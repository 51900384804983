/**
 * @generated SignedSource<<fd3af7c992adecd3450384fa518504eb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ProjectUpdatesAsideViewQuery$variables = {
  completedProjectsTqlQuery: string;
  isNavRefreshEnabled: boolean;
  newProjectsTqlQuery: string;
  ownProjectsTqlQuery: string;
  workspaceUUID: string;
};
export type ProjectUpdatesAsideViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectUpdatesSideColumn">;
};
export type ProjectUpdatesAsideViewQuery = {
  response: ProjectUpdatesAsideViewQuery$data;
  variables: ProjectUpdatesAsideViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "completedProjectsTqlQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNavRefreshEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newProjectsTqlQuery"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ownProjectsTqlQuery"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUUID"
},
v5 = {
  "kind": "Variable",
  "name": "workspaceUuid",
  "variableName": "workspaceUUID"
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v7 = {
  "kind": "Literal",
  "name": "sort",
  "value": [
    "NAME_ASC"
  ]
},
v8 = {
  "kind": "ScalarField",
  "name": "count"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "ScalarField",
  "name": "key"
},
v11 = {
  "kind": "ScalarField",
  "name": "name"
},
v12 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v13 = {
  "kind": "ScalarField",
  "name": "private"
},
v14 = {
  "concreteType": "IconURIs",
  "kind": "LinkedField",
  "name": "iconUrl",
  "plural": false,
  "selections": [
    {
      "concreteType": "ThemeURIs",
      "kind": "LinkedField",
      "name": "square",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "light"
        },
        {
          "kind": "ScalarField",
          "name": "dark"
        }
      ]
    }
  ]
},
v15 = [
  (v8/*: any*/),
  {
    "concreteType": "ProjectEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "aaid"
              },
              {
                "concreteType": "UserPII",
                "kind": "LinkedField",
                "name": "pii",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "picture"
                  }
                ]
              },
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ProjectUpdatesAsideViewQuery",
    "selections": [
      {
        "condition": "isNavRefreshEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "completedProjectsTqlQuery",
                "variableName": "completedProjectsTqlQuery"
              },
              {
                "kind": "Variable",
                "name": "newProjectsTqlQuery",
                "variableName": "newProjectsTqlQuery"
              },
              {
                "kind": "Variable",
                "name": "ownProjectsTqlQuery",
                "variableName": "ownProjectsTqlQuery"
              },
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ProjectUpdatesSideColumn"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectUpdatesAsideViewQuery",
    "selections": [
      {
        "condition": "isNavRefreshEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "ownProjects",
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "q",
                "variableName": "ownProjectsTqlQuery"
              },
              (v7/*: any*/),
              (v5/*: any*/)
            ],
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projectTql",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "watcherCount"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "alias": "newProjects",
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "q",
                "variableName": "newProjectsTqlQuery"
              },
              {
                "kind": "Literal",
                "name": "sort",
                "value": [
                  "CREATION_DATE_DESC"
                ]
              },
              (v5/*: any*/)
            ],
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projectTql",
            "plural": false,
            "selections": (v15/*: any*/)
          },
          {
            "alias": "completedProjects",
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "q",
                "variableName": "completedProjectsTqlQuery"
              },
              (v7/*: any*/),
              (v5/*: any*/)
            ],
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projectTql",
            "plural": false,
            "selections": (v15/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c0c32d54b79c1734c72a25137f4fc2ba",
    "id": null,
    "metadata": {},
    "name": "ProjectUpdatesAsideViewQuery",
    "operationKind": "query",
    "text": "query ProjectUpdatesAsideViewQuery(\n  $workspaceUUID: UUID!\n  $ownProjectsTqlQuery: String!\n  $newProjectsTqlQuery: String!\n  $completedProjectsTqlQuery: String!\n  $isNavRefreshEnabled: Boolean!\n) {\n  ...ProjectUpdatesSideColumn_2H0l1v @include(if: $isNavRefreshEnabled)\n}\n\nfragment ProjectFollowersButton on Project {\n  key\n  watcherCount\n}\n\nfragment ProjectIcon on Project {\n  private\n  iconUrl {\n    square {\n      light\n      dark\n    }\n  }\n}\n\nfragment ProjectName_data on Project {\n  id\n  key\n  name\n  uuid\n  ...ProjectIcon\n}\n\nfragment ProjectRowWithFollowers on Project {\n  key\n  ...ProjectName_data\n  ...ProjectFollowersButton\n}\n\nfragment ProjectRowWithOwner_4yoa7g on Project {\n  key\n  ...ProjectName_data\n  owner {\n    ...UserAvatar_2aqwkz\n    id\n  }\n}\n\nfragment ProjectUpdatesSideColumn_2H0l1v on Query {\n  ownProjects: projectTql(first: 20, workspaceUuid: $workspaceUUID, q: $ownProjectsTqlQuery, sort: [NAME_ASC]) {\n    ...ProjectWithFollowersSidebarSection\n  }\n  newProjects: projectTql(first: 20, workspaceUuid: $workspaceUUID, q: $newProjectsTqlQuery, sort: [CREATION_DATE_DESC]) {\n    ...ProjectWithOwnerSidebarSection\n  }\n  completedProjects: projectTql(first: 20, workspaceUuid: $workspaceUUID, q: $completedProjectsTqlQuery, sort: [NAME_ASC]) {\n    ...ProjectWithOwnerSidebarSection\n  }\n}\n\nfragment ProjectWithFollowersSidebarSection on ProjectConnection {\n  count\n  edges {\n    node {\n      id\n      ...ProjectRowWithFollowers\n    }\n  }\n}\n\nfragment ProjectWithOwnerSidebarSection on ProjectConnection {\n  count\n  edges {\n    node {\n      id\n      ...ProjectRowWithOwner_4yoa7g\n    }\n  }\n}\n\nfragment UserAvatar_2aqwkz on User {\n  aaid\n  pii {\n    picture\n  }\n}\n"
  }
};
})();

(node as any).hash = "55e311c68eb00d65af39d70899156357";

export default node;
