/**
 * @generated SignedSource<<9464c60977c1d6262080a5e283757390>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CardTeamsSection$data = {
  readonly teams: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null;
        readonly teamId: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "CardTeamsSection";
};
export type CardTeamsSection$key = {
  readonly " $data"?: CardTeamsSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardTeamsSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "CardTeamsSection",
  "selections": [
    {
      "concreteType": "TeamConnection",
      "kind": "LinkedField",
      "name": "teams",
      "plural": false,
      "selections": [
        {
          "concreteType": "TeamEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "Team",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "ScalarField",
                  "name": "teamId"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "CpusSearchUser"
};

(node as any).hash = "099e83700d47636ca9af5f6143b4c2ec";

export default node;
