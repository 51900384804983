import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

import { ellipsis, multilineEllipsis } from '@townsquare/ellipsis';
import { stylesForNestedProfilePicture } from '@townsquare/profile-picture';
import { onMobile } from '@townsquare/responsive-grid';
import { Link } from '@townsquare/router/primitives';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TeamDetails = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '190px',
    padding: `${token('space.300')} ${token('space.100')}`,
    boxSizing: 'border-box',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      maxWidth: 'calc(100% - 90px)',
      padding: `${token('space.150')} ${token('space.100')}`,
    }),
  ),
  {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    p: {
      margin: 0,
    },
  },
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NameText = styled.h4(
  {
    font: token('font.heading.small'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  ellipsis,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      font: token('font.body'),
      fontWeight: token('font.weight.medium'),
    }),
  ),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DescriptionText = styled.p(
  {
    paddingTop: token('space.100'),
    color: token('color.text.subtle'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  multilineEllipsis(4, 20),
  {
    wordWrap: 'break-word',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      WebkitLineClamp: 2,
      font: token('font.body.small'),
    }),
  ),
);

const cardStyles = css({
  width: '408px',
  height: '180px',
  borderRadius: '6px',
  boxShadow: token('elevation.shadow.raised'),
  display: 'flex',
  flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledBrowseTeamCard = styled(Link)`
  ${cardStyles}
  ${onMobile(
    css({
      maxWidth: '100%',
      height: '90px',
      boxShadow: 'unset',
    }),
  )}

  &:hover {
    box-shadow: ${token('elevation.shadow.overlay')};
    ${onMobile(
      css({
        boxShadow: 'none',
      }),
    )}

    transition: box-shadow 0.1s ease-in-out;

    ${NameText} {
      color: ${token('color.text.subtlest')};
    }

    text-decoration: none;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LoadingBrowseTeamCard = styled.div`
  ${cardStyles}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarGroup = styled.div(
  {
    height: '164px',
    width: '164px',
    margin: token('space.100'),
    display: 'flex',
    gap: token('space.050'),
    flexWrap: 'wrap',
    borderRadius: '6px',
    overflow: 'hidden',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      height: '76px',
      width: '76px',
      minWidth: '76px',
      minHeight: '76px',
    }),
  ),
);

export const AVATAR_SQUARE_SIDE_LENGTH_PX = 80;
export const AVATAR_SQUARE_SIDE_LENGTH_PX_MOBILE = 36;

// Has similar hover styles to a clickable Atlaskit avatar
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const AvatarSquare = styled(Link)`
  ${stylesForNestedProfilePicture(
    css({
      borderRadius: 0,
    }),
  )}

  position: relative;
  display: block;
  height: ${AVATAR_SQUARE_SIDE_LENGTH_PX}px;
  width: ${AVATAR_SQUARE_SIDE_LENGTH_PX}px;

  ${onMobile(`
      height: ${AVATAR_SQUARE_SIDE_LENGTH_PX_MOBILE}px;
      width: ${AVATAR_SQUARE_SIDE_LENGTH_PX_MOBILE}px;
  `)}

  &::after {
    position: absolute;
    inset: 0px;
    opacity: 0;

    content: '';
    pointer-events: none;
    transition: opacity 200ms;
    background-color: transparent;
    height: ${AVATAR_SQUARE_SIDE_LENGTH_PX}px;
    width: ${AVATAR_SQUARE_SIDE_LENGTH_PX}px;

    ${onMobile(`
      height: ${AVATAR_SQUARE_SIDE_LENGTH_PX_MOBILE}px;
      width: ${AVATAR_SQUARE_SIDE_LENGTH_PX_MOBILE}px;
    `)}
  }

  :hover {
    &::after {
      background-color: ${token('color.interaction.hovered')};
      opacity: 1;
    }
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarSquarePlaceholder = styled.div(
  {
    height: `${AVATAR_SQUARE_SIDE_LENGTH_PX}px`,
    width: `${AVATAR_SQUARE_SIDE_LENGTH_PX}px`,
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
    height: ${AVATAR_SQUARE_SIDE_LENGTH_PX_MOBILE}px;
    width: ${AVATAR_SQUARE_SIDE_LENGTH_PX_MOBILE}px;
  `),
  {
    backgroundColor: token('elevation.surface.sunken'),
  },
);
