import { Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import React, { KeyboardEvent, MouseEvent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { filterNull } from '@townsquare/filter-type';
import { ProfilePicture } from '@townsquare/profile-picture';
import { profileRoute, teamProfileRoute } from '@townsquare/ptc-directory-view';

import { SearchResultTeamMember } from '../../SearchResult/Team';

import { TeamCard$key } from './__generated__/TeamCard.graphql';
import {
  AvatarGroup,
  AvatarSquare,
  AvatarSquarePlaceholder,
  DescriptionText,
  NameText,
  StyledBrowseTeamCard,
  TeamDetails,
} from './styles';

export interface BrowseTeamMember {
  name: string;
  aaid: string;
  picture?: string;
}

export interface BrowseTeam {
  name: string;
  description?: string;
  teamId: string;
  members: BrowseTeamMember[];
  memberCount: number;
}

export const MAX_MEMBERS_TO_DISPLAY = 4;

const TeamMemberAvatarGroup = ({ members: membersToDisplay }: Pick<BrowseTeam, 'members'>) => {
  const analytics = useAnalytics();

  const emptySlotPlaceholders = useMemo(
    () =>
      membersToDisplay.length < MAX_MEMBERS_TO_DISPLAY
        ? [...Array(MAX_MEMBERS_TO_DISPLAY - membersToDisplay.length)].map((_, i) => (
            <AvatarSquarePlaceholder key={i} />
          ))
        : null,
    [membersToDisplay.length],
  );

  const teamMembers = useMemo(
    () =>
      membersToDisplay.map(({ name, picture, aaid }) => (
        <Tooltip content={name} key={aaid}>
          <AvatarSquare
            key={aaid}
            to={profileRoute}
            params={{ id: aaid }}
            onClick={(e: MouseEvent | KeyboardEvent) => {
              e.stopPropagation();
              void analytics.ui('browseTeamCardMemberAvatar', 'clicked');
            }}
          >
            <ProfilePicture name={name} src={picture} height="100%" width="100%" />
          </AvatarSquare>
        </Tooltip>
      )),
    [membersToDisplay, analytics],
  );

  return (
    <AvatarGroup>
      {teamMembers}
      {emptySlotPlaceholders}
    </AvatarGroup>
  );
};

interface BrowseTeamCardProps {
  data: TeamCard$key;
}

export const BrowseTeamCard = (props: BrowseTeamCardProps) => {
  const analytics = useAnalytics();

  const onClick = () => {
    void analytics.ui('browseTeamCard', 'clicked');
  };

  const team = useFragment(
    graphql`
      fragment TeamCard on TeamSearchResponse {
        id
        displayName
        description
        members {
          count
          edges {
            node {
              aaid
              pii {
                name
                picture
              }
            }
          }
        }
      }
    `,
    props.data,
  );

  const { id, displayName, description, members } = team;

  const teamData =
    team.members?.edges
      ?.map<SearchResultTeamMember | null>(node => {
        const member = node?.node;
        if (member?.aaid && member.pii && member.pii.name) {
          return {
            aaid: member?.aaid,
            name: member.pii.name,
            picture: member.pii.picture ?? undefined,
          };
        }
        return null;
      })
      .filter(filterNull) ?? [];

  const teamId = id?.replace('ari:cloud:identity::team/', '');

  return (
    <StyledBrowseTeamCard
      data-testid={`team-browse-card-${teamId}`}
      onClick={onClick}
      to={teamProfileRoute}
      params={{ id: teamId }}
      key={teamId}
    >
      <TeamMemberAvatarGroup members={teamData} />
      <TeamDetails>
        <EllipsisTooltip content={displayName}>
          <NameText>{displayName}</NameText>
        </EllipsisTooltip>
        <Text as="p" color="color.text.subtle" maxLines={1}>
          <FormattedMessage
            id="townsquare.views.directory.browse-team-card-member-count"
            description="The number of members in a team. Example: 5 members"
            defaultMessage="{memberCount, plural, one {# member} other {# members}}"
            values={{ memberCount: members?.count }}
          />
        </Text>
        <DescriptionText>{description}</DescriptionText>
      </TeamDetails>
    </StyledBrowseTeamCard>
  );
};
