import { useCallback } from 'react';
import { useQueryParam } from 'react-resource-router';

import { useDirectoryViewTimelineDateSpan } from '@townsquare/directory-view-store';
import { TimelineDateSpan } from '@townsquare/project-timeline';

export function useTimelineDateSpan() {
  const [directoryViewTimelineDateSpan] = useDirectoryViewTimelineDateSpan();
  const [qsTimelineDateSpan, setQsTimelineDateSpan] = useQueryParam('timelineDateSpan');

  const setTimelineDateSpan = useCallback(
    (timelineDateSpan: string | undefined) =>
      setQsTimelineDateSpan(directoryViewTimelineDateSpan === timelineDateSpan ? undefined : timelineDateSpan),
    [directoryViewTimelineDateSpan, setQsTimelineDateSpan],
  );

  return [
    (qsTimelineDateSpan ?? directoryViewTimelineDateSpan ?? TimelineDateSpan.Month) as TimelineDateSpan,
    setTimelineDateSpan,
  ] as const;
}
