import type { VariablesOf } from 'relay-runtime';

import { createCacheKey, createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

import { DEFAULT_ENTITY } from '../components/FieldSettings/constants';
import SettingsViewQuery, {
  SettingsViewQuery as SettingsViewQueryType,
} from '../components/__generated__/SettingsViewQuery.graphql';

export const settingsPageResource = createRelayResource<SettingsViewQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const { entity, pageName } = routerContext.match.params;

    const isPersonalSettings = pageName === 'personal';
    const isWorkspaceSettings = pageName === 'workspace';
    const isGoalSettings = pageName === 'goal';
    const isFieldSettings = pageName === 'field';

    const fieldListTql = tqlQueryFromInput({
      input: [
        {
          fieldName: 'entityType',
          comparator: ComparatorOperator.EQUALS,
          fieldValue: entity?.toUpperCase() ?? DEFAULT_ENTITY,
        },
      ],
    });
    const variables: VariablesOf<SettingsViewQueryType> = {
      id: context.workspace.globalId ?? '',
      workspaceUuid: context.workspace.UUID ?? '',
      tql: fieldListTql,
      isPersonalSettings,
      isWorkspaceSettings,
      isGoalSettings,
      isFieldSettings,
    };

    return {
      query: SettingsViewQuery,
      variables,
      cacheKey: createCacheKey(pageName, variables),
    };
  },
});
