import SuitcaseIcon from '@atlaskit/icon/glyph/suitcase';
import React from 'react';
import { IntlShape } from 'react-intl-next';

import { Resolver, SupportedFiltersTypes } from '../../types';
import { isLikeOrFilterComparators } from '../filter-comparators';

import { PeopleFieldType } from './__generated__/createPeopleFieldResolverQuery.graphql';
import { createPeopleFieldSuggestionResolver } from './create-people-field-resolver';
import { PeopleFieldSuggestionResolverOptions } from './types';

const jobTitleType: PeopleFieldType = 'JOB_TITLE';

export const jobTitleResolver = (intl: IntlShape, { workspaceId }: PeopleFieldSuggestionResolverOptions): Resolver =>
  createPeopleFieldSuggestionResolver(
    intl.formatMessage({
      id: 'townsquare.packages.tql.resolvers-people-and-teams-job-title',
      defaultMessage: 'Job title',
      description: 'Job title title',
    }),
    SupportedFiltersTypes.JOB_TITLE,
    <SuitcaseIcon label="" />,
    workspaceId,
    jobTitleType,
    isLikeOrFilterComparators,
  );
