/**
 * @generated SignedSource<<d1ed6824366230bcad93aaf292ae0755>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HowToStepsQuery$variables = {
  includeArchived: boolean;
  workspaceId?: string | null;
};
export type HowToStepsQuery$data = {
  readonly hasProjects: {
    readonly hasRecords: boolean;
  } | null;
};
export type HowToStepsQuery = {
  response: HowToStepsQuery$data;
  variables: HowToStepsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeArchived"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceId"
  }
],
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "includeArchived",
        "variableName": "includeArchived"
      },
      {
        "kind": "Variable",
        "name": "workspaceId",
        "variableName": "workspaceId"
      }
    ],
    "concreteType": "HasRecords",
    "kind": "LinkedField",
    "name": "hasProjects",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "hasRecords"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "HowToStepsQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HowToStepsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7121b54e6b94bbbbf5cdfbf4d5ab77b4",
    "id": null,
    "metadata": {},
    "name": "HowToStepsQuery",
    "operationKind": "query",
    "text": "query HowToStepsQuery(\n  $includeArchived: Boolean!\n  $workspaceId: ID\n) {\n  hasProjects(includeArchived: $includeArchived, workspaceId: $workspaceId) {\n    hasRecords\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c058d93dd8a8a14c89727d95067a247";

export default node;
