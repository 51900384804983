import React, { useEffect } from 'react';
import { useQueryParam, useRouter } from 'react-resource-router';

import { parseAtlOrigin, parseEmailSource, useAnalytics, useAtlasKitAnalytics } from '@townsquare/analytics';
import { useBrowserStorageControls } from '@townsquare/auth-utils';
import { isEmbedOrIntegration } from '@townsquare/config';
import { TEAM_PATH } from '@townsquare/config/routes';
import { featureFlagClient } from '@townsquare/feature-flags';
import { initMentionProvider } from '@townsquare/mention-provider';
import { initBM3 } from '@townsquare/monitoring/bm3';
import { initUFO } from '@townsquare/monitoring/ufo';
import { useHelp } from '@townsquare/navigation';
import { ProjectIcon } from '@townsquare/project-icon';
import { useRouteListeners } from '@townsquare/router/use-route-listeners';
import { useUserStore } from '@townsquare/user-store';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { useJoinableWorkspace } from '../../hooks/useJoinableWorkspace';
import { useAsyncEffect } from '../../util/hooks';

import { EmbedApplication } from './EmbedApplication';
import { WebApplication } from './WebApplication';

const Application = () => {
  const analytics = useAnalytics();
  const { isOpen: isHelpOpen } = useHelp();
  const akAnalytics = useAtlasKitAnalytics();
  const [user] = useUserStore();
  const [workspace] = useWorkspaceStore();
  const [{ route }] = useRouter();
  const [atlOrigin, setAtlOriginQueryParam] = useQueryParam('atlOrigin');
  const [src, setSrcQueryParam] = useQueryParam('src');
  const [startCrossFlowSrcContext, setStartCrossFlowSrcContextParam] = useQueryParam('startCrossFlowSrcContext');
  const [searchId, setSearchIdQueryParam] = useQueryParam('search_id');
  const [, setBifrostOverride] = useQueryParam('bifrost-override');

  useBrowserStorageControls();

  useJoinableWorkspace();

  setBifrostOverride(undefined, 'replace');
  ProjectIcon.preload();

  useRouteListeners();

  useAsyncEffect(
    async () => {
      const analyticsClient = analytics.getAnalyticsWebClient();

      initBM3(analyticsClient, featureFlagClient);
      initUFO(analyticsClient);

      void analytics.startUIViewedEvent();
    },
    [
      /* Set to empty so this only executes once */
    ],
  );

  useAsyncEffect(async () => {
    if (workspace.cloudId) {
      initMentionProvider({
        cloudId: workspace.cloudId,
        userId: user.accountId,
        orgId: workspace.organisationId,
        workspaceType: workspace.type,
      });
    }
  }, [workspace.cloudId, workspace.organisationId]);

  useEffect(() => {
    if (atlOrigin) {
      void analytics.setTopLevelAttributes({
        ...parseAtlOrigin(atlOrigin),
      });
      void analytics.getAnalyticsWebClient().sendOperationalEvent({
        actionSubject: 'origin',
        action: 'landed',
        source: 'team-central',
        attributes: {
          ...parseAtlOrigin(atlOrigin),
        },
      });
      setAtlOriginQueryParam(undefined);
    }
  }, [atlOrigin, analytics, setAtlOriginQueryParam]);

  useEffect(() => {
    if (src) {
      void analytics.setTopLevelAttributes({
        ...parseEmailSource(src),
      });
      void analytics.ui('src', 'landed', {
        route: route.path,
      });
      /**
       * https://product-fabric.atlassian.net/browse/PTC-6958
       * The directory component expects the src field, it handles removing the
       * URL search param.
       *  */
      if (location.pathname.startsWith(TEAM_PATH)) {
        return;
      }
      setSrcQueryParam(undefined);
    }
  }, [src, analytics, route.path, setSrcQueryParam]);

  useAsyncEffect(async () => {
    if (startCrossFlowSrcContext) {
      if (window.Intercom) {
        window.Intercom('trackEvent', 'landedFromCrossFlow', {
          ...analytics.getTopLevelAttributes(),
          startCrossFlowSrcContext: startCrossFlowSrcContext,
        });
      }
    }
  }, [startCrossFlowSrcContext, analytics, setStartCrossFlowSrcContextParam]);

  useEffect(() => {
    if (searchId) {
      void analytics.setTopLevelAttributes({
        searchId,
      });
      // TODO: Fire dedicated search success analytics event - TBC what that should be
      setSearchIdQueryParam(undefined, 'replace');
    }
  }, [searchId, analytics, setSearchIdQueryParam]);

  return isEmbedOrIntegration() ? (
    <EmbedApplication analyticsClient={akAnalytics} />
  ) : (
    <WebApplication analyticsClient={akAnalytics} isHelpOpen={isHelpOpen} />
  );
};

export default Application;
