import Button from '@atlaskit/button';
import React from 'react';
import { useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { getProjectLearningsExamplesOptions } from '@townsquare/examples-modal';
import { useModalActions } from '@townsquare/modals';

import { BaseOnboardingBanner } from '../BaseOnboardingBanners';
import { Onboarding } from '../Onboarding';
import { OnboardingItemKey } from '../__generated__/OnboardingQuery.graphql';
import { LOW_MARGIN_STYLING, UnderlineText } from '../style';

export const ProjectLearningsTabBanner = () => {
  const intl = useIntl();
  const bannerName = 'projectLearningsTabBanner';
  const key: OnboardingItemKey = 'PROJECT_LEARNINGS_BANNER';
  const analytics = useAnalytics();
  const { openModal } = useModalActions();
  const content = (
    <span>
      {intl.formatMessage({
        id: 'townsquare.onboarding-banner.project-learnings-tab-banner.content',
        description:
          'Project Learnings tab banner content; informing the user of the usefulness of the learnings tab in the Project Directory',
        defaultMessage: 'Capture what you learn during a project to give teams working on similar things a headstart.',
      })}
      <Button
        appearance="subtle-link"
        spacing="none"
        onClick={() => {
          void analytics.ui('onboardingBannerHyperlink', 'clicked', {
            bannerType: bannerName,
          });
          openModal('examples-modal', getProjectLearningsExamplesOptions(intl));
        }}
      >
        <UnderlineText>
          {intl.formatMessage({
            id: 'townsquare.onboarding-banner.project-learnings-tab-banner.learn-more',
            description:
              'Onboarding banners - a user is prompted within the banner content to learn more about the purpose of Learnings',
            defaultMessage: 'Learn more',
          })}
        </UnderlineText>
      </Button>
    </span>
  );

  return (
    <Onboarding onboardingKey={key}>
      {(connectionId: string) => (
        <BaseOnboardingBanner
          analyticsName={bannerName}
          content={content}
          customMargins={LOW_MARGIN_STYLING}
          connectionId={connectionId}
          onboardingKey={key}
        />
      )}
    </Onboarding>
  );
};
