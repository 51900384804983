import React, { useMemo, useState } from 'react';
import { useFragment, graphql } from 'react-relay';

import { filterNull } from '@townsquare/filter-type';

import { TeamTag, TeamTagDropdown } from './TeamTag';
import { CardTeamsSection$key } from './__generated__/CardTeamsSection.graphql';
import { TeamsContainer } from './styles';

export const CardTeamsSection = (props: { data: CardTeamsSection$key }) => {
  const [visibleTeams, setVisibleTeams] = useState(new Set<string>());
  const data = useFragment(
    graphql`
      fragment CardTeamsSection on CpusSearchUser {
        teams {
          edges {
            node {
              name
              teamId
            }
          }
        }
      }
    `,
    props.data,
  );

  const teams = useMemo(
    () =>
      data.teams?.edges
        ?.map(team => {
          if (!team?.node?.name || !team.node.teamId) {
            return null;
          }
          return { name: team.node.name, teamId: team.node.teamId };
        })
        .filter(filterNull) ?? [],
    [data.teams?.edges],
  );

  const teamTags = useMemo(
    () =>
      teams.map(team => {
        const { name, teamId } = team;
        return <TeamTag name={name} teamId={teamId} key={teamId} setVisibleTeams={setVisibleTeams} />;
      }) ?? [],
    [teams],
  );

  const excessTeams = useMemo(() => {
    if (!teams || teams.length === 0) {
      return [];
    }
    return teams.slice(visibleTeams.size);
  }, [teams, visibleTeams.size]);

  const hasExcessTeams = excessTeams.length > 0;

  return (
    <TeamsContainer isDropdownVisible={hasExcessTeams}>
      {hasExcessTeams ? [<TeamTagDropdown teams={excessTeams} />, ...teamTags] : teamTags}
    </TeamsContainer>
  );
};
