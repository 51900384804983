/**
 * @generated SignedSource<<3313ad3480b6e9418be432781fbfc898>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SearchTeamsScreen_data$data = {
  readonly searchTeams?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"TeamSearchResultCard">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "SearchTeamsScreen_data";
};
export type SearchTeamsScreen_data$key = {
  readonly " $data"?: SearchTeamsScreen_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchTeamsScreen_data">;
};

import SearchTeamsPaginationQuery_graphql from './SearchTeamsPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "searchTeams"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isSearchTeamsScreen"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organisationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": SearchTeamsPaginationQuery_graphql
    }
  },
  "name": "SearchTeamsScreen_data",
  "selections": [
    {
      "condition": "isSearchTeamsScreen",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "searchTeams",
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            {
              "kind": "Variable",
              "name": "organisationId",
              "variableName": "organisationId"
            },
            {
              "kind": "Variable",
              "name": "q",
              "variableName": "query"
            },
            {
              "kind": "Variable",
              "name": "sort",
              "variableName": "sort"
            }
          ],
          "concreteType": "TeamSearchResponseConnection",
          "kind": "LinkedField",
          "name": "__SearchTeamsScreen_searchTeams_connection",
          "plural": false,
          "selections": [
            {
              "concreteType": "TeamSearchResponseEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "TeamSearchResponse",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "id"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "TeamSearchResultCard"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "cursor"
                }
              ]
            },
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "endCursor"
                },
                {
                  "kind": "ScalarField",
                  "name": "hasNextPage"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "ed20d29908bc5227754fff9814989623";

export default node;
