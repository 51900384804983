/**
 * @generated SignedSource<<2d69b2f3b3104ae447c26f7995b7c820>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProjectSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "STATUS_ASC" | "STATUS_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type projectResolverQuery$variables = {
  after?: string | null;
  first?: number | null;
  sort?: ReadonlyArray<ProjectSortEnum | null> | null;
  tql: string;
  workspaceUuid: string;
};
export type projectResolverQuery$data = {
  readonly projectSearch: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly iconUrl: {
          readonly square: {
            readonly dark: string | null;
            readonly light: string | null;
          } | null;
        } | null;
        readonly id: string;
        readonly key: string;
        readonly name: string;
        readonly uuid: string;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};
export type projectResolverQuery = {
  response: projectResolverQuery$data;
  variables: projectResolverQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tql"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v5 = [
  {
    "alias": "projectSearch",
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "q",
        "variableName": "tql"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      },
      {
        "kind": "Variable",
        "name": "workspaceUuid",
        "variableName": "workspaceUuid"
      }
    ],
    "concreteType": "ProjectConnection",
    "kind": "LinkedField",
    "name": "projectTql",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "count"
      },
      {
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "hasNextPage"
          },
          {
            "kind": "ScalarField",
            "name": "startCursor"
          },
          {
            "kind": "ScalarField",
            "name": "endCursor"
          }
        ]
      },
      {
        "concreteType": "ProjectEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "kind": "ScalarField",
                "name": "name"
              },
              {
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "ScalarField",
                "name": "uuid"
              },
              {
                "concreteType": "IconURIs",
                "kind": "LinkedField",
                "name": "iconUrl",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "ThemeURIs",
                    "kind": "LinkedField",
                    "name": "square",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "light"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "dark"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "projectResolverQuery",
    "selections": (v5/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "projectResolverQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "43123220407dcb78d1754470f435d80d",
    "id": null,
    "metadata": {},
    "name": "projectResolverQuery",
    "operationKind": "query",
    "text": "query projectResolverQuery(\n  $tql: String!\n  $workspaceUuid: UUID!\n  $after: String\n  $first: Int\n  $sort: [ProjectSortEnum]\n) {\n  projectSearch: projectTql(first: $first, after: $after, q: $tql, workspaceUuid: $workspaceUuid, sort: $sort) {\n    count\n    pageInfo {\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        name\n        key\n        uuid\n        iconUrl {\n          square {\n            light\n            dark\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f245844d44df9f00a604893802b618c7";

export default node;
