import { loadQuery, LoadQueryOptions, PreloadedQuery } from 'react-relay';
import {
  createResource,
  type RouterContext,
  type RouterDataContext,
  type ResourceStoreContext,
  useResource,
  useQueryParam,
} from 'react-resource-router';
import type { RouteResource } from 'react-resource-router';
import type { ConcreteRequest, OperationType, VariablesOf, Environment } from 'relay-runtime';

import { GetValueOptions, SupportedFlagTypes, useFeatureFlag } from '@townsquare/feature-flags';
import { useFeatureGate } from '@townsquare/stat-sig/gate';

import { RelayEnvironment } from './RelayEnvironment';

export const RELAY_RESOURCE_TYPE = 'RELAY_RESOURCE_TYPE';

export interface RelayResourceConfig<TQuery extends OperationType> {
  query: ConcreteRequest;
  variables?: VariablesOf<TQuery>;
  options?: LoadQueryOptions;
  cacheKey?: string;
  environment?: Environment;
}

type GetQueryFunction<TQuery extends OperationType> = (
  routerContext: RouterContext | RouterDataContext,
  resourceStoreContext: ResourceStoreContext,
) => RelayResourceConfig<TQuery>;

export function createCacheKey<TQuery extends OperationType>(queryId?: string | null, variables?: VariablesOf<TQuery>) {
  return `relay-${queryId}-${JSON.stringify(variables ?? {})}`;
}

export const createRelayResource = <TQuery extends OperationType>({
  getQuery,
}: {
  getQuery: GetQueryFunction<TQuery>;
}): RouteResource<PreloadedQuery<TQuery>> => {
  return createResource({
    type: RELAY_RESOURCE_TYPE,
    getKey: (routerContext, resourceStoreContext) => {
      const { query, variables, cacheKey } = getQuery(routerContext, resourceStoreContext);

      let queryId = query.params.id;
      if ('cacheID' in query.params) {
        queryId = query.params.cacheID;
      }

      return cacheKey ?? createCacheKey(queryId, variables);
    },
    getData: async (...args) => {
      const { environment, query, variables, options } = getQuery(...args);
      const queryReference = loadQuery<TQuery>(environment ?? RelayEnvironment, query, variables ?? {}, options);

      return queryReference;
    },
  });
};

export const useRelayResource = <TQuery extends OperationType>(resource: RouteResource<PreloadedQuery<TQuery>>) => {
  const { data: queryReference } = useResource(resource);
  return queryReference;
};

export const useResourceVariables = <TQuery extends OperationType>(resource: RouteResource<PreloadedQuery<TQuery>>) => {
  const { data: queryReference } = useResource(resource);
  return queryReference?.variables;
};

export const useRelayFeatureFlag = <T extends SupportedFlagTypes>(
  flagKey: string,
  defaultValue: T,
  options?: GetValueOptions<T>,
) => {
  const flagValue = useFeatureFlag(flagKey, defaultValue, options);
  const [relay] = useQueryParam('relay');
  return Boolean(Number(relay)) && flagValue;
};

export const useRelayFeatureGate = (...args: Parameters<typeof useFeatureGate>) => {
  const flagValue = useFeatureGate(...args);
  const [relay] = useQueryParam('relay');
  return Boolean(Number(relay)) && flagValue;
};
