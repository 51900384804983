/**
 * @generated SignedSource<<26b099d269d1437382978c9c07236a26>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ProjectLinkItem$data = {
  readonly key: string;
  readonly members: {
    readonly count: number;
  } | null;
  readonly name: string;
  readonly uuid: string;
  readonly watcherCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectIcon">;
  readonly " $fragmentType": "ProjectLinkItem";
};
export type ProjectLinkItem$key = {
  readonly " $data"?: ProjectLinkItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectLinkItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ProjectLinkItem",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "uuid"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "FragmentSpread",
      "name": "ProjectIcon"
    },
    {
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "members",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "count"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "watcherCount"
    },
    {
      "kind": "ScalarField",
      "name": "key"
    }
  ],
  "type": "Project"
};

(node as any).hash = "13ca6efcf03051a4776d642aa56e0cee";

export default node;
