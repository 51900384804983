import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { ResponsiveLoadingState } from '@townsquare/loading-state';

import { BrowseTeamCard } from '../Cards/Browse/Team';
import { BrowseTeamCardLoadingState } from '../Cards/Browse/Team/loadingState';
import { Heading } from '../Heading';
import { ScreenType } from '../ScreenType';
import { CardRow } from '../styles';

import { BrowseTeamsEmptyState } from './BrowseTeamsEmptyState';
import { BrowseTeamSection$key } from './__generated__/BrowseTeamSection.graphql';

export const BROWSE_TEAM_CARD_SLOTS = 3;

export const getLoadingCards = () =>
  [...Array(BROWSE_TEAM_CARD_SLOTS)].map((_, i) => (
    <BrowseTeamCardLoadingState key={`BrowseTeamCardLoadingState${i}`} />
  ));

export interface BrowseTeamSectionProps {
  data: BrowseTeamSection$key;
}

export const BrowseTeamsSectionSkeleton = () => {
  return (
    <BrowseTeamsSectionFrame hasTeams={false}>
      <ResponsiveLoadingState>
        <CardRow>{getLoadingCards()}</CardRow>
      </ResponsiveLoadingState>
    </BrowseTeamsSectionFrame>
  );
};

type BrowseTeamsSectionFrameProps = { children: React.ReactNode; hasTeams: boolean };

const BrowseTeamsSectionFrame = ({ children, hasTeams }: BrowseTeamsSectionFrameProps) => {
  const intl = useIntl();
  return (
    <>
      <Heading
        browseMoreText={
          hasTeams
            ? intl.formatMessage({
                id: 'townsquare.web.browse-teams-section.browse-all',
                description: 'Browse more heading text',
                defaultMessage: 'Browse all',
              })
            : undefined
        }
        screenType={ScreenType.SEARCH_TEAMS}
      >
        <FormattedMessage
          id="townsquare.web.browse-teams-section.heading"
          description="Browse teams section heading"
          defaultMessage="Teams"
        />
      </Heading>
      {children}
    </>
  );
};

export const BrowseTeamsSection = (props: BrowseTeamSectionProps) => {
  const data = useFragment(
    graphql`
      fragment BrowseTeamSection on Query
      @argumentDefinitions(
        query: { type: "String!" }
        first: { type: "Int" }
        after: { type: "String" }
        organisationId: { type: "String!" }
        cloudId: { type: "String" }
      ) {
        browseTeams: teamsTql(
          q: $query
          first: $first
          sort: [NAME_ASC]
          after: $after
          organisationId: $organisationId
          cloudId: $cloudId
        ) {
          edges {
            node {
              ...TeamCard
            }
          }
        }
      }
    `,
    props.data,
  );

  if (!data.browseTeams?.edges) {
    return null;
  }

  return (
    <BrowseTeamsSectionFrame hasTeams={data.browseTeams.edges.length > 0}>
      {data.browseTeams.edges.length ? (
        <CardRow shouldAvoidBleed>
          {data.browseTeams.edges
            ?.slice(0, BROWSE_TEAM_CARD_SLOTS)
            .map(team => team?.node && <BrowseTeamCard data={team.node} />)}
        </CardRow>
      ) : (
        <BrowseTeamsEmptyState />
      )}
    </BrowseTeamsSectionFrame>
  );
};
