/**
 * @generated SignedSource<<908f52bda4e6f10d4b0dc0cc1816debc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type goalResolverLabelQuery$variables = {
  key: string;
};
export type goalResolverLabelQuery$data = {
  readonly goalByKey: {
    readonly key: string;
    readonly name: string;
  } | null;
};
export type goalResolverLabelQuery = {
  response: goalResolverLabelQuery$data;
  variables: goalResolverLabelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "key"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "key"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "goalResolverLabelQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "Goal",
        "kind": "LinkedField",
        "name": "goalByKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "goalResolverLabelQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "Goal",
        "kind": "LinkedField",
        "name": "goalByKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "51f4fe8bae4e1a971861c1dba14082e1",
    "id": null,
    "metadata": {},
    "name": "goalResolverLabelQuery",
    "operationKind": "query",
    "text": "query goalResolverLabelQuery(\n  $key: String!\n) {\n  goalByKey(key: $key) {\n    key\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "212f37f4bc4f45391b834537806c86ac";

export default node;
