export const hideIntercom = () => {
  if (window.Intercom) {
    window.Intercom('update', { hide_default_launcher: true });
  }
};

export const showIntercom = () => {
  if (window.Intercom) {
    window.Intercom('update', { hide_default_launcher: false });
  }
};
