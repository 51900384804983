/**
 * @generated SignedSource<<845070062569e1d5a30ac72f08d02b88>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ResolveUserQuery$variables = {
  accountId: string;
};
export type ResolveUserQuery$data = {
  readonly user: {
    readonly accountId: string;
    readonly name: string;
    readonly picture: string;
  } | null;
};
export type ResolveUserQuery = {
  response: ResolveUserQuery$data;
  variables: ResolveUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "picture"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ResolveUserQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResolveUserQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "__typename"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "ab2c2e0f097ecc3cef1d0c126be10d72",
    "id": null,
    "metadata": {},
    "name": "ResolveUserQuery",
    "operationKind": "query",
    "text": "query ResolveUserQuery(\n  $accountId: ID!\n) {\n  user(accountId: $accountId) {\n    __typename\n    accountId\n    name\n    picture\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2aafa8997048ae12eaaf01fba70833f8";

export default node;
