import React from 'react';

import Network5xxError from './ErrorComponents/Network5xxError';
import { ErrorContainer } from './styles';

export const FullPageError = () => (
  <ErrorContainer isFullPage>
    <Network5xxError />
  </ErrorContainer>
);
