import { Action, createActionsHook, createHook, createStore } from 'react-sweet-state';

import { type AddPeopleBenefitsModalOptions } from '@townsquare/add-people-benefits-modal';
import { type ArchiveGoalModalOptions } from '@townsquare/archive-goal-modal';
import { type CloneGoalModalOptions } from '@townsquare/clone-goal-modal';
import { type CloneProjectModalOptions } from '@townsquare/clone-project-modal';
import { type ConnectProjectToJiraModalOptions } from '@townsquare/connect-project-to-jira-modal';
import { type CreateGoalModalOptions } from '@townsquare/create-goal-modal';
import { type DeleteUpdateModalOptions } from '@townsquare/delete-update-modal';
import {
  type DeleteDirectoryViewModalOptions,
  type EditDirectoryViewModalOptions,
  type CreateDirectoryViewModalOptions,
  type RenameDirectoryViewModalOptions,
} from '@townsquare/directory-view-modals/types';
import { type ExamplesModalOptions } from '@townsquare/examples-modal';
import { type FeedbackModalOptions } from '@townsquare/feedback';
import { type ViewersModalOptions } from '@townsquare/impression-tracker/types';
import { CreateOrSelectModalOptions } from '@townsquare/metrics/create';
import {
  type MetricSettingsModalOptions,
  type DeleteMetricModalOptions,
  type EditMetricModalOptions,
  type RemoveTargetModalOptions,
} from '@townsquare/metrics/modals';
import { type GiveKudosModalOptions } from '@townsquare/navigation/give-kudos-modal';
import { type RemoveTeamModalOptions } from '@townsquare/remove-team-modal';
import { type RemoveUserAccessModalOptions } from '@townsquare/remove-user-access-modal';
import { type UpdateModalOptions } from '@townsquare/update-modal/types';
import { type WarningModalOptions } from '@townsquare/warning-modal/types';

export type ModalOptions = {
  'archive-goal-modal': ArchiveGoalModalOptions;
  'clone-goal-modal': CloneGoalModalOptions;
  'clone-project-modal': CloneProjectModalOptions;
  'create-goal-modal': CreateGoalModalOptions;
  'create-directory-view-modal': CreateDirectoryViewModalOptions;
  'rename-directory-view-modal': RenameDirectoryViewModalOptions;
  'delete-directory-view-modal': DeleteDirectoryViewModalOptions;
  'edit-directory-view-modal': EditDirectoryViewModalOptions;
  'delete-update-modal': DeleteUpdateModalOptions;
  'examples-modal': ExamplesModalOptions;
  'remove-team-modal': RemoveTeamModalOptions;
  'remove-user-access-modal': RemoveUserAccessModalOptions;
  'update-modal': UpdateModalOptions;
  'feedback-modal': FeedbackModalOptions;
  'give-kudos-modal': GiveKudosModalOptions;
  'connect-project-to-jira-modal': ConnectProjectToJiraModalOptions;
  'viewers-modal': ViewersModalOptions;
  'warning-modal': WarningModalOptions;
  'add-people-benefits-modal': AddPeopleBenefitsModalOptions;
  'create-metric-modal': CreateOrSelectModalOptions;
  'edit-metric-modal': EditMetricModalOptions;
  'delete-metric-modal': DeleteMetricModalOptions;
  'remove-target-modal': RemoveTargetModalOptions;
  'metric-settings-modal': MetricSettingsModalOptions;
};

export type ModalType = keyof ModalOptions;

export type Modal<T extends ModalType> = {
  type: T;
  options?: T extends ModalType ? ModalOptions[T] : never;
};

type ModalState = {
  modal: Modal<ModalType> | undefined;
  // Support for multiple modals, only one is shown at a time, but it will open the last one when closed if reopenLastModal is true
  stack: Modal<ModalType>[];
};

const actions = {
  /**
   * @deprecated Use openModalTypesafe instead
   */
  openModal:
    <T extends keyof ModalOptions>(
      type: T,
      options: T extends keyof ModalOptions ? ModalOptions[T] : never,
      { reopenLastModal }: { reopenLastModal?: boolean } = {},
    ): Action<ModalState> =>
    ({ getState, setState }) => {
      const { modal } = getState();
      setState({
        modal: {
          type,
          options,
        },
        /**
         * If reopenLastModal is true, we add the previous modal to the stack
         */
        stack: reopenLastModal && modal ? [...getState().stack, modal] : getState().stack,
      });
    },
  openModalTypesafe:
    <T extends keyof ModalOptions>(
      {
        type,
        options,
      }: T extends keyof ModalOptions
        ? {
            type: T;
            options: ModalOptions[T];
          }
        : never,
      { reopenLastModal }: { reopenLastModal?: boolean } = {},
    ): Action<ModalState> =>
    ({ getState, setState }) => {
      const { modal } = getState();
      setState({
        modal: {
          type,
          options,
        },
        stack: reopenLastModal && modal ? [...getState().stack, modal] : getState().stack,
      });
    },
  closeModal:
    (target: ModalType): Action<ModalState> =>
    ({ setState, getState }) => {
      const { modal, stack } = getState();
      if (modal?.type === target) {
        /**
         * If there are any modals in the stack, we pop the last one and set it as the current modal,
         * otherwise lastModal will be undefined and the modal will be closed
         */
        const lastModal = stack.pop();
        setState({
          modal: lastModal,
          stack: stack,
        });
      }
    },
} as const;

const modalStore = createStore<ModalState, typeof actions>({
  initialState: {
    modal: undefined,
    stack: [],
  },
  actions,
  name: 'modal-store',
});

export const useModalStore = createHook(modalStore, { selector: s => s.modal });
export const useModalActions = createActionsHook(modalStore);
