import { Banner } from '@atlaskit/page-layout';
import React, { ReactNode, useState, useEffect, useRef } from 'react';

import { CookiesConsentBanner } from '../cookies-consent-banner';
import { ScheduledMaintenanceBanner } from '../scheduled-maintenance-banner';

import { BannerHeightContext } from './context';

export function SiteBannerProvider({ children }: { children: ReactNode }) {
  // The total height of all visible banners
  const [height, setHeight] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      setHeight(entries[0].contentRect.height);
    }),
  );

  useEffect(() => {
    const currentObserver = resizeObserver.current;
    if (containerRef?.current) {
      currentObserver.observe(containerRef.current);
    } else {
      currentObserver.disconnect();
    }

    return () => {
      currentObserver.disconnect();
    };
  }, [containerRef, resizeObserver]);

  return (
    <BannerHeightContext.Provider value={{ height }}>
      <Banner height={height} testId="site-banners-container">
        <div ref={containerRef}>
          <CookiesConsentBanner />
          <ScheduledMaintenanceBanner />
        </div>
      </Banner>
      {children}
    </BannerHeightContext.Provider>
  );
}
