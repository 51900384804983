/**
 * @generated SignedSource<<2d0ab4e0ea149e21d8b15a79ef9a9f19>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type updateUserWorkspacePreferenceInput = {
  clientMutationId?: string | null;
  workspaceId: string;
};
export type UpdateUserWorkspacePreferenceMutation$variables = {
  input: updateUserWorkspacePreferenceInput;
};
export type UpdateUserWorkspacePreferenceMutation$data = {
  readonly updateUserWorkspacePreference: {
    readonly result: {
      readonly id: string;
      readonly name: string;
      readonly uuid: string;
    } | null;
  } | null;
};
export type UpdateUserWorkspacePreferenceMutation = {
  response: UpdateUserWorkspacePreferenceMutation$data;
  variables: UpdateUserWorkspacePreferenceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "updateUserWorkspacePreferencePayload",
    "kind": "LinkedField",
    "name": "updateUserWorkspacePreference",
    "plural": false,
    "selections": [
      {
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "id"
          },
          {
            "kind": "ScalarField",
            "name": "name"
          },
          {
            "kind": "ScalarField",
            "name": "uuid"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "UpdateUserWorkspacePreferenceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserWorkspacePreferenceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f65af93f4ffc2684fa43af631ddfa941",
    "id": null,
    "metadata": {},
    "name": "UpdateUserWorkspacePreferenceMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserWorkspacePreferenceMutation(\n  $input: updateUserWorkspacePreferenceInput!\n) {\n  updateUserWorkspacePreference(input: $input) {\n    result {\n      id\n      name\n      uuid\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe7d919725303f76e042144813f39c44";

export default node;
