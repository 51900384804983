import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
  SaveAsCopyButton: {
    defaultMessage: `Save as copy`,
    description: 'Description of the button to save a copy of a view',
    id: 'townsquare.directory-header.view.save-copy-button',
  },
  saveAsCopyFieldPlaceholder: {
    defaultMessage: `Copy of {name}`,
    description: 'Field placeholder of view name when saving a copy of a view',
    id: 'townsquare.directory-header.view.save-copy-field-placeholder',
  },
});
