/**
 * @generated SignedSource<<085c9752bb9b8b9c99f1eb1e9deb0a1d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type IllustrationCard$data = {
  readonly imageDirectoryUrl: string | null;
  readonly name: string;
  readonly " $fragmentType": "IllustrationCard";
};
export type IllustrationCard$key = {
  readonly " $data"?: IllustrationCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"IllustrationCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "IllustrationCard",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "imageDirectoryUrl"
    }
  ],
  "type": "PeopleFieldAggregate"
};

(node as any).hash = "1b10ea5a2187de3f47fb50ebdce5aa03";

export default node;
