import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { PopupSelect } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import React from 'react';
import { useIntl } from 'react-intl-next';

import { ComparatorOperator, FilterComparators } from '../../types';

interface ComparatorPickerProps {
  selected: ComparatorOperator;
  filterComparators: FilterComparators[];
  onChange: (comparator: ComparatorOperator) => void;
}

interface ComparatorPickerOption {
  label: string;
  value: ComparatorOperator;
}

export const ComparatorPicker = ({ selected, filterComparators, onChange }: ComparatorPickerProps) => {
  const intl = useIntl();

  const options = filterComparators.map(comparator => {
    return {
      label:
        comparator.comparatorText ||
        intl.formatMessage({
          id: `townsquare.tql.operator-picker.comparator-default-label`,
          description: 'Default comparator label',
          defaultMessage: 'is',
        }),
      value: comparator.comparatorOption,
    };
  });
  if (!options || options.length <= 1) {
    return null;
  }

  return (
    <PopupSelect<ComparatorPickerOption>
      options={options}
      onChange={item => {
        if (item?.value) {
          onChange(item.value);
        }
      }}
      minMenuWidth={80}
      maxMenuWidth={120}
      value={options.find(option => option.value === selected)}
      target={({ ref }) => (
        <Button
          ref={ref}
          spacing="compact"
          appearance="subtle"
          testId="metafilter-comparator-picker"
          style={{
            padding: `0 ${token('space.025')} 0 ${token('space.100')}`,
            // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
            height: 'auto',
            margin: `0 0 0 ${token('space.025')}`,
          }}
          iconAfter={<ChevronDownIcon size="medium" label="" />}
        >
          {options.find(item => item.value === selected)?.label}
        </Button>
      )}
    />
  );
};
