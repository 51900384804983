import { useCallback } from 'react';
import { useQueryParam } from 'react-resource-router';

import { useDirectoryViewUiViewType } from '@townsquare/directory-view-store';

export function useView() {
  const [directoryViewUiView] = useDirectoryViewUiViewType();
  const [qsView, setQsView] = useQueryParam('view');

  const setView = useCallback(
    (view: string | undefined) => setQsView(directoryViewUiView === view ? undefined : view),
    [directoryViewUiView, setQsView],
  );

  return [qsView ?? directoryViewUiView ?? 'list', setView] as const;
}
