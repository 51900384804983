/**
 * @generated SignedSource<<149362b58cdca21c68fe2a8465975473>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LookupRedirectActionProjectQuery$variables = {
  entityKey: string;
};
export type LookupRedirectActionProjectQuery$data = {
  readonly projectByKey: {
    readonly id: string;
  } | null;
};
export type LookupRedirectActionProjectQuery = {
  response: LookupRedirectActionProjectQuery$data;
  variables: LookupRedirectActionProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityKey"
  }
],
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "entityKey"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "projectByKey",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "id"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "LookupRedirectActionProjectQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LookupRedirectActionProjectQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ed83857d17e3810ef13e41fca9a9ad52",
    "id": null,
    "metadata": {},
    "name": "LookupRedirectActionProjectQuery",
    "operationKind": "query",
    "text": "query LookupRedirectActionProjectQuery(\n  $entityKey: String!\n) {\n  projectByKey(key: $entityKey) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a94c223d61eee0dba68c2f20aaac2da2";

export default node;
