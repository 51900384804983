/**
 * @generated SignedSource<<7d8a52cb38fd59bb768b19d7e0900f90>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FollowProjectActionQuery$variables = {
  key: string;
};
export type FollowProjectActionQuery$data = {
  readonly project: {
    readonly id: string;
    readonly key: string;
    readonly watching: boolean;
  } | null;
};
export type FollowProjectActionQuery = {
  response: FollowProjectActionQuery$data;
  variables: FollowProjectActionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": "project",
    "args": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "projectByKey",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "id"
      },
      {
        "kind": "ScalarField",
        "name": "key"
      },
      {
        "kind": "ScalarField",
        "name": "watching"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "FollowProjectActionQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FollowProjectActionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0fcc7791c12cbf6dbd784f727d679a84",
    "id": null,
    "metadata": {},
    "name": "FollowProjectActionQuery",
    "operationKind": "query",
    "text": "query FollowProjectActionQuery(\n  $key: String!\n) {\n  project: projectByKey(key: $key) {\n    id\n    key\n    watching\n  }\n}\n"
  }
};
})();

(node as any).hash = "83b74d61aa0b40879f2af27e4b261c1f";

export default node;
