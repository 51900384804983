import Avatar from '@atlaskit/avatar';
import Tooltip from '@atlaskit/tooltip';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { UserAvatar$data, UserAvatar$key } from './__generated__/UserAvatar.graphql';
import { UserAvatarProps } from './types';
import { BaseUserAvatar } from './ui/BaseUserAvatar';
import { isUserDeactivated } from './utils';

type Props = {
  data: UserAvatar$key | null;
  onClick?: (user: UserAvatar$data) => void;
  showProfileCard?: boolean;
  disableTooltip?: boolean;
} & Pick<UserAvatarProps, 'size' | 'linkToProfile' | 'hideBorder' | 'isPendingUser'>;

export const UserAvatar = (props: Props) => {
  const { data, ...otherProps } = props;
  const { showProfileCard = true } = props;
  const user = useFragment(
    graphql`
      fragment UserAvatar on User
      @argumentDefinitions(
        showName: { type: "Boolean", defaultValue: true }
        extendedProfile: { type: "Boolean", defaultValue: false }
        includeEmail: { type: "Boolean", defaultValue: false }
      ) {
        aaid
        pii {
          picture
          name @include(if: $showName)
          accountStatus @include(if: $showName)

          extendedProfile @include(if: $extendedProfile) {
            jobTitle
          }

          email @include(if: $includeEmail)
        }
      }
    `,
    data,
  );

  if (!user) {
    return null;
  }

  return showProfileCard ? (
    <BaseUserAvatar
      {...otherProps}
      showName={Boolean(user.pii?.name)}
      name={user.pii?.name}
      aaid={user.aaid}
      url={user.pii?.picture}
      jobTitle={user.pii?.extendedProfile?.jobTitle}
      isDeactivatedUser={isUserDeactivated(user.pii?.accountStatus)}
      onClick={() => props.onClick?.(user)}
    />
  ) : (
    <Tooltip content={props.disableTooltip ? null : user.pii?.name}>
      <Avatar
        src={user.pii?.picture ?? ''}
        name={user.pii?.name ?? ''}
        label={user.pii?.name ?? undefined}
        size="small"
        borderColor="transparent"
        onClick={() => props.onClick?.(user)}
      />
    </Tooltip>
  );
};
