import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { IconButtonWrapper } from '@townsquare/icons';
import { hideOnDesktop, hideOnMobile } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const AdditionalListControlsTrigger = styled.div`
  width: 32px;

  ${IconButtonWrapper} {
    width: 32px;
    height: 32px;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionsContainer = styled.div({
  display: 'inline-flex',
  alignSelf: 'center',
  justifyContent: 'space-between',
  marginBottom: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ActionListWrapper = styled.div<{ isMobileView?: boolean }>`
  margin-left: ${({ isMobileView }) => (isMobileView ? '0' : 'auto')};

  & > :empty {
    display: none !important;
    }

  ${({ isMobileView }) => (isMobileView ? hideOnDesktop() : hideOnMobile())}};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ActionListContainer = styled.div<{ isMobileView?: boolean }>(({ isMobileView }) => ({
  alignSelf: 'center',
  display: 'inline-flex',
  gap: token('space.100'),
  height: '32px',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: isMobileView ? '100%' : 'auto',
  button: {
    height: '100%',
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SaveViewText = styled.span({
  marginBlock: 0,
  marginInline: token('space.050'),
});
