import { FeatureGatesInitialization } from '@atlassian/feature-gates-react';
import React, { PropsWithChildren, useMemo } from 'react';

import { featureGatesOptions } from '@townsquare/stat-sig';
import { User } from '@townsquare/user-store';
import { WorkspaceDetails } from '@townsquare/workspace-store';

import { FullScreenSpaLoader } from '../ui/FullScreenSpaLoader';

type StatSigInitProps = {
  user: Pick<User, 'accountId'>;
  workspace: Pick<WorkspaceDetails, 'cloudId' | 'organisationId'>;
};

export function StatSigInit({ user, workspace, children }: PropsWithChildren<StatSigInitProps>) {
  const featureGatesIdentifiers = useMemo(
    () => ({
      atlassianAccountId: user.accountId,
      tenantId: workspace.cloudId,
      atlassianOrgId: workspace.organisationId,
    }),
    [user.accountId, workspace.cloudId, workspace.organisationId],
  );

  return (
    <FeatureGatesInitialization
      enabled
      options={featureGatesOptions}
      identifiers={featureGatesIdentifiers}
      initializingComponent={<FullScreenSpaLoader />}
    >
      {children}
    </FeatureGatesInitialization>
  );
}
