import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import React from 'react';
import { useIntl } from 'react-intl-next';

import { LearningType } from '@townsquare/learnings';

import { AddNewLearningsButtonWrapper, AddNewLearningsIconContainer } from './styles';

export type AddNewLearningButtonProps = {
  learningType: LearningType;
  onClick: (setter: boolean) => void;
};

export const AddNewLearningButton = (props: AddNewLearningButtonProps) => {
  const intl = useIntl();

  return (
    <AddNewLearningsButtonWrapper
      data-testid={`add-new-${props.learningType.toLowerCase()}-button`}
      onClick={() => {
        props.onClick(true);
      }}
    >
      <AddNewLearningsIconContainer>
        <EditorAddIcon
          label={intl.formatMessage(
            {
              id: 'townsquare.web.learnings-empty-state.add-new-learning-button',
              description: 'Add new learning button',
              defaultMessage: 'Add a new {learningType, select, risk {risk} decision {decision} other {learning}}',
            },
            {
              learningType: props.learningType.toLowerCase(),
            },
          )}
        />
      </AddNewLearningsIconContainer>

      <span>
        {intl.formatMessage(
          {
            id: 'townsquare.web.learnings-empty-state.add-new-learning',
            description: 'Add new learning button',
            defaultMessage: 'Add a new {learningType, select, risk {risk} decision {decision} other {learning}}',
          },
          {
            learningType: props.learningType.toLowerCase(),
          },
        )}
      </span>
    </AddNewLearningsButtonWrapper>
  );
};

export const EmptyStateAddNewLearningButton = (props: AddNewLearningButtonProps) => {
  const intl = useIntl();

  return (
    <div
      data-testid={`empty-state-add-new-${props.learningType.toLowerCase()}-button`}
      onClick={() => {
        props.onClick(true);
      }}
      // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
      style={{ width: '100%' }}
    >
      {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
      <Button style={{ width: 'inherit' }}>
        <span>
          {intl.formatMessage(
            {
              id: 'townsquare.web.learnings-empty-state-add-new-learning-button.button',
              description: 'Add new learning button',
              defaultMessage: 'Add a new {learningType, select, risk {risk} decision {decision} other {learning}}',
            },
            {
              learningType: props.learningType.toLowerCase(),
            },
          )}
        </span>
      </Button>
    </div>
  );
};
