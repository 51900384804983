/**
 * @generated SignedSource<<f4e913d72c14e3ac6172810cf4ac06bc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type GoalName$data = {
  readonly id: string;
  readonly key: string;
  readonly name: string;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"GoalIcon">;
  readonly " $fragmentType": "GoalName";
};
export type GoalName$key = {
  readonly " $data"?: GoalName$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalName">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "GoalName",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "uuid"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "kind": "FragmentSpread",
      "name": "GoalIcon"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    }
  ],
  "type": "Goal"
};

(node as any).hash = "d03e0f58c8e916c449aef6b3583e2316";

export default node;
