import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { useCollaborationGraphQuery } from '../../hooks/browsePeople';
import { Heading } from '../Heading';
import { ScreenType } from '../ScreenType';

import { BrowseAllPeopleRow } from './BrowseAllPeopleRow';
import { DefaultSearchFallBackWrapper } from './DefaultSearchFallBack';
import { PeopleRowLoadingSkeleton } from './PeopleRowLoadingSkeleton';

export const BrowsePeopleSection = () => {
  const intl = useIntl();
  const collaboratorsResponse = useCollaborationGraphQuery();

  const PeopleSection = useMemo(() => {
    if (collaboratorsResponse.loading) {
      return <PeopleRowLoadingSkeleton />;
    } else if (collaboratorsResponse.data && collaboratorsResponse.data.length !== 0 && !collaboratorsResponse.hasError)
      return <BrowseAllPeopleRow users={collaboratorsResponse.data} />;
    else {
      return <DefaultSearchFallBackWrapper />;
    }
  }, [collaboratorsResponse.data, collaboratorsResponse.hasError, collaboratorsResponse.loading]);

  return (
    <>
      <Heading
        browseMoreText={intl.formatMessage({
          id: 'townsquare.web.browse-people-section.browse-all',
          description: 'Browse more heading text',
          defaultMessage: 'Browse everyone',
        })}
        screenType={ScreenType.SEARCH_PEOPLE}
      >
        <FormattedMessage
          id="townsquare.web.browse-people-section.heading"
          description="Browse people section heading"
          defaultMessage="People you work with"
        />
      </Heading>
      {PeopleSection}
    </>
  );
};
