import React from 'react';

import { BrowseUser, PersonBrowseCard } from '../Cards/Browse/Person';
import { CardRow } from '../styles';

import { BROWSE_PEOPLE_CARD_LIMIT, FLEX_GAP_PX } from './constants';

interface BrowseAllPeopleRowProps {
  users: BrowseUser[];
}

export const BrowseAllPeopleRow = ({ users }: BrowseAllPeopleRowProps) => {
  return (
    <CardRow desktopFlexGapPx={FLEX_GAP_PX}>
      {users.slice(0, BROWSE_PEOPLE_CARD_LIMIT).map(user => (
        <PersonBrowseCard user={user} key={user.aaid} />
      ))}
    </CardRow>
  );
};
