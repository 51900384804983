import React, { createContext, useCallback, useState } from 'react';

import { noop } from '@townsquare/noop';

export interface HelpInterface {
  isOpen: boolean;
  setIsOpen(value: boolean): void;
}

export const HelpContext = createContext<HelpInterface>({
  isOpen: false,
  setIsOpen: noop,
});

export const HelpContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleSetIsOpen = useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);
  return <HelpContext.Provider value={{ isOpen, setIsOpen: handleSetIsOpen }}>{children}</HelpContext.Provider>;
};
