import { PostOfficeProvider } from '@atlassian/post-office-placement-remote';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-next';
import { useRouter } from 'react-resource-router';

import { ANALYTICS_PRODUCT_IDENTIFIER } from '@townsquare/config/constants';
import { RouteWithSubproduct } from '@townsquare/router';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { getPostOfficeEnv } from './utils/get-environment';

type PostOfficeContextProviderProps = { children: React.ReactNode };

/*
 * This component is used to provide the PostOfficeContext to the application. It provides the product, subproduct and locale at the application level.
 */
const PostOfficeContextProviderImpl = ({ children }: PostOfficeContextProviderProps) => {
  const { locale } = useIntl();
  const [workspace] = useWorkspaceStore();
  const [store] = useRouter();

  const [subproductState, setSubproductState] = useState('web');

  useEffect(() => {
    const route = store.route as RouteWithSubproduct;
    if (route.subproduct) {
      if (typeof route.subproduct === 'function') {
        setSubproductState(route.subproduct(store));
      } else {
        setSubproductState(route.subproduct);
      }
    }
  }, [store]);

  const context = useMemo(
    () => ({
      current: {
        product: ANALYTICS_PRODUCT_IDENTIFIER,
        subproduct: subproductState,
        locale: locale,
        tenantId: workspace?.cloudId,
      },
    }),
    [locale, subproductState, workspace?.cloudId],
  );

  const environment = useMemo(() => ({ env: getPostOfficeEnv() }), []);

  return (
    <PostOfficeProvider environment={environment} context={context}>
      {children}
    </PostOfficeProvider>
  );
};

export const PostOfficeContextProvider = PostOfficeContextProviderImpl;
