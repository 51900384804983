import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import TeamUpdatesViewQuery, {
  type TeamUpdatesViewQuery as TeamUpdatesViewQueryType,
} from '../TeamUpdatesView/__generated__/TeamUpdatesViewQuery.graphql';
import {
  SHOW_LIMIT,
  getActiveProjectsTql,
  getCompletedProjectsTql,
  getPausedProjectsTql,
  getPendingProjectsTql,
  getRelatedGoalsTql,
} from '../TeamUpdatesView/getTqlQueries';

// TQL can underfetch with perms - adding a slight buffer to make sure we get the SHOW_LIMIT amount of items.
const TQL_OVERFETCH = 3;
export const teamUpdatesResource = createRelayResource<TeamUpdatesViewQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const teamId = routerContext?.match?.params.id ?? '';
    return {
      query: TeamUpdatesViewQuery,
      variables: {
        teamId: teamId,
        workspaceUUID: context.workspace.UUID || '',
        workspaceId: context.workspace.globalId || '',
        activeProjectsTql: getActiveProjectsTql(teamId),
        pendingProjectsTql: getPendingProjectsTql(teamId),
        pausedProjectsTql: getPausedProjectsTql(teamId),
        completedProjectsTql: getCompletedProjectsTql(teamId),
        relatedGoalsTql: getRelatedGoalsTql(teamId),
        sidebarLimit: SHOW_LIMIT + TQL_OVERFETCH,
        cloudId: context.workspace.cloudId,
        isNavRefreshEnabled: isNavRefreshEnabled(context),
      },
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
