import { Text } from '@atlaskit/primitives';
import React from 'react';

import { useAnalytics } from '@townsquare/analytics';
import { stripUserARI } from '@townsquare/ari-utils';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { ProfilePicture } from '@townsquare/profile-picture';
import { profileRoute } from '@townsquare/ptc-directory-view';

import { NameText, ProfilePicBoundingBox, StyledPersonBrowseCard, UserDetails } from './styles';

export interface BrowseUser {
  name: string;
  aaid: string;
  picture?: string;
  jobTitle?: string;
}

export interface PersonBrowseCardProps {
  user: BrowseUser;
}

const DisplayName = ({ name }: Pick<BrowseUser, 'name'>) => (
  <EllipsisTooltip content={name}>
    <NameText>{name}</NameText>
  </EllipsisTooltip>
);

const JobTitle = ({ jobTitle }: Pick<BrowseUser, 'jobTitle'>) =>
  jobTitle ? (
    <EllipsisTooltip content={jobTitle}>
      <Text as="p" color="color.text.subtle" maxLines={1}>
        {jobTitle}
      </Text>
    </EllipsisTooltip>
  ) : null;

export const PersonBrowseCard = ({ user }: PersonBrowseCardProps) => {
  const analytics = useAnalytics();

  const onClick = () => {
    void analytics.ui('browsePersonCard', 'clicked');
  };

  const aaid = stripUserARI(user.aaid);

  return (
    <StyledPersonBrowseCard
      data-testid={`user-browse-card-${user.aaid}`}
      onClick={onClick}
      to={profileRoute}
      params={{ id: aaid }}
      key={user.aaid}
    >
      <ProfilePicBoundingBox>
        <ProfilePicture name={user.name} src={user.picture} height="100%" width="100%" />
      </ProfilePicBoundingBox>
      <UserDetails>
        <DisplayName name={user.name} />
        <JobTitle jobTitle={user.jobTitle} />
      </UserDetails>
    </StyledPersonBrowseCard>
  );
};
