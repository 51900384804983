/**
 * @generated SignedSource<<5a9333e8e483d1c1eb115cb2e30d26db>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type GoalEmbedQuery$variables = {
  id: string;
  isSpecificUpdate: boolean;
  key: string;
};
export type GoalEmbedQuery$data = {
  readonly goalByKey?: {
    readonly id: string;
    readonly updates: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"GoalEmbedUpdate">;
        } | null;
      } | null> | null;
    } | null;
    readonly workspace: {
      readonly uuid: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"GoalEmbedAbout" | "GoalEmbedRow">;
  } | null;
  readonly goalUpdate?: {
    readonly goal: {
      readonly id: string;
      readonly workspace: {
        readonly uuid: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"GoalEmbedAbout" | "GoalEmbedRow">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"GoalEmbedUpdate">;
  } | null;
};
export type GoalEmbedQuery = {
  response: GoalEmbedQuery$data;
  variables: GoalEmbedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSpecificUpdate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "key"
},
v3 = [
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "key"
  }
],
v4 = {
  "kind": "FragmentSpread",
  "name": "GoalEmbedAbout"
},
v5 = {
  "kind": "FragmentSpread",
  "name": "GoalEmbedRow"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v8 = {
  "concreteType": "Workspace",
  "kind": "LinkedField",
  "name": "workspace",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ]
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v10 = {
  "kind": "FragmentSpread",
  "name": "GoalEmbedUpdate"
},
v11 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v12 = {
  "kind": "ScalarField",
  "name": "key"
},
v13 = {
  "kind": "ScalarField",
  "name": "name"
},
v14 = {
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": [
    {
      "concreteType": "UserPII",
      "kind": "LinkedField",
      "name": "pii",
      "plural": false,
      "selections": [
        (v13/*: any*/),
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        {
          "kind": "ScalarField",
          "name": "accountStatus"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "aaid"
    },
    (v6/*: any*/)
  ]
},
v15 = {
  "concreteType": "Workspace",
  "kind": "LinkedField",
  "name": "workspace",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v6/*: any*/)
  ]
},
v16 = {
  "kind": "ScalarField",
  "name": "description"
},
v17 = {
  "kind": "ScalarField",
  "name": "score"
},
v18 = {
  "kind": "ScalarField",
  "name": "value"
},
v19 = {
  "args": [
    {
      "kind": "Literal",
      "name": "includeScore",
      "value": false
    }
  ],
  "kind": "ScalarField",
  "name": "label",
  "storageKey": "label(includeScore:false)"
},
v20 = [
  {
    "kind": "ScalarField",
    "name": "messageId"
  }
],
v21 = {
  "concreteType": "LocalizationField",
  "kind": "LinkedField",
  "name": "localizedLabel",
  "plural": false,
  "selections": (v20/*: any*/)
},
v22 = {
  "concreteType": "GoalState",
  "kind": "LinkedField",
  "name": "state",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/),
    (v21/*: any*/),
    {
      "alias": "goalStateValue",
      "kind": "ScalarField",
      "name": "value"
    },
    {
      "concreteType": "CompletionGoalState",
      "kind": "LinkedField",
      "name": "atCompletionState",
      "plural": false,
      "selections": [
        (v19/*: any*/),
        (v21/*: any*/),
        (v18/*: any*/),
        (v17/*: any*/)
      ]
    }
  ]
},
v23 = [
  {
    "kind": "Literal",
    "name": "longFormat",
    "value": true
  }
],
v24 = {
  "concreteType": "TargetDate",
  "kind": "LinkedField",
  "name": "dueDate",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "confidence"
    },
    {
      "kind": "ScalarField",
      "name": "label"
    },
    (v21/*: any*/),
    {
      "alias": "tooltip",
      "args": (v23/*: any*/),
      "kind": "ScalarField",
      "name": "label",
      "storageKey": "label(longFormat:true)"
    },
    {
      "alias": "localizedTooltip",
      "args": (v23/*: any*/),
      "concreteType": "LocalizationField",
      "kind": "LinkedField",
      "name": "localizedLabel",
      "plural": false,
      "selections": (v20/*: any*/),
      "storageKey": "localizedLabel(longFormat:true)"
    },
    {
      "kind": "ScalarField",
      "name": "overdue"
    }
  ]
},
v25 = {
  "kind": "ScalarField",
  "name": "hasSubgoalMatches"
},
v26 = {
  "concreteType": "GoalConnection",
  "kind": "LinkedField",
  "name": "subGoals",
  "plural": false,
  "selections": [
    {
      "concreteType": "GoalEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        }
      ]
    }
  ]
},
v27 = {
  "kind": "ScalarField",
  "name": "summary"
},
v28 = {
  "kind": "ScalarField",
  "name": "missedUpdate"
},
v29 = {
  "kind": "ScalarField",
  "name": "updateType"
},
v30 = {
  "kind": "ScalarField",
  "name": "creationDate"
},
v31 = {
  "kind": "ScalarField",
  "name": "canPostUpdate"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "GoalEmbedQuery",
    "selections": [
      {
        "condition": "isSpecificUpdate",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "Goal",
            "kind": "LinkedField",
            "name": "goalByKey",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "args": (v9/*: any*/),
                "concreteType": "GoalUpdateConnection",
                "kind": "LinkedField",
                "name": "updates",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "GoalUpdateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "GoalUpdate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "updates(first:1)"
              }
            ]
          }
        ]
      },
      {
        "condition": "isSpecificUpdate",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v11/*: any*/),
            "concreteType": "GoalUpdate",
            "kind": "LinkedField",
            "name": "goalUpdate",
            "plural": false,
            "selections": [
              {
                "concreteType": "Goal",
                "kind": "LinkedField",
                "name": "goal",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              },
              (v10/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "GoalEmbedQuery",
    "selections": [
      {
        "condition": "isSpecificUpdate",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "Goal",
            "kind": "LinkedField",
            "name": "goalByKey",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v14/*: any*/),
              (v7/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v6/*: any*/),
              (v22/*: any*/),
              (v13/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              {
                "args": (v9/*: any*/),
                "concreteType": "GoalUpdateConnection",
                "kind": "LinkedField",
                "name": "updates",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "GoalUpdateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "GoalUpdate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v27/*: any*/),
                          (v28/*: any*/),
                          (v29/*: any*/),
                          (v30/*: any*/),
                          {
                            "concreteType": "Goal",
                            "kind": "LinkedField",
                            "name": "goal",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v7/*: any*/),
                              (v31/*: any*/),
                              (v6/*: any*/)
                            ]
                          },
                          (v6/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "updates(first:1)"
              }
            ]
          }
        ]
      },
      {
        "condition": "isSpecificUpdate",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v11/*: any*/),
            "concreteType": "GoalUpdate",
            "kind": "LinkedField",
            "name": "goalUpdate",
            "plural": false,
            "selections": [
              {
                "concreteType": "Goal",
                "kind": "LinkedField",
                "name": "goal",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v15/*: any*/),
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v7/*: any*/),
                  (v16/*: any*/),
                  (v22/*: any*/),
                  (v13/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v31/*: any*/)
                ]
              },
              (v7/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "4e70435092c0f1f1128b3f4d09e4ad3b",
    "id": null,
    "metadata": {},
    "name": "GoalEmbedQuery",
    "operationKind": "query",
    "text": "query GoalEmbedQuery(\n  $key: String!\n  $id: ID!\n  $isSpecificUpdate: Boolean!\n) {\n  goalByKey(key: $key) @skip(if: $isSpecificUpdate) {\n    ...GoalEmbedAbout\n    ...GoalEmbedRow\n    id\n    workspace {\n      uuid\n      id\n    }\n    updates(first: 1) {\n      edges {\n        node {\n          ...GoalEmbedUpdate\n          id\n        }\n      }\n    }\n  }\n  goalUpdate(id: $id) @include(if: $isSpecificUpdate) {\n    goal {\n      id\n      workspace {\n        uuid\n        id\n      }\n      ...GoalEmbedAbout\n      ...GoalEmbedRow\n    }\n    ...GoalEmbedUpdate\n    id\n  }\n}\n\nfragment GoalEmbedAbout on Goal {\n  key\n  owner {\n    pii {\n      name\n    }\n    aaid\n    id\n  }\n  uuid\n  workspace {\n    uuid\n    id\n  }\n  description\n}\n\nfragment GoalEmbedRow on Goal {\n  ...GoalName\n  id\n  owner {\n    ...UserAvatar_1cDMtx\n    id\n  }\n  state {\n    ...GoalState\n  }\n  dueDate {\n    ...TargetDate\n  }\n  hasSubgoalMatches\n  subGoals {\n    edges {\n      __typename\n    }\n  }\n}\n\nfragment GoalEmbedUpdate on GoalUpdate {\n  uuid\n  summary\n  missedUpdate\n  updateType\n  creationDate\n  goal {\n    key\n    uuid\n    canPostUpdate\n    id\n  }\n}\n\nfragment GoalIcon on Goal {\n  state {\n    score\n    value\n  }\n}\n\nfragment GoalName on Goal {\n  uuid\n  id\n  key\n  ...GoalIcon\n  name\n}\n\nfragment GoalState on GoalState {\n  label(includeScore: false)\n  localizedLabel {\n    messageId\n  }\n  score\n  goalStateValue: value\n  atCompletionState {\n    label(includeScore: false)\n    localizedLabel {\n      messageId\n    }\n    value\n    score\n  }\n}\n\nfragment TargetDate on TargetDate {\n  confidence\n  label\n  localizedLabel {\n    messageId\n  }\n  tooltip: label(longFormat: true)\n  localizedTooltip: localizedLabel(longFormat: true) {\n    messageId\n  }\n  overdue\n}\n\nfragment UserAvatar_1cDMtx on User {\n  aaid\n  pii {\n    picture\n    name\n    accountStatus\n  }\n}\n"
  }
};
})();

(node as any).hash = "16c6be54b7ad8fa5abff7669e89394b3";

export default node;
