/**
 * @generated SignedSource<<86696a94a7fcffcd656c54b194bff3d9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type NewLearningsPanel$data = {
  readonly tags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null;
        readonly watcherCount: number;
      } | null;
    } | null> | null;
  } | null;
  readonly watcherCount: number;
  readonly " $fragmentType": "NewLearningsPanel";
};
export type NewLearningsPanel$key = {
  readonly " $data"?: NewLearningsPanel$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewLearningsPanel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "watcherCount"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagCount"
    }
  ],
  "kind": "Fragment",
  "name": "NewLearningsPanel",
  "selections": [
    (v0/*: any*/),
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "tagCount"
        }
      ],
      "concreteType": "TagConnection",
      "kind": "LinkedField",
      "name": "tags",
      "plural": false,
      "selections": [
        {
          "concreteType": "TagEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "name"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Project"
};
})();

(node as any).hash = "6ad50cca0abe61ac4c78ee6daa90f49d";

export default node;
