/**
 * @generated SignedSource<<3bf6259c9ec97b0c6f96451d68201195>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DirectorySortEnum = "NAME_ASC" | "NAME_DESC";
export type ProfilePictureSize = "LARGE" | "MAXIMUM" | "MEDIUM";
export type DefaultSearchFallBackPeopleSearchQuery$variables = {
  after?: string | null;
  cloudId: string;
  first: number;
  organisationId: string;
  profilePicSize: ProfilePictureSize;
  query: string;
  sort: ReadonlyArray<DirectorySortEnum | null>;
};
export type DefaultSearchFallBackPeopleSearchQuery$data = {
  readonly peopleTql: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly account_id: string | null;
        readonly highResolutionProfilePicture: {
          readonly url: string | null;
        } | null;
        readonly job_title: string | null;
        readonly name: string | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};
export type DefaultSearchFallBackPeopleSearchQuery = {
  response: DefaultSearchFallBackPeopleSearchQuery$data;
  variables: DefaultSearchFallBackPeopleSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "profilePicSize"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v7 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "cloudId",
        "variableName": "cloudId"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "organisationId",
        "variableName": "organisationId"
      },
      {
        "kind": "Variable",
        "name": "q",
        "variableName": "query"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "CpusSearchUserConnection",
    "kind": "LinkedField",
    "name": "peopleTql",
    "plural": false,
    "selections": [
      {
        "concreteType": "CpusSearchUserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "concreteType": "CpusSearchUser",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "name"
              },
              {
                "kind": "ScalarField",
                "name": "account_id"
              },
              {
                "kind": "ScalarField",
                "name": "job_title"
              },
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "size",
                    "variableName": "profilePicSize"
                  }
                ],
                "concreteType": "HighResolutionProfilePicture",
                "kind": "LinkedField",
                "name": "highResolutionProfilePicture",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "url"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "startCursor"
          },
          {
            "kind": "ScalarField",
            "name": "endCursor"
          },
          {
            "kind": "ScalarField",
            "name": "hasNextPage"
          },
          {
            "kind": "ScalarField",
            "name": "hasPreviousPage"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "DefaultSearchFallBackPeopleSearchQuery",
    "selections": (v7/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DefaultSearchFallBackPeopleSearchQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "fc73667bd0e9d076d8057ab8740230f6",
    "id": null,
    "metadata": {},
    "name": "DefaultSearchFallBackPeopleSearchQuery",
    "operationKind": "query",
    "text": "query DefaultSearchFallBackPeopleSearchQuery(\n  $query: String!\n  $first: Int!\n  $sort: [DirectorySortEnum]!\n  $profilePicSize: ProfilePictureSize!\n  $organisationId: String!\n  $cloudId: String!\n  $after: String\n) {\n  peopleTql(q: $query, first: $first, after: $after, sort: $sort, organisationId: $organisationId, cloudId: $cloudId) {\n    edges {\n      node {\n        name\n        account_id\n        job_title\n        highResolutionProfilePicture(size: $profilePicSize) {\n          url\n        }\n      }\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b86440ddb9b7932d1ea248ab92b4972a";

export default node;
