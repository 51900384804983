import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DisplayedItem, HiddenItem, SingleRow } from './styles';

interface ItemObserverProps {
  children: React.ReactNode;
  index: number;
  onIntersectionChanged: (entry: IntersectionObserverEntry, index: number) => void;
  threshold?: number | number[];
}

const ItemObserver = ({ children, index, onIntersectionChanged, threshold }: ItemObserverProps) => {
  const ref = useRef(null);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      ([entry]: IntersectionObserverEntry[]) => onIntersectionChanged(entry, index),
      {
        threshold,
      },
    );

    if (ref.current) {
      intersectionObserver.observe(ref.current);
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, [index, onIntersectionChanged, threshold]);

  return <DisplayedItem innerRef={ref}>{children}</DisplayedItem>;
};

/**
 * Invisible div to detect when there is enough space to show the next items from the overflow menu
 */
const ShowNextItemDetector = ({ children, onIntersectionChanged, index }: ItemObserverProps) => {
  return (
    <ItemObserver onIntersectionChanged={onIntersectionChanged} index={index} threshold={1}>
      <HiddenItem>{children}</HiddenItem>
    </ItemObserver>
  );
};

interface DynamicOverflowRowProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  renderOverflowMenu: (overflowItems: T[], areAllItemsHidden: boolean) => React.ReactNode;
  maxItemsToShowBeforeOverflow: number;
}

export const DynamicOverflowRow = <T,>({
  items,
  renderItem,
  renderOverflowMenu,
  maxItemsToShowBeforeOverflow,
}: DynamicOverflowRowProps<T>) => {
  const [itemLimit, setItemLimit] = useState(items.length);

  // Force at least two items to be hidden if one has to be, so user's aren't like "why is there only one item in the menu?"
  const isOnlyOneItemCutOff = items.length > 1 && itemLimit === items.length - 1;

  const splitIndex = isOnlyOneItemCutOff ? items.length - 2 : Math.min(itemLimit, maxItemsToShowBeforeOverflow);

  const isOverflowing = splitIndex < items.length;

  const onItemCutOff = useCallback((entry: IntersectionObserverEntry, index: number) => {
    // Item placed in overflow menu if even partially cut off
    if (!entry.isIntersecting || entry.intersectionRatio !== 1) {
      setItemLimit(curr => {
        if (index < curr) {
          return index;
        }
        return curr;
      });
    }
  }, []);

  const onAdditionalSpaceAvailable = useCallback(
    (entry: IntersectionObserverEntry, index: number) => {
      // Check if there's enough space to return the hidden items on a resize
      if (entry.isIntersecting && entry.intersectionRatio === 1) {
        setItemLimit(curr => {
          if (index >= curr) {
            return curr + 1;
          } else if (isOnlyOneItemCutOff) {
            // Since two items hidden in this case
            return curr + 2;
          }
          return curr;
        });
      }
    },
    [isOnlyOneItemCutOff],
  );

  const onOverflowMenuIntersected = useCallback((entry: IntersectionObserverEntry) => {
    // When the overflow menu is cut off we need to remove an item to make room for it
    if (!entry.isIntersecting || entry.intersectionRatio !== 1) {
      setItemLimit(curr => Math.max(0, curr - 1));
    }
  }, []);

  return (
    <SingleRow>
      {splitIndex > 0 &&
        items.slice(0, splitIndex).map((item, index) => (
          <ItemObserver index={index} key={index} threshold={[0, 1]} onIntersectionChanged={onItemCutOff}>
            {renderItem(item, index)}
          </ItemObserver>
        ))}
      {isOverflowing && (
        <ItemObserver onIntersectionChanged={onOverflowMenuIntersected} index={-1} threshold={[0, 1]}>
          {renderOverflowMenu(items.slice(splitIndex), splitIndex === 0)}
        </ItemObserver>
      )}
      {isOverflowing && (
        <ShowNextItemDetector index={splitIndex} onIntersectionChanged={onAdditionalSpaceAvailable}>
          {renderItem(items[splitIndex], splitIndex)}
          {isOnlyOneItemCutOff && renderItem(items[splitIndex + 1], splitIndex + 1)}
        </ShowNextItemDetector>
      )}
    </SingleRow>
  );
};
