import Loadable from 'react-loadable';

import { FULL_PAGE_SEARCH_PAGE, FULL_PAGE_SEARCH_PATH } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';

export const searchRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "search-view" */ './SearchView'),
    loading: () => null,
  }),
  exact: true,
  name: FULL_PAGE_SEARCH_PAGE,
  path: FULL_PAGE_SEARCH_PATH,
  query: ['text?'],
});
