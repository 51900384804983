import Button from '@atlaskit/button';
import CloseIcon from '@atlaskit/icon/glyph/cross';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl-next';
import { useRelayEnvironment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';

import { saveOnboarding } from '../mutations/SaveOnboardingMutation';

import { OnboardingItemKey } from './__generated__/OnboardingQuery.graphql';
import {
  BannerContainer,
  CloseButton,
  Container,
  Content,
  ContentWithMargins,
  PrimaryBannerButton,
  Row,
  SecondaryBannerButton,
  Title,
} from './style';
import {
  BannerButtonProps,
  BannerCustomMarginStyling,
  OnboardingAppearance,
  SecondaryBannerButtonProps,
} from './types';

interface OnboardingBannerProps {
  title?: string;
  content: ReactElement;
  appearance?: OnboardingAppearance;
  analyticsName: string;
  customMargins: BannerCustomMarginStyling;
  buttonInfo?: BannerButtonProps;
  secondButtonInfo?: SecondaryBannerButtonProps;
  connectionId: string;
  onboardingKey: OnboardingItemKey;
}

interface ContentProps {
  title?: string;
  content: ReactElement;
  analyticsName: string;
  dismissBanner: () => void;
  appearance: OnboardingAppearance;
}

export const BaseOnboardingBanner = (props: OnboardingBannerProps): JSX.Element | null => {
  const analytics = useAnalytics();
  const {
    title,
    buttonInfo,
    customMargins,
    secondButtonInfo,
    connectionId,
    analyticsName,
    content,
    onboardingKey,
    appearance = 'PRIMARY',
  } = props;
  const environment = useRelayEnvironment();
  const [showOnboardingBanner, setShowOnboardingBanner] = useState(true);

  const onSendAction = useCallback(() => {
    saveOnboarding(environment, { onboardingKey }, [connectionId]);
  }, [connectionId, environment, onboardingKey]);

  const dismissBanner = () => {
    setShowOnboardingBanner(false);
    onSendAction();
  };

  useEffect(() => {
    void analytics.ui('onboardingBanner', 'shown', {
      bannerType: analyticsName,
    });
  }, [analytics, analyticsName]);

  return showOnboardingBanner ? (
    <Container customMargins={customMargins}>
      <BannerContainer appearance={appearance}>
        {title ? (
          <RenderContentWithTitle
            title={title}
            content={content}
            analyticsName={analyticsName}
            dismissBanner={dismissBanner}
            appearance={appearance}
          />
        ) : (
          <RenderContentWithoutTitle
            content={content}
            analyticsName={analyticsName}
            dismissBanner={dismissBanner}
            appearance={appearance}
          />
        )}
        {buttonInfo && (
          <PrimaryBannerButton
            onClick={event => {
              event.stopPropagation();
              void analytics.ui('onboardingBannerButton', 'clicked', {
                bannerType: analyticsName,
                buttonType: 'primary',
              });

              buttonInfo.onClick?.();
              if (buttonInfo.shouldDismissBannerOnClick) {
                dismissBanner();
              }
            }}
          >
            {buttonInfo.content}
          </PrimaryBannerButton>
        )}
        {secondButtonInfo && (
          <SecondaryBannerButton
            buttonType={secondButtonInfo.type}
            appearance={secondButtonInfo.type === 'link' ? 'subtle-link' : undefined}
            onClick={event => {
              event.stopPropagation();
              void analytics.ui('onboardingBannerButton', 'clicked', {
                bannerType: analyticsName,
                buttonType: 'secondary',
              });

              secondButtonInfo.onClick?.();
              if (secondButtonInfo.shouldDismissBannerOnClick) {
                dismissBanner();
              }
            }}
          >
            {secondButtonInfo.content}
          </SecondaryBannerButton>
        )}
      </BannerContainer>
    </Container>
  ) : null;
};

const RenderContentWithTitle = (props: ContentProps) => {
  const analytics = useAnalytics();
  const intl = useIntl();

  const { title, content, analyticsName, dismissBanner, appearance } = props;

  return (
    <>
      <Row>
        <Title appearance={appearance}> {title} </Title>
        <CloseButton appearance={appearance}>
          <Button
            appearance="subtle"
            iconBefore={
              <CloseIcon
                label={intl.formatMessage({
                  id: 'townsquare.onboarding.content-with-title.button-icon',
                  description: 'Close icon label',
                  defaultMessage: 'Close banner',
                })}
                size="small"
              />
            }
            spacing="none"
            tabIndex={-1}
            onClick={() => {
              dismissBanner();
              void analytics.ui('onboardingBanner', 'closed', {
                bannerType: analyticsName,
              });
            }}
          />
        </CloseButton>
      </Row>
      <ContentWithMargins appearance={appearance}>{content}</ContentWithMargins>
    </>
  );
};

const RenderContentWithoutTitle = (props: ContentProps) => {
  const { content, analyticsName, dismissBanner, appearance } = props;

  const analytics = useAnalytics();
  const intl = useIntl();

  return (
    <>
      <Row>
        <Content appearance={appearance}>{content}</Content>
        <CloseButton appearance={appearance}>
          <Button
            appearance="subtle"
            iconBefore={
              <CloseIcon
                label={intl.formatMessage({
                  id: 'townsquare.onboarding.content-without-title.button-icon',
                  description: 'Close icon label',
                  defaultMessage: 'Close banner',
                })}
                size="small"
              />
            }
            spacing="none"
            tabIndex={-1}
            onClick={() => {
              dismissBanner();
              void analytics.ui('onboardingBanner', 'closed', {
                bannerType: analyticsName,
              });
            }}
          />
        </CloseButton>
      </Row>
    </>
  );
};
