import React from 'react';

import { UpdateCardSkeletonWithReply } from '@townsquare/card';
import { SectionTitle, SectionWrapper } from '@townsquare/info-section';
import { LoadingSkeleton } from '@townsquare/loading-skeleton';
import { AsideColumn, MainColumn, ResponsivePageWithSidebar, ResponsiveSection } from '@townsquare/responsive-grid';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { BlankIconOrAvatar } from '@townsquare/user-avatar';

import {
  MetaActionsWrapper,
  UserWrapper,
  SidebarWrapper,
  MainSectionWrapper,
  HeaderWrapper,
  TitleAndKeyWrapper,
  StatusAndDateWrapper,
  TabListWrapper,
  TabListUnderline,
  ContentWrapper,
} from './styles';

const MetaActionsSkeleton = () => (
  <MetaActionsWrapper>
    <LoadingSkeleton width="90px" height="32px" />
    <LoadingSkeleton width="66px" height="32px" />
    <LoadingSkeleton width="32px" height="32px" />
    <LoadingSkeleton width="32px" height="32px" />
    <LoadingSkeleton width="80px" height="20px" />
  </MetaActionsWrapper>
);

const UserInfoSectionSkeleton = () => (
  <SectionWrapper>
    <SectionTitle>
      <LoadingSkeleton width="100px" height="20px" />
    </SectionTitle>
    <UserWrapper>
      <BlankIconOrAvatar size="small" isAvatar />
      <LoadingSkeleton marginLeftPx={14} width="150px" height="20px" />
    </UserWrapper>
  </SectionWrapper>
);

const SidebarSectionSkeleton = () => (
  <SidebarWrapper>
    <MetaActionsSkeleton />
    <UserInfoSectionSkeleton />
  </SidebarWrapper>
);

const MainSectionSkeleton = () => (
  <MainSectionWrapper>
    <HeaderWrapper>
      <LoadingSkeleton width="48px" height="48px" />
      <TitleAndKeyWrapper>
        <LoadingSkeleton width="80px" height="16px" />
        <LoadingSkeleton width="240px" height="22px" />
      </TitleAndKeyWrapper>
      <StatusAndDateWrapper>
        <LoadingSkeleton width="120px" height="20px" />
      </StatusAndDateWrapper>
    </HeaderWrapper>
    <TabListWrapper>
      <LoadingSkeleton width="60px" height="16px" />
      <LoadingSkeleton width="80px" height="16px" />
    </TabListWrapper>
    <TabListUnderline />
    <ContentWrapper>
      <UpdateCardSkeletonWithReply />
      <UpdateCardSkeletonWithReply />
    </ContentWrapper>
  </MainSectionWrapper>
);

export const GoalViewSkeleton = () => {
  const isNavRefreshEnabled = useIsNavRefreshEnabled();

  return (
    <ResponsivePageWithSidebar>
      <ResponsiveSection>
        <MainColumn>
          <MainSectionSkeleton />
        </MainColumn>
        {!isNavRefreshEnabled && (
          <AsideColumn>
            <SidebarSectionSkeleton />
          </AsideColumn>
        )}
      </ResponsiveSection>
    </ResponsivePageWithSidebar>
  );
};
