import { validate as uuidValidate } from 'uuid';

import type { ColumnInfo } from '@townsquare/columns/types';
import { startDateMax, targetDateMin } from '@townsquare/project-timeline';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

export function getCustomColumnRelayVariables(columns: ColumnInfo[]) {
  const includedCustomFieldUuids = columns
    .filter(column => column.custom === true && uuidValidate(column.id))
    .map(column => column.id);
  return {
    includedCustomFieldUuids: includedCustomFieldUuids,
  };
}

export function getStandardColumnRelayVariables(columns: ColumnInfo[]) {
  return {
    includeContributors: columns.some(column => column.id === 'contributors'),
    includeFollowerCount: columns.some(column => column.id === 'followerCount'),
    includeFollowing: columns.some(column => column.id === 'following'),
    includeLastUpdated: columns.some(column => column.id === 'lastUpdated'),
    includeOwner: columns.some(column => column.id === 'owner'),
    includeRelatedProjects: columns.some(column => column.id === 'relatedProjects'),
    includeStatus: columns.some(column => column.id === 'status'),
    includeTargetDate: columns.some(column => column.id === 'targetDate'),
    includeTeam: columns.some(column => column.id === 'team'),
    includeGoals: columns.some(column => column.id === 'goals'),
    includeTags: columns.some(column => column.id === 'tags'),
    includeStartDate: columns.some(column => column.id === 'startDate'),
  };
}

export const getDefaultTableTqlQuery = (userId: string, isNavRefreshEnabled: boolean) =>
  tqlQueryFromInput({
    input: [
      {
        fieldName: 'archived',
        fieldValue: false,
        comparator: ComparatorOperator.EQUALS,
      },
      ...(isNavRefreshEnabled
        ? [
            {
              fieldName: 'contributor',
              fieldValue: userId,
              comparator: ComparatorOperator.EQUALS,
            },
          ]
        : []),
    ],
  });

export const getDefaultTimelineTqlQuery = (userId: string, isNavRefreshEnabled: boolean) =>
  tqlQueryFromInput({
    input: [
      {
        fieldName: 'archived',
        fieldValue: false,
        comparator: ComparatorOperator.EQUALS,
      },
      {
        fieldName: 'startDate',
        fieldValue: startDateMax,
        comparator: ComparatorOperator.LT,
      },
      {
        fieldName: 'targetDate',
        fieldValue: targetDateMin,
        comparator: ComparatorOperator.GTE,
      },
      ...(isNavRefreshEnabled
        ? [
            {
              fieldName: 'contributor',
              fieldValue: userId,
              comparator: ComparatorOperator.EQUALS,
            },
          ]
        : []),
    ],
  });
