import Loadable from 'react-loadable';

export { rovoEntitlementResource } from './resources/rovoEntitlementResource';

export const ChatView = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "ChatView" */ './components/ChatView/index'),
  loading: () => null,
});

export const CreateAgentView = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "CreateAgentView" */ './components/CreateAgentView/index'),
  loading: () => null,
});

export const EditAgentView = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "EditAgentView" */ './components/EditAgentView/index'),
  loading: () => null,
});
