import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

import { onMobile } from '@townsquare/responsive-grid';

export interface SearchResultsContainerStyleProps {
  columnGapPx?: number;
  rowGapPx?: number;
  margin?: string;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultsContainer = styled.div<SearchResultsContainerStyleProps>(
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  ({ columnGapPx, rowGapPx, margin }) => ({
    display: 'flex',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    columnGap: columnGapPx ? `${columnGapPx}px` : token('space.200'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    rowGap: rowGapPx ? `${rowGapPx}px` : token('space.200'),
    flexWrap: 'wrap',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    margin: margin ? margin : undefined,
  }),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      gap: token('space.100'),
    }),
  ),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ScrollingResultsContainer = styled.div({
  marginBottom: token('space.500'),
});
