import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { Stack } from '@atlaskit/primitives';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useRefetchableFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { useOnMount } from '@townsquare/hooks';
import { filterNull } from '@townsquare/type-helpers';

import { PersonSearchResultCard } from '../Cards/Search/Person';
import { TeamSearchResultCard } from '../Cards/SearchResult/Team';
import { EmptySearchState } from '../EmptySearchState/SearchEmptyState';
import { EmptyStateSearchAllSectionText } from '../styles';
import { ClearSearchQueryFn } from '../types';

import { ScreenType } from './ScreenType';
import { SearchAllQuery } from './__generated__/SearchAllQuery.graphql';
import { SearchAllScreen_data$key } from './__generated__/SearchAllScreen_data.graphql';
import { SearchResultsContainer } from './styles';

const PERSON_CARD_LIMIT = 8;
const TEAM_CARD_LIMIT = 9;

const EmptyStateText = ({
  onClearFiltersClicked,
  entity,
}: {
  onClearFiltersClicked: () => void;
  entity: 'people' | 'teams';
}) => (
  <EmptyStateSearchAllSectionText>
    <FormattedMessage
      id="townsquare.web.search-all-screen.empty-state-text"
      description="Text for the empty state on the search all screen"
      defaultMessage="{entity, select, people {We couldn't find any people matching your search. Try changing your search criteria or <button>clear all filters</button>} other {We couldn't find any teams matching your search. Try changing your search criteria or <button>clear all filters</button>}}"
      values={{
        entity,
        button: (chunks: string) => (
          <Button appearance="link" spacing="compact" onClick={onClearFiltersClicked}>
            {chunks}
          </Button>
        ),
      }}
    />
  </EmptyStateSearchAllSectionText>
);

interface ShowMoreResultsButtonProps {
  excessResultCount: number;
  screenType: ScreenType;
}

export interface SearchAllScreenProps {
  data: SearchAllScreen_data$key;
  tql: string;
  updateScreen: (newScreen: ScreenType) => void;
  clearSearchQuery: ClearSearchQueryFn;
}

export const SearchAllScreen = (props: SearchAllScreenProps) => {
  // TODO: TC-4409 add sorting to SearchPeopleScreen/ScrollingResultsScreen when sorting people does what we expect
  const [data, refetch] = useRefetchableFragment<SearchAllQuery, SearchAllScreen_data$key>(
    graphql`
      fragment SearchAllScreen_data on Query
      @refetchable(queryName: "SearchAllQuery")
      @argumentDefinitions(
        teamQuery: { type: "String!" }
        userQuery: { type: "String!" }
        first: { type: "Int", defaultValue: 12 }
        after: { type: "String" }
        sort: { type: "[DirectoryTeamSortEnum]" }
        organisationId: { type: "String!" }
        cloudId: { type: "String!" }
        isSearchAllScreen: { type: "Boolean!" }
      ) {
        peopleTql(q: $userQuery, organisationId: $organisationId, cloudId: $cloudId, first: $first, after: $after)
          @include(if: $isSearchAllScreen) {
          edges {
            node {
              account_id
              ...PersonSearchResultCard
            }
          }
        }
        searchTeams: teamsTql(
          q: $teamQuery
          organisationId: $organisationId
          cloudId: $cloudId
          sort: $sort
          first: $first
          after: $after
        ) @include(if: $isSearchAllScreen) {
          edges {
            node {
              id
              ...TeamSearchResultCard
            }
          }
        }
      }
    `,
    props.data,
  );
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      return;
    }
    const query = props.tql ?? '';
    // TODO: TC-2526 This needs to be PERSON_CARD_LIMIT but I have added more because total count is broken in CPUS
    refetch({ teamQuery: query, userQuery: query, first: PERSON_CARD_LIMIT + 4, sort: ['NAME_ASC'] });
  }, [props.tql, refetch]);

  const analytics = useAnalytics();
  useOnMount(() => {
    hasMounted.current = true;
    void analytics.ui('staffDirectorySearchAllScreen', 'viewed');
  });

  const excessPeople = (data.peopleTql?.edges?.length ?? 0) - PERSON_CARD_LIMIT;
  const excessTeams = (data.searchTeams?.edges?.length ?? 0) - TEAM_CARD_LIMIT;

  const onClearFiltersClicked = () => {
    void analytics.ui('staffDirectorySearchAllScreenClearFilters', 'clicked');
    props.clearSearchQuery();
  };

  const teamCards = useMemo(() => {
    return (
      data.searchTeams?.edges
        ?.slice(0, TEAM_CARD_LIMIT)
        ?.map(edge => {
          if (!edge || !edge.node || !edge.node.id) {
            return null;
          }
          const teamId = edge.node.id.replace('ari:cloud:identity::team/', '');
          return <TeamSearchResultCard key={`search-teams-result-${teamId}`} data={edge.node} />;
        })
        .filter(filterNull) ?? []
    );
  }, [data.searchTeams?.edges]);

  const peopleCards = useMemo(() => {
    return (
      data.peopleTql?.edges
        ?.slice(0, PERSON_CARD_LIMIT)
        ?.map(edge => {
          if (!edge || !edge.node) {
            return null;
          }
          return <PersonSearchResultCard key={`search-people-result-${edge.node.account_id}`} data={edge.node} />;
        })
        .filter(filterNull) ?? []
    );
  }, [data.peopleTql?.edges]);

  const ShowMoreResultsButton = useCallback(
    ({ excessResultCount, screenType }: ShowMoreResultsButtonProps) =>
      excessResultCount > 0 ? (
        <Button
          onClick={() => {
            props.updateScreen(screenType);
            void analytics.ui('staffDirectorySearchAllScreenShowMoreBtn', 'clicked', {
              navigateToScreen: ScreenType[screenType],
            });
          }}
        >
          {
            // TODO: TC-2840 reinstate when total result count works
            /* Show {excessResultCount} more results */
          }
          <FormattedMessage
            id="townsquare.web.search-all-screen.show-more-results"
            description="Text for the show more results button on the search all screen"
            defaultMessage="Show more results"
          />
        </Button>
      ) : null,
    [analytics, props],
  );

  if (peopleCards.length === 0 && teamCards.length === 0) {
    return <EmptySearchState clearSearchQuery={props.clearSearchQuery} resultType="people or teams" />;
  }

  return (
    <>
      <Stack space="space.250" alignInline="start">
        {
          // TODO: TC-2840 reinstate when total result count works
          /* <Heading size="medium" as="h4">{userResultsCount + teamsResultsCount} results</Heading> */
        }
        <Heading size="small">
          <FormattedMessage
            id="townsquare.web.search-all-screen.people-heading"
            description="Heading for the people section of the search all screen"
            defaultMessage="People"
          />
        </Heading>
        <SearchResultsContainer data-testid="search-all-results-container-people">
          {peopleCards.length > 0 ? (
            peopleCards
          ) : (
            <EmptyStateText onClearFiltersClicked={onClearFiltersClicked} entity="people" />
          )}
        </SearchResultsContainer>
        <ShowMoreResultsButton excessResultCount={excessPeople} screenType={ScreenType.SEARCH_PEOPLE} />
      </Stack>

      <Stack space="space.250" alignInline="start">
        <Heading size="small">
          <FormattedMessage
            id="townsquare.web.search-all-screen.teams-heading"
            description="Heading for the teams section of the search all screen"
            defaultMessage="Teams"
          />
        </Heading>
        <SearchResultsContainer data-testid="search-all-results-container-teams">
          {teamCards.length > 0 ? (
            teamCards
          ) : (
            <EmptyStateText onClearFiltersClicked={onClearFiltersClicked} entity="teams" />
          )}
        </SearchResultsContainer>
        <ShowMoreResultsButton excessResultCount={excessTeams} screenType={ScreenType.SEARCH_TEAMS} />
      </Stack>
    </>
  );
};
