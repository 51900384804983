/**
 * @generated SignedSource<<bc03aa4daebd138428bc688914ae3dbb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type deleteRiskInput = {
  clientMutationId?: string | null;
  id: string;
};
export type DeleteRiskMutation$variables = {
  connections: ReadonlyArray<string>;
  input: deleteRiskInput;
};
export type DeleteRiskMutation$data = {
  readonly deleteRisk: {
    readonly risk: {
      readonly id: string;
    } | null;
  } | null;
};
export type DeleteRiskMutation = {
  response: DeleteRiskMutation$data;
  variables: DeleteRiskMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "DeleteRiskMutation",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "deleteRiskPayload",
        "kind": "LinkedField",
        "name": "deleteRisk",
        "plural": false,
        "selections": [
          {
            "concreteType": "Risk",
            "kind": "LinkedField",
            "name": "risk",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteRiskMutation",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "deleteRiskPayload",
        "kind": "LinkedField",
        "name": "deleteRisk",
        "plural": false,
        "selections": [
          {
            "concreteType": "Risk",
            "kind": "LinkedField",
            "name": "risk",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5ef59c3793704bb468d54e12b9773754",
    "id": null,
    "metadata": {},
    "name": "DeleteRiskMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteRiskMutation(\n  $input: deleteRiskInput!\n) {\n  deleteRisk(input: $input) {\n    risk {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b183c5a3bd87d3851685e71286e9239f";

export default node;
