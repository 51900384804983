import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorMoreIcon from '@atlaskit/icon/glyph/editor/more';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { ConnectionHandler, useRelayEnvironment } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useFeatureGate } from '@townsquare/stat-sig/gate';

import { deleteDecision } from './mutations/DeleteDecisionMutation';
import { deleteLearning } from './mutations/DeleteLearningMutation';
import { deleteRisk } from './mutations/DeleteRiskMutation';
import { resolveRiskMutation } from './mutations/ResolveRiskMutation';
import { DropdownItemDelete, EditDropdownWrapper, ModalTitleWrapper } from './styles';
import { LearningEntityType, LearningType } from './util';

interface EditDropDownProps {
  learningId: string | undefined;
  learningType: LearningType;
  entityType: LearningEntityType;
  entityId?: string;
  entityState?: string;
  connectionId: string | undefined;
  expanded: boolean;
  onEdit?: () => void;
  canDelete?: boolean;
  canEdit?: boolean;
}

interface DeleteVerificationModalProps {
  onDeleteHighlight: () => void;
  closeModal: () => void;
  learningType: LearningType;
  entityType: LearningEntityType;
}

const DeleteVerificationModal = (props: DeleteVerificationModalProps) => {
  const highlightName = props.learningType.toLowerCase();
  return (
    <Modal onClose={props.closeModal} width="small">
      <ModalHeader>
        <ModalTitle>
          <ModalTitleWrapper>
            <ErrorIcon label="" primaryColor={token('color.icon.danger')} />
            <FormattedMessage
              id="townsquare.packages.highlights.delete-verification-modal-title"
              defaultMessage="Delete this {highlightName, select, learning {learning} risk {risk} other {decision}}?"
              values={{ highlightName }}
              description="Delete verification modal title"
            />
          </ModalTitleWrapper>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id="townsquare.packages.highlights.delete-verification-modal-body"
          defaultMessage="Are you sure you want to delete this? This action will delete this {highlightName, select, learning {learning} risk {risk} other {decision}} from your {entityType, select, project {project} other {goal}} and it cannot be restored."
          values={{ highlightName, entityType: props.entityType.toLowerCase() }}
          description="Delete verification modal body"
        />
      </ModalBody>
      <ModalFooter>
        <Button appearance="subtle" onClick={() => props.closeModal()}>
          <FormattedMessage
            id="townsquare.packages.highlights.delete-verification-modal-cancel-button"
            defaultMessage="Cancel"
            description="Delete verification modal cancel button"
          />
        </Button>
        {/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
        <Button className="delete-learning-button" appearance="danger" onClick={props.onDeleteHighlight} autoFocus>
          <FormattedMessage
            id="townsquare.packages.highlights.delete-verification-modal-delete-button"
            defaultMessage="Delete"
            description="Delete verification modal delete button"
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface AnalyticProps {
  screen: string;
  entityType: string;
  type: string;
  projectPhase?: string;
  goalState?: string;
}

export const EditDropDown = ({
  learningId,
  learningType,
  entityType,
  entityId,
  entityState,
  connectionId,
  expanded,
  onEdit,
  canEdit,
  canDelete,
}: EditDropDownProps) => {
  const intl = useIntl();
  const environment = useRelayEnvironment();
  const analytics = useAnalytics();
  const [routerState] = useRouter();
  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setisDeleteModalOpen(true);
  const closeDeleteModal = () => setisDeleteModalOpen(false);
  const enableMitigatingAndResolvingRisks = useFeatureGate('enable_mitigating_and_resolving_risks');

  const onResolveRisk = useCallback(() => {
    if (learningId && entityType === 'PROJECT') {
      resolveRiskMutation(
        environment,
        { id: learningId },
        {
          onCompleted: () => {
            void analytics.track('risk', 'resolved', {
              screen: `${routerState.route.name}Screen`,
              entityType,
              type: learningType,
              projectPhase: entityState,
            });
          },
          onError: () => {
            void analytics.operational('risk', 'resolve-failed', {
              screen: `${routerState.route.name}Screen`,
              entityType: 'project',
              type: learningType,
            });
          },
        },
      );
    }
  }, [analytics, entityState, entityType, environment, learningId, learningType, routerState.route.name]);

  const onDeleteHighlight = useCallback(() => {
    if (learningId && connectionId) {
      const analyticProps: AnalyticProps = {
        screen: `${routerState.route.name}Screen`,
        entityType,
        type: learningType,
      };
      if (entityType === 'PROJECT') {
        analyticProps.projectPhase = entityState;
      } else {
        analyticProps.goalState = entityState;
      }
      const highlightName = learningType.toLowerCase();
      const connectionIds = [
        ...(entityId
          ? [
              connectionId,
              ConnectionHandler.getConnectionID(
                entityId,
                entityType === 'PROJECT' ? `ProjectTabs__${highlightName}sCount` : `GoalTabs__${highlightName}sCount`,
              ),
              ConnectionHandler.getConnectionID(
                entityId,
                entityType === 'PROJECT' ? `LearningsList__${highlightName}s` : `GoalHighlightsList__${highlightName}s`,
                {
                  sort: ['CREATION_DATE_ASC'],
                  type: learningType.toUpperCase(),
                },
              ),
            ]
          : [connectionId]),
      ];
      const events = {
        onCompleted: () => {
          void analytics.track(highlightName, 'deleted', analyticProps);
        },
        onError: () => {
          void analytics.operational(highlightName, 'delete-failed', analyticProps);
        },
      };
      if (entityType === 'PROJECT') {
        deleteLearning(environment, { id: learningId }, connectionIds, events);
      } else if (learningType === 'RISK') {
        deleteRisk(environment, { id: learningId }, connectionIds, events);
      } else if (learningType === 'DECISION') {
        deleteDecision(environment, { id: learningId }, connectionIds, events);
      } else {
        deleteLearning(environment, { id: learningId }, connectionIds, events);
      }
      closeDeleteModal();
    }
  }, [
    analytics,
    connectionId,
    environment,
    learningId,
    learningType,
    entityId,
    entityState,
    entityType,
    routerState.route.name,
  ]);

  const highlightName = learningType.toLowerCase();

  return (
    <EditDropdownWrapper
      data-testid={`${highlightName}-dropdown-button-${learningId}`}
      expanded={expanded}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <DropdownMenu
        trigger={({ triggerRef, ...triggerProps }) => (
          <Button
            ref={triggerRef}
            {...triggerProps}
            spacing="none"
            iconBefore={
              <EditorMoreIcon
                label={intl.formatMessage({
                  id: 'townsquare.packages.highlights.edit-dropdown-more-icon-label',
                  defaultMessage: 'More',
                  description: 'Edit dropdown more icon label',
                })}
              />
            }
          />
        )}
      >
        <DropdownItemGroup>
          {canEdit && (
            <DropdownItem
              onClick={() => {
                onEdit?.();
              }}
              testId={`${highlightName}-edit-button-${learningId}`}
            >
              <FormattedMessage
                id="townsquare.packages.highlights.edit-dropdown-edit-button"
                defaultMessage="Edit"
                description="Edit dropdown edit button"
              />
            </DropdownItem>
          )}
          {canDelete && (
            <DropdownItemDelete data-testid={`delete-${highlightName}-item-${learningId}`}>
              <DropdownItem onClick={() => openDeleteModal()}>
                <FormattedMessage
                  id="townsquare.packages.learnings.edit-dropdown-delete-button"
                  defaultMessage="Delete"
                  description="Edit dropdown delete button"
                />
              </DropdownItem>
            </DropdownItemDelete>
          )}
          {enableMitigatingAndResolvingRisks && learningType === 'RISK' && (
            <DropdownItem onClick={onResolveRisk}>
              <FormattedMessage
                id="townsquare.packages.learnings.edit-dropdown-resolve-risk-button"
                defaultMessage="Resolve risk"
                description="Edit dropdown resolve button"
              />
            </DropdownItem>
          )}
        </DropdownItemGroup>
      </DropdownMenu>
      <ModalTransition>
        {isDeleteModalOpen && (
          <DeleteVerificationModal
            onDeleteHighlight={onDeleteHighlight}
            closeModal={closeDeleteModal}
            learningType={learningType}
            entityType={entityType}
          />
        )}
      </ModalTransition>
    </EditDropdownWrapper>
  );
};
