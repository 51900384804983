import Icon from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';
import React from 'react';

import {
  getStatusColor,
  GoalPhase,
  goalScoreToStatusEntity,
  scoreMap,
  type ScoreStatus,
} from '@townsquare/goal-status-lozenge';

import * as styled from './styles';
import { GoalState, Props } from './types';

const glyph = `<svg width="18" height="18" viewBox="0 0 18 18"><g fill="currentColor" fill-rule="evenodd"><path d="M16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9ZM13 9C13 11.2091 11.2091 13 9 13C6.79086 13 5 11.2091 5 9C5 6.79086 6.79086 5 9 5C11.2091 5 13 6.79086 13 9Z" fill-rule="evenodd"/></g></svg>`;

const getColorFromState = (state: GoalState) => {
  // Get color based on score if goal is completed.
  // TODO: This should be moved to backend.
  const value =
    state.value === 'done' || !state.score
      ? state.value
      : state.score < 0.4
      ? 'off_track'
      : state.score < 0.7
      ? 'at_risk'
      : 'on_track';

  return scoreMap[value as ScoreStatus] ?? scoreMap.default;
};

export const BaseGoalIcon = ({ score, phaseName, backgroundSize, state, includeBackground, ...iconProps }: Props) => {
  const stateScore = state && state.score;
  const statePhaseName = state && state.value;

  const iconStatusColor = state
    ? getColorFromState(state)
    : getStatusColor(
        goalScoreToStatusEntity(
          stateScore ? stateScore : score,
          statePhaseName ? (statePhaseName as GoalPhase) : (phaseName as GoalPhase),
        ).name,
      );

  if (!includeBackground) {
    return <Icon dangerouslySetGlyph={glyph} {...iconProps} />;
  }

  return (
    <styled.Icon size={backgroundSize ?? 'medium'} color={iconStatusColor.icon}>
      <Icon
        dangerouslySetGlyph={glyph}
        {...iconProps}
        primaryColor={iconStatusColor.iconForeground ?? token('color.icon.inverse')}
      />
    </styled.Icon>
  );
};
