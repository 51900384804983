import { minusMonths, plusMonths, tzWorkspaceTimezoneDate } from '@townsquare/date-utils';

export const MONTHS_IN_TIMELINE_VIEW = 18;
export const MINUS_MONTHS_AT_START_OF_VIEW = 4;

export const startDateMax = plusMonths(
  minusMonths(tzWorkspaceTimezoneDate(), MINUS_MONTHS_AT_START_OF_VIEW),
  MONTHS_IN_TIMELINE_VIEW,
)
  .toISOString()
  .split('T')[0];
export const targetDateMin = minusMonths(tzWorkspaceTimezoneDate(), MINUS_MONTHS_AT_START_OF_VIEW)
  .toISOString()
  .split('T')[0];
