/**
 * @generated SignedSource<<69749a35a862996a3925908eedeb2c18>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DirectoryTeamSortEnum = "NAME_ASC" | "NAME_DESC";
export type SearchAllQuery$variables = {
  after?: string | null;
  cloudId: string;
  first?: number | null;
  isSearchAllScreen: boolean;
  organisationId: string;
  sort?: ReadonlyArray<DirectoryTeamSortEnum | null> | null;
  teamQuery: string;
  userQuery: string;
};
export type SearchAllQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SearchAllScreen_data">;
};
export type SearchAllQuery = {
  response: SearchAllQuery$data;
  variables: SearchAllQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": 12,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSearchAllScreen"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamQuery"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userQuery"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "organisationId",
  "variableName": "organisationId"
},
v5 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "SearchAllQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "isSearchAllScreen",
            "variableName": "isSearchAllScreen"
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "teamQuery",
            "variableName": "teamQuery"
          },
          {
            "kind": "Variable",
            "name": "userQuery",
            "variableName": "userQuery"
          }
        ],
        "kind": "FragmentSpread",
        "name": "SearchAllScreen_data"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchAllQuery",
    "selections": [
      {
        "condition": "isSearchAllScreen",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "q",
                "variableName": "userQuery"
              }
            ],
            "concreteType": "CpusSearchUserConnection",
            "kind": "LinkedField",
            "name": "peopleTql",
            "plural": false,
            "selections": [
              {
                "concreteType": "CpusSearchUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "CpusSearchUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "account_id"
                      },
                      (v6/*: any*/),
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "size",
                            "value": "MEDIUM"
                          }
                        ],
                        "concreteType": "HighResolutionProfilePicture",
                        "kind": "LinkedField",
                        "name": "highResolutionProfilePicture",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "url"
                          }
                        ],
                        "storageKey": "highResolutionProfilePicture(size:\"MEDIUM\")"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "job_title"
                      },
                      {
                        "concreteType": "ReportCounts",
                        "kind": "LinkedField",
                        "name": "reportCounts",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "directReports"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "location"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "zoneinfo"
                      },
                      {
                        "concreteType": "TeamConnection",
                        "kind": "LinkedField",
                        "name": "teams",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "TeamEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "Team",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "teamId"
                                  },
                                  (v7/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "alias": "searchTeams",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "q",
                "variableName": "teamQuery"
              },
              (v5/*: any*/)
            ],
            "concreteType": "TeamSearchResponseConnection",
            "kind": "LinkedField",
            "name": "teamsTql",
            "plural": false,
            "selections": [
              {
                "concreteType": "TeamSearchResponseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "TeamSearchResponse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "description"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "displayName"
                      },
                      {
                        "concreteType": "TeamMemberConnection",
                        "kind": "LinkedField",
                        "name": "members",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "count"
                          },
                          {
                            "concreteType": "TeamMemberEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "TeamMember",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "UserPII",
                                    "kind": "LinkedField",
                                    "name": "pii",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "picture"
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "aaid"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "8011ecdcdb78ab5df350b9f7b76006cb",
    "id": null,
    "metadata": {},
    "name": "SearchAllQuery",
    "operationKind": "query",
    "text": "query SearchAllQuery(\n  $after: String\n  $cloudId: String!\n  $first: Int = 12\n  $isSearchAllScreen: Boolean!\n  $organisationId: String!\n  $sort: [DirectoryTeamSortEnum]\n  $teamQuery: String!\n  $userQuery: String!\n) {\n  ...SearchAllScreen_data_1dzIUH\n}\n\nfragment CardTeamsSection on CpusSearchUser {\n  teams {\n    edges {\n      node {\n        name\n        teamId\n        id\n      }\n    }\n  }\n}\n\nfragment PersonSearchResultCard on CpusSearchUser {\n  account_id\n  name\n  highResolutionProfilePicture(size: MEDIUM) {\n    url\n  }\n  job_title\n  reportCounts {\n    directReports\n  }\n  location\n  zoneinfo\n  ...CardTeamsSection\n}\n\nfragment SearchAllScreen_data_1dzIUH on Query {\n  peopleTql(q: $userQuery, organisationId: $organisationId, cloudId: $cloudId, first: $first, after: $after) @include(if: $isSearchAllScreen) {\n    edges {\n      node {\n        account_id\n        ...PersonSearchResultCard\n      }\n    }\n  }\n  searchTeams: teamsTql(q: $teamQuery, organisationId: $organisationId, cloudId: $cloudId, sort: $sort, first: $first, after: $after) @include(if: $isSearchAllScreen) {\n    edges {\n      node {\n        id\n        ...TeamSearchResultCard\n      }\n    }\n  }\n}\n\nfragment TeamSearchResultCard on TeamSearchResponse {\n  id\n  description\n  displayName\n  members {\n    count\n    edges {\n      node {\n        pii {\n          name\n          picture\n        }\n        aaid\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "22df083f9d15d5887373517be4e4aedd";

export default node;
