/**
 * @generated SignedSource<<ec3ea8d783c8e65930278aaccf43bddc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BrowsePeopleFieldSection$data = {
  readonly results: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"IllustrationCard" | "LocationCard">;
  } | null> | null;
  readonly " $fragmentType": "BrowsePeopleFieldSection";
};
export type BrowsePeopleFieldSection$key = {
  readonly " $data"?: BrowsePeopleFieldSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrowsePeopleFieldSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BrowsePeopleFieldSection",
  "selections": [
    {
      "concreteType": "PeopleFieldAggregate",
      "kind": "LinkedField",
      "name": "results",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "LocationCard"
        },
        {
          "kind": "FragmentSpread",
          "name": "IllustrationCard"
        }
      ]
    }
  ],
  "type": "PeopleFieldAggregateResponse"
};

(node as any).hash = "277ca47fd195759778d909cb03973b71";

export default node;
