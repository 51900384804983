import SearchIcon from '@atlaskit/icon/glyph/search';
import { OptionType, SelectProps } from '@atlaskit/select';
import React, { Suspense, useCallback, useEffect, useState } from 'react';

import { TextFilter, type TextFilterProps } from '@townsquare/text-filter';
import type { Resolvers } from '@townsquare/tql/types';

import { SearchPreview } from './SearchPreview';
import { LoadingOverlay } from './SearchPreview/LoadingOverlay';
import { Recent } from './SearchPreview/Recent';
import { ResultNavigationOptions } from './SearchPreview/types';
import { SearchBarWrapper } from './styles';

type TextFilterWithPreviewProps = {
  onTextChange: (q: string | undefined) => void;
  tqlForPreview?: string;
  searchText?: string;
  textFieldPlaceholder: string;
  resolvers: Resolvers;
} & SelectProps<OptionType, false> &
  Pick<TextFilterProps, 'innerRef' | 'isDisabled'>;

export const TextFilterWithPreview = ({
  onTextChange,
  innerRef,
  isDisabled,
  textFieldPlaceholder,
  tqlForPreview,
  searchText,
  showRecent,
  resolvers,
}: TextFilterWithPreviewProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [shouldNavigateToResult, setShouldNavigateToResult] = useState<ResultNavigationOptions>(
    ResultNavigationOptions.NOT_NAVIGATING,
  );
  const [canOpenPreview, setCanOpenPreview] = useState(false);

  const closeSearchPreview = useCallback(() => setCanOpenPreview(false), [setCanOpenPreview]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Escape':
          setCanOpenPreview(false);
          return;
        case 'ArrowDown':
          e.preventDefault();
          e.stopPropagation();
          setSelectedIndex(currentIndex => currentIndex + 1);
          break;
        case 'ArrowUp':
          e.preventDefault();
          e.stopPropagation();
          setSelectedIndex(currentIndex => currentIndex - 1);
          break;
        case 'Enter':
          e.preventDefault();
          e.stopPropagation();
          setShouldNavigateToResult(
            e.metaKey ? ResultNavigationOptions.NAVIGATE_NEW_TAB : ResultNavigationOptions.NAVIGATE_SAME_TAB,
          );
          break;
        default:
          if (!canOpenPreview) {
            setCanOpenPreview(true);
          }

          break;
      }
    },
    [canOpenPreview],
  );

  useEffect(() => {
    const onClickOutsidePreview = () => {
      if (shouldNavigateToResult === ResultNavigationOptions.NOT_NAVIGATING) {
        setCanOpenPreview(false);
      }
    };
    window.addEventListener('click', onClickOutsidePreview);

    return () => {
      window.removeEventListener('click', onClickOutsidePreview);
    };
  }, [shouldNavigateToResult]);

  return (
    <SearchBarWrapper>
      <TextFilter
        elemBeforeInput={<SearchIcon label={textFieldPlaceholder} />}
        isDisabled={isDisabled}
        placeholder={textFieldPlaceholder}
        onTextChange={onTextChange}
        innerRef={innerRef}
        onKeyDown={onKeyDown}
        trimSpaces={true}
        onClick={e => {
          e.stopPropagation();
          setCanOpenPreview(true);
        }}
      />
      {canOpenPreview &&
        (tqlForPreview ? (
          <Suspense fallback={<LoadingOverlay />}>
            <SearchPreview
              searchPreviewTql={tqlForPreview ?? ''}
              searchText={searchText}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              shouldNavigateToResult={shouldNavigateToResult}
              setShouldNavigateToResult={setShouldNavigateToResult}
              closeSearchPreview={closeSearchPreview}
            />
          </Suspense>
        ) : showRecent ? (
          <Suspense fallback={<LoadingOverlay />}>
            <Recent
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              shouldNavigateToResult={shouldNavigateToResult}
              setShouldNavigateToResult={setShouldNavigateToResult}
              closeSearchPreview={closeSearchPreview}
              resolvers={resolvers}
            />
          </Suspense>
        ) : null)}
    </SearchBarWrapper>
  );
};
