import Tooltip, { TooltipProps } from '@atlaskit/tooltip';
import React from 'react';

type Props = {
  content?: string | null;
  children?: React.ReactNode;
  position?: TooltipProps['position'];
  mousePosition?: TooltipProps['mousePosition'];
};

export const EllipsisTooltip = ({ content, children, position = 'mouse', mousePosition = 'bottom' }: Props) => {
  return (
    <Tooltip delay={500} content={content} position={position} mousePosition={mousePosition}>
      {children}
    </Tooltip>
  );
};
