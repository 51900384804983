import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import ProductOverviewQuery, {
  ProductOverviewQuery as ProductOverviewQueryType,
} from '../New/__generated__/ProductOverviewQuery.graphql';

export const projectDialogProjectOverviewResource = createRelayResource<ProductOverviewQueryType>({
  getQuery(routerContext, resourceContext) {
    const context = resourceContext as ResourceContext;
    return {
      query: ProductOverviewQuery,
      variables: {
        workspaceContext: {
          contextType: 'WorkspaceIdentifier',
          identifier: context.workspace.keyPrefix,
        },
      },
    };
  },
});
