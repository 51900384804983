import Loadable from 'react-loadable';

import {
  GOAL_FEED_PAGE,
  GOAL_FEED_PATH,
  PROJECT_FEED_PAGE,
  PROJECT_FEED_PATH,
  SAVED_VIEW_FEED_PAGE,
  SAVED_VIEW_FEED_PATH_PREFIX,
  TAG_FEED_PAGE,
  TAG_FEED_PATH_PREFIX,
  TEAM_FEED_PAGE,
  TEAM_FEED_PATH_PREFIX,
  RECENTS_PAGE,
  RECENTS_PATH,
} from '@townsquare/config/routes';
import { createGlobalRoute, createSiteRoute } from '@townsquare/router/create-route';

import { goalUpdatesAsideResource } from './resources/goalUpdatesAsideResource';
import { goalUpdatesResource } from './resources/goalUpdatesResource';
import { projectUpdatesAsideResource } from './resources/projectUpdatesAsideResource';
import { projectUpdatesResource } from './resources/projectUpdatesResource';
import { savedViewUpdatesAsideResource } from './resources/savedViewUpdatesAsideResource';
import { savedViewUpdatesResource } from './resources/savedViewUpdatesResource';
import { statusUpdatesNavigationResource } from './resources/statusUpdatesNavigationResource';
import { stayInLoopResource } from './resources/stayInLoopResource';
import { tagUUIDRedirectResource } from './resources/tag-uuid-redirect';
import { tagUpdatesAsideResource } from './resources/tagUpdatesAsideResource';
import { tagUpdatesResource } from './resources/tagUpdatesResource';
import { teamUpdatesAsideResource } from './resources/teamUpdatesAsideResource';
import { teamUpdatesResource } from './resources/teamUpdatesResource';

const loadableStatusUpdatesNavigation = Loadable({
  loader: () => import(/* webpackChunkName: "status-updates-navigation" */ './StatusUpdatesNavigation/index'),
  loading: () => null,
});

export const goalUpdateViewRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "goalUpdate-view" */ './GoalUpdatesView/index'),
    loading: () => null,
  }),
  aside: Loadable({
    loader: () => import(/* webpackChunkName: "goalUpdateAside-view" */ './GoalUpdatesView/GoalUpdatesAsideView'),
    loading: () => null,
  }),
  navigation: loadableStatusUpdatesNavigation,
  exact: true,
  name: GOAL_FEED_PAGE,
  path: GOAL_FEED_PATH,
  resources: [goalUpdatesResource, goalUpdatesAsideResource, stayInLoopResource, statusUpdatesNavigationResource],
  layout: 'home',
  subproduct: 'townsquare-goals',
});

export const projectUpdateViewRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "projectUpdate-view" */ './ProjectUpdatesView/index'),
    loading: () => null,
  }),
  aside: Loadable({
    loader: () =>
      import(/* webpackChunkName: "projectUpdateAside-view" */ './ProjectUpdatesView/ProjectUpdatesAsideView'),
    loading: () => null,
  }),
  navigation: loadableStatusUpdatesNavigation,
  exact: true,
  name: PROJECT_FEED_PAGE,
  path: PROJECT_FEED_PATH,
  resources: [projectUpdatesResource, projectUpdatesAsideResource, stayInLoopResource, statusUpdatesNavigationResource],
  layout: 'home',
  subproduct: 'townsquare-projects',
});

export const savedViewsUpdatesRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "savedViewsUpdates-view" */ './SavedViewUpdates/index'),
    loading: () => null,
  }),
  aside: Loadable({
    loader: () =>
      import(/* webpackChunkName: "savedViewsUpdates-aside-view" */ './SavedViewUpdates/SavedViewUpdatesAside'),
    loading: () => null,
  }),
  navigation: loadableStatusUpdatesNavigation,
  exact: true,
  name: SAVED_VIEW_FEED_PAGE,
  path: `${SAVED_VIEW_FEED_PATH_PREFIX}/:uuid`,
  resources: [savedViewUpdatesResource, savedViewUpdatesAsideResource, statusUpdatesNavigationResource],
  layout: 'home',
  subproduct: 'townsquare-tags',
});

export const tagUUIDRedirectRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "tagUUID-redirect" */ './TagUpdatesView/TagUUIDRedirect'),
    loading: () => null,
  }),
  exact: true,
  name: TAG_FEED_PAGE,
  path: `${TAG_FEED_PATH_PREFIX}/:id`,
  resources: [tagUUIDRedirectResource, tagUpdatesResource],
  layout: 'home',
  subproduct: 'townsquare-tags',
});

export const tagFeedViewRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "TagUpdatesView" */ './TagUpdatesView'),
    loading: () => null,
  }),
  aside: Loadable({
    loader: () => import(/* webpackChunkName: "TagUpdatesAsideView" */ './TagUpdatesView/TagUpdatesAsideView'),
    loading: () => null,
  }),
  navigation: loadableStatusUpdatesNavigation,
  exact: true,
  name: TAG_FEED_PAGE,
  path: `${TAG_FEED_PATH_PREFIX}/:id`,
  resources: [tagUpdatesResource, tagUpdatesAsideResource, statusUpdatesNavigationResource],
  layout: 'home',
  subproduct: 'townsquare-tags',
});

export const recentsViewRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "RecentsView" */ './RecentsView'),
    loading: () => null,
  }),
  exact: true,
  name: RECENTS_PAGE,
  path: RECENTS_PATH,
  resources: [],
  layout: 'home',
  subproduct: 'townsquare-home',
});

export const teamUpdatesViewRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "teamUpdates-view" */ './TeamUpdatesView/index'),
    loading: () => null,
  }),
  aside: Loadable({
    loader: () => import(/* webpackChunkName: "teamUpdates-aside-view" */ './TeamUpdatesView/TeamUpdatesAsideView'),
    loading: () => null,
  }),
  navigation: loadableStatusUpdatesNavigation,
  exact: true,
  name: TEAM_FEED_PAGE,
  path: `${TEAM_FEED_PATH_PREFIX}/:id`,
  resources: [teamUpdatesResource, teamUpdatesAsideResource, statusUpdatesNavigationResource],
  layout: 'home',
  subproduct: 'townsquare-people-team-directory',
});
