/**
 * @generated SignedSource<<64340772d1052736068e884ba1fe990f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type OnboardingViewQuery$variables = {};
export type OnboardingViewQuery$data = {
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"OnboardingMessagePrompt">;
  } | null;
};
export type OnboardingViewQuery = {
  response: OnboardingViewQuery$data;
  variables: OnboardingViewQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "OnboardingViewQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OnboardingMessagePrompt"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OnboardingViewQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "keys": [
                    "PRODUCT_OVERVIEW"
                  ]
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              }
            ],
            "concreteType": "OnboardingConnection",
            "kind": "LinkedField",
            "name": "onboarding",
            "plural": false,
            "selections": [
              {
                "concreteType": "OnboardingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  }
                ]
              }
            ],
            "storageKey": "onboarding(filter:{\"keys\":[\"PRODUCT_OVERVIEW\"]},first:1)"
          },
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a610834934e114c6c6a129f46f1e5542",
    "id": null,
    "metadata": {},
    "name": "OnboardingViewQuery",
    "operationKind": "query",
    "text": "query OnboardingViewQuery {\n  currentUser {\n    ...OnboardingMessagePrompt\n    id\n  }\n}\n\nfragment OnboardingMessagePrompt on User {\n  onboarding(first: 1, filter: {keys: [PRODUCT_OVERVIEW]}) {\n    edges {\n      __typename\n    }\n  }\n}\n"
  }
};

(node as any).hash = "b50dd0962ea8d4326ebfc0c33025da89";

export default node;
