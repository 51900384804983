/**
 * @generated SignedSource<<b744f0437907f368db9c70357486d74d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GoalScoringMode = "OKR" | "SIMPLE";
export type WorkspaceType = "GLOBAL_EXPERIENCE" | "PRODUCT";
export type ProfileAndWorkspaceSwitcherButtonQuery$variables = {};
export type ProfileAndWorkspaceSwitcherButtonQuery$data = {
  readonly allWorkspaces: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cloudId: string;
        readonly goalScoringMode: GoalScoringMode;
        readonly id: string;
        readonly keyPrefix: string;
        readonly name: string;
        readonly organisationId: string;
        readonly timezone: string;
        readonly type: WorkspaceType;
        readonly uuid: string;
      } | null;
    } | null> | null;
  } | null;
};
export type ProfileAndWorkspaceSwitcherButtonQuery = {
  response: ProfileAndWorkspaceSwitcherButtonQuery$data;
  variables: ProfileAndWorkspaceSwitcherButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "concreteType": "WorkspaceConnection",
    "kind": "LinkedField",
    "name": "allWorkspaces",
    "plural": false,
    "selections": [
      {
        "concreteType": "WorkspaceEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "name"
              },
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "kind": "ScalarField",
                "name": "uuid"
              },
              {
                "kind": "ScalarField",
                "name": "keyPrefix"
              },
              {
                "kind": "ScalarField",
                "name": "timezone"
              },
              {
                "kind": "ScalarField",
                "name": "cloudId"
              },
              {
                "kind": "ScalarField",
                "name": "organisationId"
              },
              {
                "kind": "ScalarField",
                "name": "goalScoringMode"
              },
              {
                "kind": "ScalarField",
                "name": "type"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "ProfileAndWorkspaceSwitcherButtonQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileAndWorkspaceSwitcherButtonQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "52a937db5c0ad6daec13a4ea154cd348",
    "id": null,
    "metadata": {},
    "name": "ProfileAndWorkspaceSwitcherButtonQuery",
    "operationKind": "query",
    "text": "query ProfileAndWorkspaceSwitcherButtonQuery {\n  allWorkspaces {\n    edges {\n      node {\n        name\n        id\n        uuid\n        keyPrefix\n        timezone\n        cloudId\n        organisationId\n        goalScoringMode\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d79c8bc1baf8a8f6350cb1a4c0198192";

export default node;
