import { Inline, xcss } from '@atlaskit/primitives';
import React, { PropsWithChildren } from 'react';

import { ARCHIVED, useSelectedView } from '@townsquare/directory-header/hooks';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { ResetView } from './ResetView';
import { SaveViewAction } from './SaveViewAction';
import { ActionListContainer, ActionListWrapper } from './style';

interface Props {
  viewContext?: 'mobile' | 'desktop';
}

const containerStyles = xcss({
  alignSelf: 'center',
  height: '32px',
  ':empty': {
    display: 'none',
  },
});

export const DirectoryActions = ({ viewContext }: PropsWithChildren<Props>) => {
  const isViewSelected = useSelectedView();

  const isSavedViewsEnabled = !isViewSelected(ARCHIVED);

  const isMobileView = viewContext === 'mobile';

  if (useIsNavRefreshEnabled()) {
    return (
      <Inline xcss={containerStyles} space="space.100">
        {!isMobileView && <ResetView />}
        {isSavedViewsEnabled && <SaveViewAction isMobileView={isMobileView} />}
        {isMobileView && <ResetView />}
      </Inline>
    );
  }

  return (
    <ActionListWrapper isMobileView={isMobileView}>
      <ActionListContainer isMobileView={isMobileView}>
        {!isMobileView && <ResetView />}
        {isSavedViewsEnabled && <SaveViewAction isMobileView={isMobileView} />}
        {isMobileView && <ResetView />}
      </ActionListContainer>
    </ActionListWrapper>
  );
};
