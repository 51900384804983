import { captureException } from '@sentry/react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl-next';
import { useRelayEnvironment } from 'react-relay';
import { useRouter } from 'react-resource-router';
import { PayloadError, fetchQuery } from 'relay-runtime';

import { useShownColumns, shownColumnsToDirectoryViewColumns } from '@townsquare/columns';
import {
  useViewTql,
  useView,
  useTimelineDateSpan,
  useSort,
  useDirectoryEntityTypeFromRoute,
} from '@townsquare/directory-header/hooks';
import { entityTypeToDirectoryRoute } from '@townsquare/directory-header/utils';
import { useDirectoryViewStore } from '@townsquare/directory-view-store';
import { featureFlagClient } from '@townsquare/feature-flags';
import { useFlagActions } from '@townsquare/flags';
import { getGoalDirectoryTableResourceQuery } from '@townsquare/goals-directory-view';
import { DEFAULT_GOAL_SORTS } from '@townsquare/goals-directory-view/default-constants';
import { getProjectDirectoryViewResourceQuery } from '@townsquare/projects-directory-view';
import {
  DEFAULT_TABLE_PROJECT_SORTS,
  DEFAULT_TIMELINE_PROJECT_SORTS,
} from '@townsquare/projects-directory-view/default-constants';
import { useRouterActions } from '@townsquare/router/primitives';
import { getTqlExplainerResourceQuery } from '@townsquare/tql';
import { useUserStore } from '@townsquare/user-store';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { updateDirectoryView } from '../mutations/UpdateDirectoryView';

export const useEditDirectoryMutation = () => {
  const [selectedDirectoryView] = useDirectoryViewStore();
  const { addFlag } = useFlagActions();
  const environment = useRelayEnvironment();
  const [workspace] = useWorkspaceStore();
  const [user] = useUserStore();
  const [tql] = useViewTql();
  const entityType = useDirectoryEntityTypeFromRoute();
  const [shownColumns] = useShownColumns(entityType === 'PROJECT' ? 'projects' : 'goals');
  const [uiViewType] = useView();
  const [timelineDateSpan] = useTimelineDateSpan();
  const [projectSort] = useSort(uiViewType === 'list' ? DEFAULT_TABLE_PROJECT_SORTS : DEFAULT_TIMELINE_PROJECT_SORTS);
  const [goalSort] = useSort(DEFAULT_GOAL_SORTS);
  const { replaceTo } = useRouterActions();
  const [routerState] = useRouter();
  const intl = useIntl();

  const refetchResourceCachedQueries = useCallback(() => {
    // Simulate a route state in which only viewUuid is set in the querystring
    // This is the state we'll get into when redirecting the user after saving a view
    const query = { viewUuid: selectedDirectoryView.uuid || '' };
    const refetchResources = [
      getTqlExplainerResourceQuery({ ...routerState, query }, { workspace, featureFlags: featureFlagClient, user }),
      entityType === 'GOAL'
        ? getGoalDirectoryTableResourceQuery(
            { ...routerState, query },
            { workspace, featureFlags: featureFlagClient, user },
          )
        : getProjectDirectoryViewResourceQuery(
            { ...routerState, query },
            { workspace, featureFlags: featureFlagClient, user },
          ),
    ];

    // Refetch the queries to update the cache
    const fetchQueryPromises = refetchResources.map(resource => {
      return new Promise(resolve => {
        if (!resource.variables) {
          return resolve({});
        }

        fetchQuery(environment, resource.query, resource.variables).subscribe({
          complete: () => resolve({}),
          error: () => resolve({}), // resolve even on error as the query will be refetched anyway
        });
      });
    });

    // Return a promise that resolves when all queries have been refetched
    return Promise.all(fetchQueryPromises);
  }, [entityType, environment, routerState, selectedDirectoryView.uuid, user, workspace]);

  return useCallback(
    (onCompleted?: () => void) => {
      const onEditDirectoryViewError = (error: PayloadError | Error) => {
        addFlag({
          id: 'edit-directory-view-error-flag',
          type: 'error',
          shouldAutoDismiss: false,
          title: intl.formatMessage({
            id: 'townsquare.directory-view-modals.edit-directory-view-error-flag.title',
            description: 'Title of the edit directory view error flag',
            defaultMessage: "We couldn't save changes to your view",
          }),
          description: intl.formatMessage({
            id: 'townsquare.directory-view-modals.edit-directory-view-error-flag.description',
            description: 'Description of the edit directory view error flag',
            defaultMessage: 'There was an error while saving your view. Please try again.',
          }),
        });
        captureException(error);
      };

      const directoryViewUuid = selectedDirectoryView.uuid;
      if (!directoryViewUuid) {
        onEditDirectoryViewError(new Error('directoryViewUuid is null'));
        return null;
      }

      return new Promise((resolve, reject) => {
        updateDirectoryView(
          environment,
          {
            directoryViewUuid: directoryViewUuid,
            workspaceUuid: workspace.UUID,
            tql,
            uiViewType: uiViewType === 'list' ? 'list' : 'timeline',
            timelineDateSpan: timelineDateSpan,
            projectSort: entityType === 'PROJECT' ? projectSort : undefined,
            goalSort: entityType === 'GOAL' ? goalSort : undefined,
            directoryViewColumns: shownColumnsToDirectoryViewColumns(shownColumns),
          },
          {
            onCompleted: async (_result, payloadErrors) => {
              if (payloadErrors) {
                void resolve({});
                return onEditDirectoryViewError(payloadErrors[0]);
              }

              // Before redirecting, ensure related view queries are refetched to update the cache
              // This prevents the display of old data momentarily
              await refetchResourceCachedQueries();

              void resolve({});
              void replaceTo(entityTypeToDirectoryRoute(selectedDirectoryView.entityType), {
                query: { viewUuid: selectedDirectoryView.uuid ?? '' },
              });
              onCompleted?.();
              addFlag({
                id: 'edit-directory-view-success-flag',
                type: 'success',
                title: intl.formatMessage({
                  id: 'townsquare.directory-view-modals.edit-directory-view-success-flag.title',
                  description: 'Title of the edit directory view success flag',
                  defaultMessage: 'Changes saved for everyone',
                }),
              });
            },
            onError: error => {
              onEditDirectoryViewError(error);
              void reject();
            },
          },
        );
      });
    },
    [
      selectedDirectoryView.uuid,
      selectedDirectoryView.entityType,
      addFlag,
      environment,
      workspace.UUID,
      tql,
      uiViewType,
      timelineDateSpan,
      entityType,
      projectSort,
      goalSort,
      shownColumns,
      refetchResourceCachedQueries,
      replaceTo,
      intl,
    ],
  );
};
