import Loadable from 'react-loadable';

import { FULL_PAGE_NOTIFICATIONS_PAGE, FULL_PAGE_NOTIFICATIONS_PATH } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';

export const notificationsRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "notifications-view" */ './FullPageNotifications'),
    loading: () => null,
  }),
  exact: true,
  layout: 'full-screen',
  name: FULL_PAGE_NOTIFICATIONS_PAGE,
  path: FULL_PAGE_NOTIFICATIONS_PATH,
});
