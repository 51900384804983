import { Help } from '@atlaskit/atlassian-navigation';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { HeadingItem, LinkItem, MenuGroup, Section, StatelessCSSFn } from '@atlaskit/menu';
import Popup, { ContentProps } from '@atlaskit/popup';
import React, { useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';

const config = getConfig();

const HelpMenuContent: React.FC<ContentProps> = () => {
  const analytics = useAnalytics();
  const headingCssFn: StatelessCSSFn = useCallback(() => ({ textTransform: 'none' }), []);

  const handleStartTrialClick = () => {
    void analytics.ui('menuItem', 'clicked', undefined, 'wac');
  };

  const handleGetSupportClick = () => {
    void analytics.ui('menuItem', 'clicked', undefined, 'support');
  };

  return (
    <MenuGroup minWidth="300px">
      <Section>
        <HeadingItem
          // Matches other heading items format, we should remove them all together
          // eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
          cssFn={headingCssFn}
        >
          <FormattedMessage {...messages.helpLabel} />
        </HeadingItem>
        <LinkItem
          onClick={handleStartTrialClick}
          iconAfter={<ShortcutIcon size="small" label="" />}
          href={config.wacUrl}
          target="_blank"
          description={<FormattedMessage {...messages.startTrialDescription} />}
        >
          <FormattedMessage {...messages.startTrialLabel} />
        </LinkItem>
        <LinkItem
          onClick={handleGetSupportClick}
          iconAfter={<ShortcutIcon size="small" label="" />}
          href={config.supportUrl}
          target="_blank"
          description={<FormattedMessage {...messages.getSupportDescription} />}
        >
          <FormattedMessage {...messages.getSupportLabel} />
        </LinkItem>
      </Section>
    </MenuGroup>
  );
};

export const NoWorkspaceNavHelpButton = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Popup
      placement="bottom-end"
      content={HelpMenuContent}
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      testId="nav__help-menu"
      trigger={triggerProps => (
        <Help
          isSelected={isOpen}
          testId="nav__help-menu-trigger"
          onClick={() => setOpen(!isOpen)}
          tooltip={<FormattedMessage {...messages.helpLabel} />}
          {...triggerProps}
        />
      )}
    />
  );
};

const messages = defineMessages({
  getSupportDescription: {
    defaultMessage: 'Resources for users and administrators',
    description: `Generic label used for give feedback button`,
    id: 'uchi.help.get-support.description',
  },
  getSupportLabel: {
    defaultMessage: 'Get support',
    description: `Label for the support`,
    id: 'uchi.help.get-support.label',
  },
  helpLabel: {
    defaultMessage: 'Help',
    description: `Generic label used for the help section`,
    id: 'uchi.help.label',
  },
  startTrialDescription: {
    defaultMessage: 'Start a product trial',
    description: `Generic label used for give feedback button`,
    id: 'uchi.help.start-trial.description',
  },
  startTrialLabel: {
    defaultMessage: 'Atlassian.com',
    description: `Generic label used for give feedback button`,
    id: 'uchi.help.start-trial.label',
  },
});
