import React, { ReactNode } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { LoadMoreFn } from 'react-relay';
import { OperationType } from 'relay-runtime';

import { LoadingState } from '@townsquare/loading-state';
import { PaginatedPage } from '@townsquare/pagination';

import { ScrollingResultsContainer, SearchResultsContainer, SearchResultsContainerStyleProps } from './styles';

export interface ScrollingResultsScreenProps<T extends OperationType> {
  loadMore: LoadMoreFn<T>;
  hasMore?: boolean;
  isLoading: boolean;
  resultCount: number;
  resultName: 'teams' | 'people' | 'kudos';
  resultList: JSX.Element[];
  renderFallback: (fallbackProps: FallbackProps) => ReactNode;
  pageSize: number;
  loadingState?: JSX.Element;
  styleProps?: SearchResultsContainerStyleProps;
}

export const ScrollingResultsScreen = <QueryType extends OperationType>({
  loadMore,
  hasMore,
  isLoading,
  resultList,
  loadingState = <LoadingState />,
  renderFallback,
  styleProps,
  pageSize,
}: ScrollingResultsScreenProps<QueryType>) => {
  return (
    <ScrollingResultsContainer>
      <PaginatedPage<QueryType>
        loadMore={loadMore}
        hasMore={hasMore}
        isLoading={isLoading}
        renderLoader={loadingState}
        pageSize={pageSize}
        renderFallback={renderFallback}
      >
        <SearchResultsContainer data-testid="staff-dir-results-container" {...styleProps}>
          {resultList}
        </SearchResultsContainer>
      </PaginatedPage>
    </ScrollingResultsContainer>
  );
};
