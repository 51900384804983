import AvatarGroup, { AvatarProps } from '@atlaskit/avatar-group';
import { ReactionConsumer } from '@atlaskit/reactions';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { useReactionStore } from '@townsquare/reactions';

import AnimateOnHover from './AnimateOnHover';
import BlurryBackground from './BlurryBackground';
import * as Styled from './KudosContent.styled';
import KudosMessage from './KudosMessage';
import { KudosReactions } from './KudosReactions';
import { KudosContent$data, KudosContent$key } from './__generated__/KudosContent.graphql';
import { KudosCardSize } from './types';

type AvatarPropsWithId = AvatarProps & { id: string };
interface KudosContentProps {
  data: KudosContent$key;
  kudosPerPage: number;
  showAllDescriptionRows?: boolean;
  cardSize?: KudosCardSize;
  centerAlign?: boolean;
}

const TeamOrUserProfileItem = (props: { kudos: KudosContent$data }) => {
  const kudos = props.kudos;

  const firstRecipient = (kudos?.recipients?.edges && kudos?.recipients?.edges[0]?.node) || undefined;
  const team = firstRecipient?.team;

  const giver: AvatarPropsWithId = {
    id: kudos.giver?.pii?.accountId ?? '',
    name: kudos.giver?.pii?.name ?? '',
    src: kudos.giver?.pii?.picture ?? '',
  };

  const receiver: AvatarPropsWithId =
    kudos.type === 'TEAM' && team
      ? {
          id: team.teamId ?? '',
          name: team.name ?? '',
          src: team.avatarUrl ?? '',
        }
      : {
          id: firstRecipient?.recipient?.pii?.accountId ?? '',
          name: firstRecipient?.recipient?.pii?.name ?? '',
          src: firstRecipient?.recipient?.pii?.picture ?? '',
        };

  return (
    <Styled.Participants>
      <AvatarGroup size="small" data={[giver, receiver]} appearance="stack" />
      <Styled.Names>
        <Styled.Name>{receiver.name}</Styled.Name>
        <Styled.Name>
          <Styled.From>
            <FormattedMessage
              id="townsquare.packages.kudos-on-feed.kudos-from"
              defaultMessage="from"
              description="Who gave the kudos - eg. from Bob to Alice"
            />
          </Styled.From>
          {giver.name}
        </Styled.Name>
      </Styled.Names>
    </Styled.Participants>
  );
};

const KudosContent = (props: KudosContentProps) => {
  const kudos = useFragment(
    graphql`
      fragment KudosContent on Kudos {
        uuid
        ari
        description
        imageUrl
        stillImageUrl
        type
        bgTheme
        giver {
          pii {
            name
            picture
            accountId
          }
        }
        recipients(first: 1) {
          edges {
            node {
              recipient {
                pii {
                  accountId
                  name
                  picture
                }
              }
              team {
                name
                teamId
                avatarUrl
              }
            }
          }
        }
      }
    `,
    props.data,
  );

  const analytics = useAnalytics();
  const reactionStore = useReactionStore();
  const pathname = `/people/kudos/${kudos.uuid}`;

  const handleKudosClick = () => {
    void analytics.ui('kudosOnFeed', 'clicked');
  };

  return (
    <Styled.KudosContent
      backgroundTheme={kudos.bgTheme || 'blue'}
      numberOfKudosCards={props.kudosPerPage}
      cardSize={props.cardSize || 'standard'}
      centreAlign={props.centerAlign || false}
    >
      <Styled.KudosContentLink to={pathname} onClick={() => handleKudosClick()}>
        <TeamOrUserProfileItem kudos={kudos} />

        <AnimateOnHover
          still={<img src={kudos.stillImageUrl || undefined} />}
          animated={<BlurryBackground backgroundUrl={kudos.imageUrl} imageUrl={kudos.imageUrl} size={props.cardSize} />}
        />

        <Styled.KudosContentDescription showAllDescriptionRows={props.showAllDescriptionRows}>
          {kudos.description && <KudosMessage description={kudos.description} />}
        </Styled.KudosContentDescription>
      </Styled.KudosContentLink>

      <ReactionConsumer store={reactionStore}>{() => <KudosReactions kudosAri={kudos.ari} />}</ReactionConsumer>
    </Styled.KudosContent>
  );
};

export default KudosContent;
