import { captureException } from '@sentry/react';
import type { ExecuteFunction, GraphQLResponseWithData, PayloadError, RequiredFieldLogger } from 'relay-runtime';
import { Environment, Network, Observable, RecordSource, Store } from 'relay-runtime';

import { getConfig } from '@townsquare/config';
import { PRODUCT_DISPLAY_NAME } from '@townsquare/config/constants';
import {
  BadRequestError,
  ForbiddenError,
  GraphQLFetchError,
  InternalServerError,
  NotFound,
  RelayRequiredFieldError,
  RequestError,
  UnauthorizedError,
} from '@townsquare/error-state/classes';

import { captureMissingWorkspaceUuid } from './captureMissingWorkspaceUuid';
import { captureErrorSentry } from './relayCaptureError';
import { resolveMultiRegionUrl } from './resolve-multi-region-url';

const CONTENT_TYPE_HEADER = { 'Content-Type': 'application/json' };

export const fetchGraphQLFromUrl = (
  legacyUrl: string,
  multiRegionUrl?: string,
  customHeaders?: Record<string, string>,
) => {
  const baseUrl = resolveMultiRegionUrl(legacyUrl, multiRegionUrl);
  return ((request, variables, cacheConfig) => {
    const fetchUrl = `${baseUrl}?operationName=${request.name}`;

    const captureError = (error: RequestError, extras: Record<string, unknown> = {}) => {
      return captureErrorSentry({ fetchUrl, request, variables, error, extras });
    };

    // Abort signal comes from cacheConfig metadata when using fetchQuery as we don't have direct access to request metadata
    const abortSignal =
      (request.metadata?.abortSignal as AbortSignal | undefined | null) ??
      (cacheConfig.metadata?.abortSignal as AbortSignal | undefined | null);

    /**
     * Monitor every graphql request that comes through and emit on instances of `workspaceUuid` variable
     * that is defined, but populated with invalid value (null or empty)
     */
    captureMissingWorkspaceUuid(request, variables);

    return Observable.create(source => {
      void fetch(fetchUrl, {
        method: 'POST',
        headers: {
          ...CONTENT_TYPE_HEADER,
          ...customHeaders,
        },
        body: JSON.stringify({
          query: request.text,
          variables,
        }),
        signal: abortSignal,
      })
        .then(response => {
          if (!response.ok) {
            throw new BadRequestError(response.statusText, response.status);
          }
          return response.json();
        })
        .then(data => {
          const errors = data.errors as (PayloadError & GraphQLResponseWithData['extensions'])[] | undefined;
          if (errors) {
            const messages = errors.reduce<Record<string, unknown>>((acc, error, index) => {
              acc[`message[${index}]`] = error.message;
              return acc;
            }, {});

            captureError(new GraphQLFetchError(errors[0].message, errors[0].extensions?.statusCode), messages);

            // We only care about top level nodes returning 403s.
            // If a nested node returns a 403, we don't want to blow away the entire page.
            // The nested node should handle the error state.
            if (errors.some(error => error.extensions?.statusCode === 403 && error.path?.length === 1)) {
              source.error(new ForbiddenError(`You do not have permission to perform this action`));
              return;
            }

            if (errors.some(error => error.extensions?.statusCode === 401 && error.path?.length === 1)) {
              source.error(new UnauthorizedError(`You do not have permission to perform this action`));
              return;
            }

            if (errors.some(error => error.extensions?.statusCode === 404 && error.path?.length === 1)) {
              source.error(new NotFound());
              return;
            }

            const internalServerError = errors.find(
              error => error.extensions?.statusCode === 500 && error.path?.length === 1,
            );
            if (internalServerError) {
              source.error(new InternalServerError(internalServerError.message));
              return;
            }
          }

          source.next(data);
          source.complete();
        })
        .catch((error: BadRequestError) => {
          captureError(error);
          source.error(error);
        });
    });
  }) as ExecuteFunction;
};

export const logMissingRequiredField: RequiredFieldLogger = log => {
  captureException(new RelayRequiredFieldError(`Missing required field: ${log.fieldPath}`), scope =>
    scope
      .setExtra('kind', log.kind)
      .setExtra('fieldPath', log.fieldPath)
      .setExtra('owner', log.owner)
      .setExtra('message', log.kind === 'relay_resolver.error' ? log.error.message : undefined)
      .setLevel(log.kind === 'missing_field.log' ? 'info' : 'error'),
  );
};

const config = getConfig();
export const RelayEnvironment = new Environment({
  configName: `${PRODUCT_DISPLAY_NAME} GraphQL`,
  network: Network.create(fetchGraphQLFromUrl(config.watermelonGraphQLUrl, config.townsquareGraphQLUrl)),
  store: new Store(new RecordSource()),
  requiredFieldLogger: logMissingRequiredField,
});
