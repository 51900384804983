import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import TeamProfileEmbedQuery, {
  type TeamProfileEmbedQuery as TeamProfileEmbedQueryType,
} from '../components/Profile/TeamEmbed/__generated__/TeamProfileEmbedQuery.graphql';

export const teamProfileEmbedResource = createRelayResource<TeamProfileEmbedQueryType>({
  getQuery: (_routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const teamId = _routerContext.match.params.teamId ? decodeURIComponent(_routerContext.match.params.teamId) : '';
    const currentProjectGoalQuery = `team = ${teamId} and (phase = in_progress or phase = pending) and archived = false`;

    return {
      query: TeamProfileEmbedQuery,
      variables: {
        teamId: teamId,
        currentGoalQuery: currentProjectGoalQuery,
        currentProjectQuery: currentProjectGoalQuery,
        workspaceId: context.workspace.globalId,
        workspaceUuid: context.workspace.UUID || '',
        first: 5,
        cloudId: context.workspace.cloudId,
      },
    };
  },
});
