import React from 'react';

import { LoadingSkeleton } from '@townsquare/loading-skeleton';
import { BlankIconOrAvatar } from '@townsquare/user-avatar';

import {
  EmptySidebarSectionContainer,
  EmptySidebarSectionInnerContainer,
  SectionHeader,
  SectionWrapper,
} from './styles';

const RowSkeleton = () => (
  <EmptySidebarSectionContainer>
    <EmptySidebarSectionInnerContainer>
      <BlankIconOrAvatar size="small" isAvatar={false} />
      <LoadingSkeleton marginLeftPx={12} width="150px" height="20px" />
    </EmptySidebarSectionInnerContainer>
    <BlankIconOrAvatar size="small" isAvatar />
  </EmptySidebarSectionContainer>
);

export const SidebarSectionSkeleton = ({ rows }: { rows?: number }) => {
  return (
    <SectionWrapper>
      <SectionHeader>
        <LoadingSkeleton width="100px" height="20px" />
      </SectionHeader>
      {[...Array(rows || 1)].map((_, i) => (
        <RowSkeleton key={`row-skeleton-${i}`} />
      ))}
    </SectionWrapper>
  );
};
