import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { ellipsis } from '@townsquare/ellipsis';
import { SMALL_LAPTOP_SCREEN_RESPONSIVE_THRESHOLD } from '@townsquare/responsive-grid';

import { UserAvatarProps, Size } from '../types';

// This top/bottom margin breaks alignment when used in Checkboxes (TeamPicker PickerItem)
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UserAvatar = styled.div({
  margin: `${token('space.050')} 0`,
  display: 'flex',
  alignItems: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  a: {
    textDecoration: 'none',
  },
});

type SizeMap = {
  [size in Size]: {
    color: string;
    fontWeight: string;
    margin: string;
    padding: string;
    jobTitle: { color: string; padding: string };
    name: { maxWidth: string };
  };
};

const sizeStyle: SizeMap = {
  xsmall: {
    color: token('color.text'),
    margin: `${token('space.negative.025')} 0 0 0`,
    padding: `0 0 0 ${token('space.100')}`,
    fontWeight: token('font.weight.regular'),
    jobTitle: {
      color: token('color.text.subtlest'),
      padding: `0 0 0 ${token('space.100')}`,
    },
    name: {
      maxWidth: 'calc(100% - 8px)',
    },
  },
  small: {
    color: token('color.text'),
    margin: '0',
    padding: `0 0 0 ${token('space.150')}`,
    fontWeight: token('font.weight.medium'),
    jobTitle: {
      color: token('color.text.subtlest'),
      padding: `0 0 0 ${token('space.150')}`,
    },
    name: {
      maxWidth: 'calc(100% - 12px)',
    },
  },
  medium: {
    color: token('color.text'),
    margin: '0',
    padding: `0 0 0 ${token('space.150')}`,
    fontWeight: token('font.weight.medium'),
    jobTitle: {
      color: token('color.text.subtlest'),
      padding: `0 0 0 ${token('space.150')}`,
    },
    name: {
      maxWidth: 'calc(100% - 12px)',
    },
  },
  large: {
    color: token('color.text'),
    margin: '0',
    padding: `0 0 0 ${token('space.150')}`,
    fontWeight: token('font.weight.medium'),
    jobTitle: {
      color: token('color.text.subtlest'),
      padding: `0 0 0 ${token('space.150')}`,
    },
    name: {
      maxWidth: 'calc(100% - 12px)',
    },
  },
};

const getSizeStyle = ({ size }: { size: Size }) => sizeStyle[size];

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Name = styled.div<{ size?: Size; isBold?: boolean }>(
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  ({ size, isBold }) => ({
    maxWidth: '100%',
    font: token('font.body'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    ...(size && getSizeStyle({ size })),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    ...(isBold && { fontWeight: token('font.weight.semibold') }),
  }),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  ellipsis,
);

const getDimension = (length: number) => `width: ${length}px; height: ${length}px`;
const overrideWithBorderelessDimension = (props: Pick<UserAvatarProps, 'hideBorder' | 'size'>) => {
  if (props.hideBorder) {
    switch (props.size) {
      case 'small':
        return getDimension(24);
      case 'medium':
        return getDimension(36);
      default:
        return '';
    }
  }
  return '';
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const BorderlessAvatar = styled.div`
  ${overrideWithBorderelessDimension};
  margin-right: ${(props: Pick<UserAvatarProps, 'hideBorder' | 'size'>) => (props.hideBorder ? 0 : 2)}px;

  span {
    ${(props: Pick<UserAvatarProps, 'hideBorder' | 'size'>) => (props.hideBorder ? 'padding: 0' : '')};
  }

  > div:first-child,
  > span:first-child,
  > a:first-child {
    display: flex !important;
  }

  > div > span:first-child,
  > a > div > span:first-child {
    margin: 0 !important;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const Description = styled.div`
  font: ${token('font.body.small')};
  ${ellipsis}

  // Seems a bit dodgy? This is to ensure ellipsis works correctly
  // This container is being wrapped by an inlined span element (profile card hover) at the very top
  // and since inline element always "fit-content" width, ellipsis won't work.
  max-width: 300px;
  @media (max-width: ${SMALL_LAPTOP_SCREEN_RESPONSIVE_THRESHOLD}px) {
    max-width: 220px;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const NameAndDescription = styled.div<{ size: Size }>`
  display: flex;
  flex-direction: column;
  max-width: ${props => getSizeStyle(props).name.maxWidth};
  // Set min-width to allow div to shrink properly
  min-width: 0;

  ${Description} {
    color: ${props => getSizeStyle(props).jobTitle.color} !important;
    padding: ${props => getSizeStyle(props).jobTitle.padding};
  }

  .ellipsis-tooltip-container {
    display: flex;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InvitedLozenge = styled.div({
  position: 'relative',
  left: token('space.150'),
});
