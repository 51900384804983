/**
 * @generated SignedSource<<daeb277427615b17b30af3d8a6600e10>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SearchAllScreen_data$data = {
  readonly peopleTql?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly account_id: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"PersonSearchResultCard">;
      } | null;
    } | null> | null;
  } | null;
  readonly searchTeams?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"TeamSearchResultCard">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "SearchAllScreen_data";
};
export type SearchAllScreen_data$key = {
  readonly " $data"?: SearchAllScreen_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchAllScreen_data">;
};

import SearchAllQuery_graphql from './SearchAllQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "organisationId",
  "variableName": "organisationId"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isSearchAllScreen"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organisationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": SearchAllQuery_graphql
    }
  },
  "name": "SearchAllScreen_data",
  "selections": [
    {
      "condition": "isSearchAllScreen",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "kind": "Variable",
              "name": "q",
              "variableName": "userQuery"
            }
          ],
          "concreteType": "CpusSearchUserConnection",
          "kind": "LinkedField",
          "name": "peopleTql",
          "plural": false,
          "selections": [
            {
              "concreteType": "CpusSearchUserEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "CpusSearchUser",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "account_id"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "PersonSearchResultCard"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "alias": "searchTeams",
          "args": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "kind": "Variable",
              "name": "q",
              "variableName": "teamQuery"
            },
            {
              "kind": "Variable",
              "name": "sort",
              "variableName": "sort"
            }
          ],
          "concreteType": "TeamSearchResponseConnection",
          "kind": "LinkedField",
          "name": "teamsTql",
          "plural": false,
          "selections": [
            {
              "concreteType": "TeamSearchResponseEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "TeamSearchResponse",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "id"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "TeamSearchResultCard"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "22df083f9d15d5887373517be4e4aedd";

export default node;
