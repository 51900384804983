import { SITE_IDENTIFIER_PLACEHOLDER } from '@townsquare/config';
import { location } from '@townsquare/facade';
import { isGateEnabled } from '@townsquare/stat-sig/gate';
import { createSearchParamsFromCurrentUrl } from '@townsquare/url-utils/search-params';

const SITE_ROUTE_REGEX = new RegExp(`^/o/(?<orgIdentifier>[0-9a-zA-Z-]+)/s/(?<siteIdentifier>[a-zA-Z0-9%+-]+).*$`);

export const resolveMultiRegionUrl = (legacyUrl: string, multiRegionUrl?: string) => {
  // https://switcheroo.atlassian.com/ui/gates/266723ac-a6d8-400f-858f-8a156a065383
  const isEnabled = isGateEnabled('titan_enable_multi_region_stargate_url');
  const searchParams = Object.fromEntries(createSearchParamsFromCurrentUrl());
  const match = SITE_ROUTE_REGEX.exec(location().pathname);

  const mureEnabled = window.localStorage.getItem('multiRegionRoutingEnabled');

  if (multiRegionUrl && (isEnabled || (mureEnabled ?? Number(searchParams.mureRouting)))) {
    window.localStorage.setItem('multiRegionRoutingEnabled', 'true');
    /**
     * Get cloudId from path (if available) or search params (if available)
     *
     * Note: the piece of React code that appends `cloudId=BLA` to the URL happens AFTER the graphql requests
     * So ... by the time this piece of code is being executed, cloudId may not be available in the URL
     */
    const siteIdentifier = match?.groups?.siteIdentifier || searchParams.cloudId;

    // Other places where we can grab cloud id
    /**
     * Note: At this point, if org information is available, then we have already resolved workspace information
     * and cloud id is available in `workspaceStore`
     * Unfortunately, `workspaceStore` IS NOT available outside of React component.
     * We can probably do some sort of `workspaceHolder` singleton which is set during bootstrap and available to access
     * outside of React component. Similar to `timezoneHolder`
     */
    if (siteIdentifier) {
      return multiRegionUrl.replace(SITE_IDENTIFIER_PLACEHOLDER, encodeURI(siteIdentifier));
    } else {
      return legacyUrl;
    }
  } else {
    return legacyUrl;
  }
};
