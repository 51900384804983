import Tooltip from '@atlaskit/tooltip';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { useRelayEnvironment } from 'react-relay';

import { useInviteUserModal } from '@townsquare/invite-user-modal';
import { useModalActions } from '@townsquare/modals';
import { OnboardingItemKey, saveOnboarding } from '@townsquare/onboarding';

import meeplesPlusImg from './assets/meeples-plus.svg';
import meeplesImg from './assets/meeples.svg';
import { AddPeopleWrapper, AvatarsImage, AvatarsButtonWrapper, AddPeopleButtonStyled } from './styles';

const ANALYTICS_SUBJECT_ID = 'atlasInvitingExperimentA1';
const onboardingKey: OnboardingItemKey = 'ADD_PEOPLE_BANNER';

export const AddPeopleButton = (props: { connectionId: string; displayOnboarding: boolean }) => {
  const { openModal } = useModalActions();

  const intl = useIntl();

  const environment = useRelayEnvironment();

  const [inviteUserModal, inviteUserModalTrigger] = useInviteUserModal({
    analyticsCtx: { source: ANALYTICS_SUBJECT_ID },
  });

  const onAddPeopleButtonClick = (connectionId: string, displayOnboarding: boolean) => {
    if (displayOnboarding) {
      openModal('add-people-benefits-modal', {
        analyticsSubjectId: ANALYTICS_SUBJECT_ID,
        onConfirm: () => {
          inviteUserModalTrigger();
          saveOnboarding(environment, { onboardingKey }, [connectionId]);
        },
      });
    } else {
      inviteUserModalTrigger();
    }
  };

  return (
    <>
      <AddPeopleWrapper>
        <AvatarsImage
          src={meeplesImg}
          alt={intl.formatMessage({
            description: 'Button text for Add People button in navigation bar',
            defaultMessage: 'Add people',
            id: 'townsquare.invitation.navigation.add-people-button-text',
          })}
        />
        <AvatarsButtonWrapper>
          <Tooltip
            content={intl.formatMessage({
              description: 'Button text for Add People button in navigation bar',
              defaultMessage: 'Add people',
              id: 'townsquare.invitation.navigation.add-people-button-text',
            })}
          >
            <img
              src={meeplesPlusImg}
              alt={intl.formatMessage({
                description: 'Button text for Add People button in navigation bar',
                defaultMessage: 'Add people',
                id: 'townsquare.invitation.navigation.add-people-button-text',
              })}
              onClick={() => onAddPeopleButtonClick(props.connectionId, props.displayOnboarding)}
            />
          </Tooltip>
        </AvatarsButtonWrapper>
        <AddPeopleButtonStyled
          shouldFitContainer
          onClick={() => onAddPeopleButtonClick(props.connectionId, props.displayOnboarding)}
        >
          <FormattedMessage
            description="Button text for Add People button in navigation bar"
            defaultMessage="Add people"
            id="townsquare.invitation.navigation.add-people-button-text"
          />
        </AddPeopleButtonStyled>
      </AddPeopleWrapper>
      {inviteUserModal}
    </>
  );
};
