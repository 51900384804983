import Loadable from 'react-loadable';

export const ProjectEmbed = Loadable({
  loader: () => import(/* webpackChunkName: "ProjectEmbed" */ './components/Project/ProjectEmbed'),
  loading: () => null,
});

export const GoalEmbed = Loadable({
  loader: () => import(/* webpackChunkName: "GoalEmbed" */ './components/Goal/GoalEmbed'),
  loading: () => null,
});

export const UserProfileEmbed = Loadable({
  loader: () => import(/* webpackChunkName: "UserProfileEmbed" */ './components/Profile/UserEmbed/UserProfileEmbed'),
  loading: () => null,
});

export const TeamProfileEmbed = Loadable({
  loader: () => import(/* webpackChunkName: "TeamProfileEmbed" */ './components/Profile/TeamEmbed/TeamProfileEmbed'),
  loading: () => null,
});

export const ProjectsDirectoryEmbed = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ProjectsDirectoryEmbed" */ './components/ProjectsDirectory/ProjectsDirectoryEmbed'),
  loading: () => null,
});

export const GoalsDirectoryEmbed = Loadable({
  loader: () => import(/* webpackChunkName: "GoalsDirectoryEmbed" */ './components/GoalsDirectory/GoalsDirectoryEmbed'),
  loading: () => null,
});

export { goalEmbedResource } from './resources/goalEmbedResource';
export { goalsDirectoryEmbedResource } from './resources/goalsDirectoryEmbedResource';
export { projectEmbedResource } from './resources/projectEmbedResource';
export { projectsDirectoryEmbedResource } from './resources/projectsDirectoryEmbedResource';
export { teamProfileEmbedResource } from './resources/teamProfileResource';
export { userProfileEmbedResource } from './resources/userProfileResource';
export { labelEmbedResource } from './resources/labelResource';
export { EMBED_ROUTE_NAMES } from './util';
export {
  projectsDirectoryEmbedRoute,
  goalsDirectoryEmbedRoute,
  teamProfileEmbedRoute,
  userProfileEmbedRoute,
  labelEmbedRoute,
  goalEmbedRoute,
  projectEmbedRoute,
  createKudosEmbedRoute,
} from './routes';
