import React from 'react';

import { LoadingSkeleton } from '@townsquare/loading-skeleton';

import { LoadingCard, ProfilePicBoundingBox, UserDetails } from './styles';

export const BrowsePersonCardLoadingState = () => (
  <LoadingCard>
    <ProfilePicBoundingBox>
      <LoadingSkeleton width="100%" height="100%" />
    </ProfilePicBoundingBox>
    <UserDetails>
      <LoadingSkeleton width="100px" height="20px" marginTopPx={2} marginBottomPx={2} />
      <LoadingSkeleton width="160px" height="14px" marginTopPx={6} />
    </UserDetails>
  </LoadingCard>
);
