import { ResourceStoreContext, RouterContext, RouterDataContext } from 'react-resource-router';

import { getStandardColumns, getCustomColumns } from '@townsquare/columns/hooks/use-shown-columns';
import { resolveRawSort } from '@townsquare/directory-header/sort';
import { createRelayResource, RelayResourceConfig } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import ProjectDirectoryQuery, {
  type DirectoryViewProjectQuery as ProjectDirectoryViewQueryType,
  type ProjectSortEnum,
  type LearningSortEnum,
} from '../components/__generated__/DirectoryViewProjectQuery.graphql';
import { DEFAULT_FIRST } from '../constants';
import { DEFAULT_TABLE_PROJECT_SORTS, DEFAULT_TIMELINE_PROJECT_SORTS } from '../default-constants';
import { getDefaultTableTqlQuery, getDefaultTimelineTqlQuery, getStandardColumnRelayVariables } from '../utils';

export const getProjectDirectoryViewResourceQuery = (
  routerContext: RouterContext | RouterDataContext,
  resourceContext: ResourceStoreContext,
): RelayResourceConfig<ProjectDirectoryViewQueryType> => {
  const context = resourceContext as ResourceContext;

  const navEnabled = isNavRefreshEnabled(context);
  const isSavedView = !!routerContext.query.viewUuid;
  const isTable = Boolean(routerContext.query.view !== 'timeline');
  const isTableOrSavedView = isTable || isSavedView;
  const isTimelineOrSavedView = !isTable || isSavedView;
  const defaultTql = isTable
    ? getDefaultTableTqlQuery(context.user.accountId, navEnabled)
    : getDefaultTimelineTqlQuery(context.user.accountId, navEnabled);
  const defaultSort = isTable ? DEFAULT_TABLE_PROJECT_SORTS : DEFAULT_TIMELINE_PROJECT_SORTS;
  const defaultLearningSort: LearningSortEnum[] = ['SUMMARY_ASC'];
  const shouldUseDefaults = !routerContext.query.viewUuid;

  const selectedStandardColumns = getStandardColumns('projects', routerContext.query.columns);
  const selectedCustomColumns = getCustomColumns(routerContext.query.columns);
  const showHighlights =
    routerContext.query.showLearnings === 'true' ||
    routerContext.query.showRisks === 'true' ||
    routerContext.query.showDecisions === 'true';
  const showLatestUpdate = routerContext.query.showLatestUpdate === 'true';

  return {
    query: ProjectDirectoryQuery,
    variables: {
      tql: routerContext.query.tql || (shouldUseDefaults ? defaultTql : null),
      first: DEFAULT_FIRST,
      sort: resolveRawSort<ProjectSortEnum>(routerContext.query.sort) ?? (shouldUseDefaults ? defaultSort : null),
      workspaceUuid: context.workspace.UUID || '',
      directoryViewUuid: routerContext.query.viewUuid,
      isTableOrSavedView,
      isTimelineOrSavedView,
      ...getStandardColumnRelayVariables(selectedStandardColumns),
      includedCustomFieldUuids: selectedCustomColumns,
      learningSort: defaultLearningSort,
      // We have to manually refetch once the columns have loaded in from the view.
      // See DirectoryTable.tsx
      skipTableTql: isSavedView && routerContext.query.columns === undefined,
      showHighlights,
      showLatestUpdate,
    },
    options: {
      fetchPolicy: 'store-and-network',
    },
  };
};

export const projectDirectoryViewResource = createRelayResource<ProjectDirectoryViewQueryType>({
  getQuery: getProjectDirectoryViewResourceQuery,
});
