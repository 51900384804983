import { getRovoParams } from '@atlassian/conversation-assistant-pubsub';
import { createResource } from 'react-resource-router';

import { ResourceContext } from '@townsquare/router';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import { ROVO_EXPERIENCE_ID_HEADER, ROVO_PRODUCT_HEADER, getCloudIdForRovo } from '../utils';

import { EntitlementAPIResponse, RovoEntitlement } from './types';

export const rovoEntitlementResource = createResource<RovoEntitlement>({
  getData: async (_, resourceContext) => {
    if (!isGateEnabled('atlas_rovo_use_entitlement_check')) {
      return {
        isRovoEnabled: true,
        reason: 'feature gate disabled',
      };
    }

    const context = resourceContext as ResourceContext;

    const cloudId = getCloudIdForRovo(getRovoParams(), context.workspace.cloudId);

    const response = await fetch('/gateway/api/assist/api/v2/configuration/entitlement', {
      headers: {
        'X-Cloudid': cloudId ?? '',
        'X-Product': ROVO_PRODUCT_HEADER,
        'X-Experience-Id': ROVO_EXPERIENCE_ID_HEADER,
      },
    });

    if (!response.ok) {
      return {
        isRovoEnabled: false,
        reason: 'Failed to fetch entitlement data',
      };
    }

    const data: EntitlementAPIResponse = await response.json();

    return {
      isRovoEnabled: data.enabled,
      reason: data.reason,
    };
  },
  getKey: () => 'rovo-entitlement-resource-key',
  type: 'ROVO_ENTITLEMENT',
});
