import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { useCreateTeamModal } from '@townsquare/create-team-modal';
import { useOnMount } from '@townsquare/hooks';
import { teamProfileRoute } from '@townsquare/ptc-directory-view';
import { useRouterActions } from '@townsquare/router/primitives';

// If no results returned by main query
export const BrowseTeamsEmptyState = () => {
  const analytics = useAnalytics();
  const { pushTo } = useRouterActions();

  useOnMount(() => {
    void analytics.ui('browseTeamEmptyState', 'shown');
  });

  const [createTeamModal, createTeamModalTrigger] = useCreateTeamModal({
    analyticsCtx: { source: 'browseTeamEmptyStateCreateTeam' },
    onCreate: team => pushTo(teamProfileRoute, { params: { id: team.id } }),
  });

  const onCreateTeamClicked = () => {
    void analytics.ui('browseTeamEmptyStateCreateTeam', 'clicked');
    createTeamModalTrigger();
  };

  return (
    <Inline shouldWrap space="space.500" rowSpace="space.250" alignBlock="center" xcss={emptyStateStyles}>
      <Image
        src="/Images/StaffDirectory/BrowseTeamsEmptyStateLight.svg"
        srcDark="/Images/StaffDirectory/BrowseTeamsEmptyStateDark.svg"
      />
      <Stack space="space.200" xcss={browseTeamsEmptyStateDetailsStyles}>
        <Heading size="medium" as="h4">
          <FormattedMessage
            id="townsquare.staff-directory.browse-teams-empty-state.heading"
            description="Empty state heading for the browse teams feature"
            defaultMessage="Open up your team work"
          />
        </Heading>
        <Stack space="space.400" alignInline="start">
          <Text color="color.text.subtle">
            <FormattedMessage
              id="townsquare.staff-directory.browse-teams-empty-state.description"
              description="Empty state info message informing the user about the browse teams feature"
              defaultMessage="Capture who your team is, your projects and goals, and the best way for people to get help from your team. All
            in a profile that can be used across {jiraAtlassianProductName}, {confluenceAtlassianProductName} and other Atlassian products."
              values={{
                jiraAtlassianProductName: 'Jira',
                confluenceAtlassianProductName: 'Confluence',
              }}
            />
          </Text>
          <Button appearance="primary" onClick={onCreateTeamClicked}>
            <FormattedMessage
              id="townsquare.staff-directory.browse-teams-empty-state.create-team-button"
              description="Create team button for the browse teams empty state"
              defaultMessage="Create a team"
            />
          </Button>
        </Stack>
      </Stack>
      {createTeamModal}
    </Inline>
  );
};

const browseTeamsEmptyStateDetailsStyles = xcss({
  maxWidth: '424px',
  marginInlineStart: 'space.250',
});

const emptyStateStyles = xcss({
  marginBlockEnd: 'space.600',
});
