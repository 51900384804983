import { borderRadius } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
// Kudos not supported in titan, no need to migrate
// eslint-disable-next-line no-restricted-imports
import { Link } from 'react-resource-router';
import styled from 'styled-components';

import { multilineEllipsis } from '@townsquare/ellipsis';
import { HoistReactionsWrapper } from '@townsquare/reactions';

import { Animated, MediaWrapper, Still } from './AnimateOnHover';
import { BackgroundGradient } from './KudosBackgroundGradientStyles';
import { KudosBgThemeType } from './__generated__/KudosContent.graphql';
import { MIN_KUDOS_CARD_WIDTH, MIN_KUDOS_CARD_WIDTH_LARGE } from './constants';
import { KudosCardSize } from './types';

interface KudosDescriptionProps {
  showAllDescriptionRows?: boolean;
}

interface KudosContentProps {
  backgroundTheme?: KudosBgThemeType;
  cardSize?: KudosCardSize;
  numberOfKudosCards?: number;
  centreAlign?: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const KudosContent = styled.div<KudosContentProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: ${props => (props.cardSize == 'large' ? MIN_KUDOS_CARD_WIDTH_LARGE : MIN_KUDOS_CARD_WIDTH)}px;
  max-width: ${props =>
    `calc((100% - (${props.cardSize == 'large' ? '48px' : '24px'})) / ${props.numberOfKudosCards || 1});`};
  border-radius: ${borderRadius() * 2}px;
  box-shadow: ${token('elevation.shadow.raised')};
  margin: ${props => (props.centreAlign ? '0 auto' : '0')};

  &:hover,
  &.active {
    box-shadow: ${token('elevation.shadow.overlay')};
    ${HoistReactionsWrapper} > div {
      display: contents;
    }
  }

  &:hover ${MediaWrapper} > ${Animated} {
    opacity: 1;
  }

  &:hover ${MediaWrapper} > ${Still} {
    opacity: 0;
  }

  ${props => props.backgroundTheme && BackgroundGradient(props.backgroundTheme)}

  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KudosContentLink = styled(Link)({
  height: '100%',
  padding: token('space.200'),
  '&:hover': {
    textDecoration: 'none',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KudosContentImgWrapper = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: `${borderRadius() * 2}px`,
  width: '100%',
  height: '100px',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KudosContentImgBlurredBg = styled.img({
  height: '100%',
  width: '100%',
  minWidth: 0,
  position: 'absolute',
  filter: 'blur(20px)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KudosContentImg = styled.img({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: `${borderRadius() * 2}px`,
  maxHeight: '100%',
  maxWidth: '100%',
  minWidth: 0,
  margin: 'auto',
  zIndex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const KudosContentDescription = styled.div<KudosDescriptionProps>`
  margin-top: ${token('space.150')};

  ${props => !props.showAllDescriptionRows && multilineEllipsis(4)}

  p {
    color: ${token('color.text.subtle')};
    font: ${token('font.body')} !important;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Participants = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Names = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: token('space.100'),
  overflowX: 'hidden',
  font: token('font.heading.xxsmall'),
  color: token('color.text'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  span: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Name = styled.span({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const From = styled.span({
  color: token('color.text.subtlest'),
  marginRight: token('space.050'),
});
