import Button from '@atlaskit/button';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { useOnMount } from '@townsquare/hooks';

import { EmptySearchStateText } from '../styles';
import { ClearSearchQueryFn } from '../types';

import { EmptySearchStateContainer, EmptySearchStateImage } from './styles';

interface EmptySearchStateProps {
  resultType: 'people' | 'teams' | 'kudos' | 'people or teams';
  clearSearchQuery: ClearSearchQueryFn;
}

export const EmptySearchState = ({ resultType, clearSearchQuery }: EmptySearchStateProps) => {
  const analytics = useAnalytics();

  useOnMount(() => {
    void analytics.ui('staffDirectorySearchEmptyState', 'shown', {
      resultType,
    });
  });

  const onClearFiltersClicked = () => {
    void analytics.ui('staffDirectorySearchEmptyStateClearFilters', 'clicked', {
      resultType,
    });
    clearSearchQuery();
  };

  return (
    <EmptySearchStateContainer>
      <EmptySearchStateImage src="/Images/states/no-results-found.svg" />
      <EmptySearchStateText>
        <FormattedMessage
          id="townsquare.web.empty-search-state.no-results-found"
          description="Search empty state no results found"
          defaultMessage="We couldn't find any {resultType, select, people {people} teams {teams} kudos {kudos} other {people or teams}} matching your search. Try changing your search criteria or <button>clear all filters</button>"
          values={{
            resultType,
            button: (text: string) => (
              <Button appearance="link" spacing="compact" onClick={onClearFiltersClicked}>
                {text}
              </Button>
            ),
          }}
        />
      </EmptySearchStateText>
    </EmptySearchStateContainer>
  );
};
