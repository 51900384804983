import Avatar from '@atlaskit/avatar';
import Lozenge from '@atlaskit/lozenge';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { UserAvatarProps } from '../types';

import { BaseUserNameWithoutProfileCard } from './BaseUserNameWithoutProfileCard';
import * as styled from './styles';

export const BaseUserAvatarWithoutProfileCard = (props: Omit<UserAvatarProps, 'profileCardPosition'>) => {
  const {
    aaid,
    url,
    size = 'medium',
    showName = true,
    hideBorder,
    name,
    jobTitle,
    linkToProfile,
    isPendingUser = false,
    isDeactivatedUser = false,
  } = props;
  const src = url ?? '';

  const AvatarElement = useMemo(() => <Avatar src={src} size={size} borderColor="transparent" />, [src, size]);

  return (
    <styled.UserAvatar onClick={props.onClick}>
      <styled.BorderlessAvatar size={size} hideBorder={hideBorder}>
        {AvatarElement}
      </styled.BorderlessAvatar>
      {showName && (
        <styled.NameAndDescription size={size}>
          <BaseUserNameWithoutProfileCard
            aaid={aaid}
            name={name}
            size={size}
            linkToProfile={linkToProfile}
            isDeactivatedUser={isDeactivatedUser}
          />
          {jobTitle && <styled.Description>{jobTitle}</styled.Description>}
        </styled.NameAndDescription>
      )}
      {isPendingUser && showName && (
        <styled.InvitedLozenge>
          <Lozenge>
            <FormattedMessage
              id="townsquare.user-avatar.base-user-avatar-without-profile-card.invited-lozenge"
              description="Invited lozenge"
              defaultMessage="Invited"
            />
          </Lozenge>
        </styled.InvitedLozenge>
      )}
    </styled.UserAvatar>
  );
};
