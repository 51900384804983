import { commitMutation, Environment, graphql, MutationConfig } from 'relay-runtime';

import {
  UpdateDirectoryViewMutation,
  UpdateDirectoryViewMutation$variables,
} from './__generated__/UpdateDirectoryViewMutation.graphql';

export const updateDirectoryView = (
  environment: Environment,
  variables: UpdateDirectoryViewMutation$variables,
  events: Pick<MutationConfig<UpdateDirectoryViewMutation>, 'onCompleted' | 'onError'>,
) => {
  return commitMutation<UpdateDirectoryViewMutation>(environment, {
    mutation: graphql`
      mutation UpdateDirectoryViewMutation(
        $directoryViewUuid: UUID!
        $workspaceUuid: UUID!
        $name: String
        $tql: String
        $uiViewType: DirectoryViewUiType
        $projectSort: [ProjectSortEnum]
        $goalSort: [GoalSortEnum]
        $timelineDateSpan: TimelineDateSpan
        $directoryViewColumns: [directoryViewColumnInput]
      ) {
        editDirectoryView(
          input: {
            directoryViewUuid: $directoryViewUuid
            workspaceUuid: $workspaceUuid
            name: $name
            tql: $tql
            uiViewType: $uiViewType
            projectSort: $projectSort
            goalSort: $goalSort
            timelineDateSpan: $timelineDateSpan
            directoryViewColumns: $directoryViewColumns
          }
        ) {
          directoryView {
            ...storeDirectoryView
          }
        }
      }
    `,
    variables,
    onCompleted: events.onCompleted,
    onError: events.onError,
  });
};
