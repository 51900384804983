import { usePostOfficeEnvironment } from '@atlassian/post-office-context';
import { useMemo } from 'react';

export const PLACEMENT_PATH = `/api/v1/placements/`;

export type BuiltBasePath = {
	baseUrlOverride?: string;
	baseUrl?: string;
	path?: string;
	requestUrl?: string;
};

type BuildBasePath = (params: { baseUrlOverride?: string }) => BuiltBasePath;

export const useBuildBasePath: BuildBasePath = ({ baseUrlOverride }) => {
	const env = usePostOfficeEnvironment();

	return useMemo<BuiltBasePath>(() => {
		const baseUrlOverrideUrl = baseUrlOverride ? new URL(baseUrlOverride) : undefined;

		const urlParts = baseUrlOverrideUrl
			? {
					baseUrl: baseUrlOverrideUrl?.origin,
					path: baseUrlOverrideUrl?.pathname ?? PLACEMENT_PATH,
				}
			: {
					baseUrl: env?.api?.origin,
					path: env?.api?.pathname ?? PLACEMENT_PATH,
				};

		const requestUrl =
			urlParts?.baseUrl && urlParts?.path
				? new URL(urlParts.path, urlParts.baseUrl).toString()
				: urlParts.path;

		return {
			baseUrlOverride,
			requestUrl,
			...urlParts,
		};
	}, [baseUrlOverride, env?.api?.pathname, env?.api?.origin]);
};
