/**
 * @generated SignedSource<<3b9a93085a24a5c854f65032e4dceb74>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type WorkspaceContextType = "Default" | "DirectoryViewUuid" | "GoalKey" | "ProjectKey" | "TagId" | "TagUuid" | "WorkspaceCloudId" | "WorkspaceIdentifier" | "WorkspaceInOrgByCloudId";
export type WorkspaceContextInput = {
  contextType: WorkspaceContextType;
  identifier?: string | null;
};
export type resolveWorkspaceQuery$variables = {
  workspaceContext?: WorkspaceContextInput | null;
};
export type resolveWorkspaceQuery$data = {
  readonly workspaceByContext: {
    readonly __typename: "WorkspaceByContextAccessDeniedResponse";
  } | {
    readonly __typename: "WorkspaceByContextErrorResponse";
  } | {
    readonly __typename: "WorkspaceByContextProductRecommendationResponse";
    readonly cloudId: string;
  } | {
    readonly __typename: "WorkspaceByContextSuccessfulResponse";
    readonly " $fragmentSpreads": FragmentRefs<"BootstrapWorkspaceData" | "storeWorkspace">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type resolveWorkspaceQuery = {
  response: resolveWorkspaceQuery$data;
  variables: resolveWorkspaceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceContext"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "workspaceContext",
    "variableName": "workspaceContext"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "name": "cloudId"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v7 = {
  "kind": "ScalarField",
  "name": "organisationId"
},
v8 = {
  "kind": "ScalarField",
  "name": "type"
},
v9 = {
  "kind": "ScalarField",
  "name": "name"
},
v10 = {
  "kind": "ScalarField",
  "name": "keyPrefix"
},
v11 = {
  "kind": "ScalarField",
  "name": "timezone"
},
v12 = {
  "kind": "ScalarField",
  "name": "cloudUrl"
},
v13 = {
  "kind": "ScalarField",
  "name": "cloudName"
},
v14 = {
  "kind": "ScalarField",
  "name": "goalScoringMode"
},
v15 = {
  "kind": "ScalarField",
  "name": "activationId"
},
v16 = {
  "kind": "ScalarField",
  "name": "userIsOrgAdmin"
},
v17 = {
  "kind": "ScalarField",
  "name": "userHasInvitePermission"
},
v18 = {
  "concreteType": "FeatureContext",
  "kind": "LinkedField",
  "name": "featureContext",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "transactionAccountId"
    },
    {
      "kind": "ScalarField",
      "name": "edition"
    },
    {
      "concreteType": "WorkspaceFeatures",
      "kind": "LinkedField",
      "name": "features",
      "plural": false,
      "selections": [
        (v14/*: any*/),
        {
          "kind": "ScalarField",
          "name": "privateProjects"
        },
        {
          "kind": "ScalarField",
          "name": "customFieldsLimit"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "entitlementId"
    }
  ]
},
v19 = {
  "concreteType": "WorkspaceAIConfig",
  "kind": "LinkedField",
  "name": "aiConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "enabled"
    }
  ]
},
v20 = {
  "concreteType": "UserWorkspacePermissions",
  "kind": "LinkedField",
  "name": "userWorkspacePermissions",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "admin"
    }
  ]
},
v21 = {
  "kind": "ScalarField",
  "name": "workspaceCount"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "resolveWorkspaceQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "kind": "LinkedField",
          "name": "workspaceByContext",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": (v3/*: any*/),
              "type": "WorkspaceByContextAccessDeniedResponse"
            },
            {
              "kind": "InlineFragment",
              "selections": (v3/*: any*/),
              "type": "WorkspaceByContextErrorResponse"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "RequiredField",
                  "field": (v4/*: any*/),
                  "action": "THROW",
                  "path": "workspaceByContext.cloudId"
                }
              ],
              "type": "WorkspaceByContextProductRecommendationResponse"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "BootstrapWorkspaceData",
                  "selections": [
                    {
                      "concreteType": "Workspace",
                      "kind": "LinkedField",
                      "name": "workspace",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v4/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/)
                      ]
                    }
                  ],
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "storeWorkspace",
                  "selections": [
                    {
                      "concreteType": "Workspace",
                      "kind": "LinkedField",
                      "name": "workspace",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/),
                        (v4/*: any*/),
                        (v12/*: any*/),
                        (v13/*: any*/),
                        (v7/*: any*/),
                        (v14/*: any*/),
                        (v8/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/),
                        (v18/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/)
                      ]
                    },
                    (v21/*: any*/)
                  ],
                  "argumentDefinitions": []
                }
              ],
              "type": "WorkspaceByContextSuccessfulResponse"
            }
          ]
        },
        "action": "THROW",
        "path": "workspaceByContext"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "resolveWorkspaceQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "workspaceByContext",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "WorkspaceByContextProductRecommendationResponse"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "Workspace",
                "kind": "LinkedField",
                "name": "workspace",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ]
              },
              (v21/*: any*/)
            ],
            "type": "WorkspaceByContextSuccessfulResponse"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "ca9f899ad14673aef812543423ded673",
    "id": null,
    "metadata": {},
    "name": "resolveWorkspaceQuery",
    "operationKind": "query",
    "text": "query resolveWorkspaceQuery(\n  $workspaceContext: WorkspaceContextInput\n) {\n  workspaceByContext(workspaceContext: $workspaceContext) {\n    __typename\n    ... on WorkspaceByContextAccessDeniedResponse {\n      __typename\n    }\n    ... on WorkspaceByContextErrorResponse {\n      __typename\n    }\n    ... on WorkspaceByContextProductRecommendationResponse {\n      __typename\n      cloudId\n    }\n    ... on WorkspaceByContextSuccessfulResponse {\n      __typename\n      ...BootstrapWorkspaceData\n      ...storeWorkspace\n    }\n  }\n}\n\nfragment BootstrapWorkspaceData on WorkspaceByContextSuccessfulResponse {\n  workspace {\n    id\n    uuid\n    cloudId\n    organisationId\n    type\n  }\n}\n\nfragment storeWorkspace on WorkspaceByContextSuccessfulResponse {\n  workspace {\n    id\n    uuid\n    name\n    keyPrefix\n    timezone\n    cloudId\n    cloudUrl\n    cloudName\n    organisationId\n    goalScoringMode\n    type\n    activationId\n    userIsOrgAdmin\n    userHasInvitePermission\n    featureContext {\n      transactionAccountId\n      edition\n      features {\n        goalScoringMode\n        privateProjects\n        customFieldsLimit\n      }\n      entitlementId\n    }\n    aiConfig {\n      enabled\n    }\n    userWorkspacePermissions {\n      admin\n    }\n  }\n  workspaceCount\n}\n"
  }
};
})();

(node as any).hash = "624878eac42aad70b3c8a937ba07192b";

export default node;
