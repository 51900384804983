export enum TimelineDateSpan {
  Month = 'month',
  Week = 'week',
}

export type DateHeaderFillObject = {
  name: string;
  startDate: Date;
  daysInSpan: number;
};

export type ProjectBarPosition = {
  marginLeft: string;
  width: string;
  borderRadius: string;
};

export type ProjectBarInnerProps = {
  position: ProjectBarPosition;
  text: JSX.Element;
  lozengeAfter: boolean;
  fadePercentage?: number;
};
