/**
 * @generated SignedSource<<dd41a94b2268754736069432cfcf7212>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type WorkspaceType = "GLOBAL_EXPERIENCE" | "PRODUCT";
import type { FragmentRefs } from "relay-runtime";
export type BootstrapWorkspaceData$data = {
  readonly workspace: {
    readonly cloudId: string;
    readonly id: string;
    readonly organisationId: string;
    readonly type: WorkspaceType;
    readonly uuid: string;
  } | null;
  readonly " $fragmentType": "BootstrapWorkspaceData";
};
export type BootstrapWorkspaceData$key = {
  readonly " $data"?: BootstrapWorkspaceData$data;
  readonly " $fragmentSpreads": FragmentRefs<"BootstrapWorkspaceData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "BootstrapWorkspaceData"
};

(node as any).hash = "2074651cd9b32b8fc262af2b1e00b499";

export default node;
