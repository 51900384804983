/**
 * @generated SignedSource<<07bb7db5f87de234dce318bf18ea1ad1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type markRiskAsResolvedInput = {
  details?: string | null;
  id: string;
};
export type ResolveRiskMutation$variables = {
  input: markRiskAsResolvedInput;
};
export type ResolveRiskMutation$data = {
  readonly markRiskAsResolved: {
    readonly risk: {
      readonly description: string | null;
      readonly id: string;
      readonly lastEditedBy: {
        readonly pii: {
          readonly name: string | null;
          readonly picture: string | null;
        } | null;
      } | null;
      readonly lastEditedDate: string | null;
      readonly summary: string | null;
    } | null;
  } | null;
};
export type ResolveRiskMutation = {
  response: ResolveRiskMutation$data;
  variables: ResolveRiskMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "summary"
},
v4 = {
  "kind": "ScalarField",
  "name": "description"
},
v5 = {
  "concreteType": "UserPII",
  "kind": "LinkedField",
  "name": "pii",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "picture"
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "lastEditedDate"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ResolveRiskMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "markRiskAsResolvedPayload",
        "kind": "LinkedField",
        "name": "markRiskAsResolved",
        "plural": false,
        "selections": [
          {
            "concreteType": "Risk",
            "kind": "LinkedField",
            "name": "risk",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "lastEditedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResolveRiskMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "markRiskAsResolvedPayload",
        "kind": "LinkedField",
        "name": "markRiskAsResolved",
        "plural": false,
        "selections": [
          {
            "concreteType": "Risk",
            "kind": "LinkedField",
            "name": "risk",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "lastEditedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "306c7fdd84c7af775a3b55de7de4e112",
    "id": null,
    "metadata": {},
    "name": "ResolveRiskMutation",
    "operationKind": "mutation",
    "text": "mutation ResolveRiskMutation(\n  $input: markRiskAsResolvedInput!\n) {\n  markRiskAsResolved(input: $input) {\n    risk {\n      id\n      summary\n      description\n      lastEditedBy {\n        pii {\n          name\n          picture\n        }\n        id\n      }\n      lastEditedDate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "318220ac7e960bbc3e592184052df432";

export default node;
