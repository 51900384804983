import React from 'react';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { LoadingSkeleton } from '@townsquare/loading-skeleton';
import { ResponsiveLoadingState } from '@townsquare/loading-state';

import { PeopleFieldType } from '../../hooks/types';
import { PeopleFieldIllustrationCard, PeopleLocationCard } from '../Cards/Browse/PeopleField';
import {
  LocationCardLoadingStateContainer,
  STYLED_ILLUSTRATION_CARD_HEIGHT,
  STYLED_ILLUSTRATION_CARD_WIDTH,
  STYLED_KUDOS_CARD_HEIGHT,
  STYLED_KUDOS_CARD_WIDTH,
} from '../Cards/Browse/PeopleField/styles';
import { Heading } from '../Heading';
import { ScreenType } from '../ScreenType';
import { CardRow } from '../styles';

import { BrowsePeopleFieldSection$key } from './__generated__/BrowsePeopleFieldSection.graphql';

const BROWSE_PEOPLE_FIELD_CARD_SLOTS = 4;
const BROWSE_KUDOS_CARD_SLOTS = 5;

const IllustrationLoadingCards = () => (
  <>
    {[...Array(BROWSE_PEOPLE_FIELD_CARD_SLOTS)].map((_, i) => (
      <LoadingSkeleton
        width={STYLED_ILLUSTRATION_CARD_WIDTH}
        height={STYLED_ILLUSTRATION_CARD_HEIGHT}
        marginTopPx={5}
        key={i}
      />
    ))}
  </>
);

const KudosLoadingCards = () => (
  <>
    {[...Array(BROWSE_KUDOS_CARD_SLOTS)].map((_, i) => (
      <LoadingSkeleton width={STYLED_KUDOS_CARD_WIDTH} height={STYLED_KUDOS_CARD_HEIGHT} marginTopPx={5} key={i} />
    ))}
  </>
);

const LocationLoadingCards = () => (
  <>
    {[...Array(BROWSE_PEOPLE_FIELD_CARD_SLOTS)].map((_, i) => (
      <LocationCardLoadingStateContainer key={i}>
        <LoadingSkeleton
          marginTopPx={10}
          width={STYLED_ILLUSTRATION_CARD_WIDTH}
          height={STYLED_ILLUSTRATION_CARD_HEIGHT}
        />
        <LoadingSkeleton marginTopPx={14} height="22px" width="220px" />
        <LoadingSkeleton marginTopPx={2} height="18px" width="44px" />
      </LocationCardLoadingStateContainer>
    ))}
  </>
);

type BrowsePeopleFieldSectionFrameProps = {
  browseAllScreenType: ScreenType;
  children: React.ReactNode;
  sectionTitle: string;
};

const BrowsePeopleFieldSectionFrame = ({
  browseAllScreenType,
  children,
  sectionTitle,
}: BrowsePeopleFieldSectionFrameProps) => {
  const intl = useIntl();
  return (
    <>
      <Heading
        browseMoreText={intl.formatMessage({
          id: 'townsquare.web.staff-directory-view.browse-all',
          defaultMessage: 'Browse all',
          description: 'Browse all text',
        })}
        screenType={browseAllScreenType}
      >
        {sectionTitle}
      </Heading>
      <CardRow>{children}</CardRow>
    </>
  );
};

type BrowsePeopleFieldSectionSkeletonProps = {
  fieldType: PeopleFieldType;
} & Pick<BrowsePeopleFieldSectionFrameProps, 'browseAllScreenType' | 'sectionTitle'>;

export const BrowsePeopleFieldSectionSkeleton = ({
  browseAllScreenType,
  fieldType,
  sectionTitle,
}: BrowsePeopleFieldSectionSkeletonProps) => {
  return (
    <BrowsePeopleFieldSectionFrame browseAllScreenType={browseAllScreenType} sectionTitle={sectionTitle}>
      <ResponsiveLoadingState>
        <CardRow>
          {fieldType === 'LOCATION' ? (
            <LocationLoadingCards />
          ) : fieldType === 'KUDOS' ? (
            <KudosLoadingCards />
          ) : (
            <IllustrationLoadingCards />
          )}
        </CardRow>
      </ResponsiveLoadingState>
    </BrowsePeopleFieldSectionFrame>
  );
};

type BrowsePeopleFieldSectionProps = {
  data: BrowsePeopleFieldSection$key;
  fieldType: PeopleFieldType;
} & Pick<BrowsePeopleFieldSectionFrameProps, 'browseAllScreenType' | 'sectionTitle'>;

export const BrowsePeopleFieldSection = ({
  browseAllScreenType,
  data,
  fieldType,
  sectionTitle,
}: BrowsePeopleFieldSectionProps) => {
  const fields = useFragment(
    graphql`
      fragment BrowsePeopleFieldSection on PeopleFieldAggregateResponse {
        results {
          ...LocationCard
          ...IllustrationCard
        }
      }
    `,
    data,
  );

  if (!fields.results?.length) {
    return null;
  }

  return (
    <BrowsePeopleFieldSectionFrame browseAllScreenType={browseAllScreenType} sectionTitle={sectionTitle}>
      {fields?.results?.map((field, i) => {
        if (field) {
          return fieldType === 'LOCATION' ? (
            <PeopleLocationCard data={field} key={`location-card-${i}`} />
          ) : (
            field && <PeopleFieldIllustrationCard data={field} key={`illustration-card-${i}`} fieldType={fieldType} />
          );
        }
      })}
    </BrowsePeopleFieldSectionFrame>
  );
};
