/**
 * @generated SignedSource<<c3b227bd6b241af8a06f254eb02a8732>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive";
import type { FragmentRefs } from "relay-runtime";
export type UserAvatar$data = {
  readonly aaid: string | null;
  readonly pii: {
    readonly accountStatus?: AccountStatus | null;
    readonly email?: string | null;
    readonly extendedProfile?: {
      readonly jobTitle: string | null;
    } | null;
    readonly name?: string | null;
    readonly picture: string | null;
  } | null;
  readonly " $fragmentType": "UserAvatar";
};
export type UserAvatar$key = {
  readonly " $data"?: UserAvatar$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "extendedProfile"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeEmail"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "showName"
    }
  ],
  "kind": "Fragment",
  "name": "UserAvatar",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "aaid"
    },
    {
      "concreteType": "UserPII",
      "kind": "LinkedField",
      "name": "pii",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        {
          "condition": "showName",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "name"
            },
            {
              "kind": "ScalarField",
              "name": "accountStatus"
            }
          ]
        },
        {
          "condition": "extendedProfile",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "concreteType": "ExtendedProfile",
              "kind": "LinkedField",
              "name": "extendedProfile",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "jobTitle"
                }
              ]
            }
          ]
        },
        {
          "condition": "includeEmail",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "email"
            }
          ]
        }
      ]
    }
  ],
  "type": "User"
};

(node as any).hash = "26bd80c70b1b7d58965a2e9687d53d0f";

export default node;
