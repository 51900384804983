import Account from '@atlassian/account';
import { MenuListItem } from '@atlassian/navigation-system/v4';
import React, { useCallback } from 'react';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig, getIdentityEnv } from '@townsquare/config';
import { profileRoute } from '@townsquare/ptc-directory-view';
import { useThemeStore } from '@townsquare/theme';
import { useMetaClickHandler } from '@townsquare/ui-interactions';
import { useUserStore } from '@townsquare/user-store';

const config = getConfig();
const identityEnv = getIdentityEnv(config.env);

export const AccountPopup = () => {
  const [user] = useUserStore();
  const analytics = useAnalytics();
  const [theme, uiApi] = useThemeStore();

  const { handler, path } = useMetaClickHandler(
    profileRoute,
    { params: { id: encodeURIComponent(user.accountId) } },
    () => {
      analytics.ui('profileLink', 'clicked');
    },
  );

  const handleOnThemeChange = useCallback(
    newTheme => {
      void analytics.ui('themePreference', 'changed', { newPreference: newTheme, oldPreference: theme });
      uiApi.setTheme(newTheme);
    },
    [analytics, uiApi, theme],
  );

  return (
    <MenuListItem>
      <Account
        testId="nav-profile-button"
        identityEnvironment={identityEnv}
        application="atlas"
        continueUrl={config.fullUrl}
        name={user.name}
        picture={user.picture}
        email={user.email}
        viewProfileLink={path}
        onViewProfileClick={handler}
        currentTheme={theme}
        onThemeChange={handleOnThemeChange}
      />
    </MenuListItem>
  );
};
