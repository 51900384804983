/**
 * @generated SignedSource<<fbc82ef63afc756035255d948420575b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type utils_isUserInList$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly aaid: string | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "utils_isUserInList";
};
export type utils_isUserInList$key = {
  readonly " $data"?: utils_isUserInList$data;
  readonly " $fragmentSpreads": FragmentRefs<"utils_isUserInList">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "utils_isUserInList"
};

(node as any).hash = "0f8d467a77845a1c4ef3394bdb364c82";

export default node;
