import Button, { LoadingButton } from '@atlaskit/button';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useDirectoryEntityTypeFromRoute, useIsEditingView } from '@townsquare/directory-header/hooks';
import { LeftButton, RightButton, SplitButtons } from '@townsquare/directory-header/style';
import { useEditDirectoryMutation } from '@townsquare/directory-view-modals/hooks';
import { getFavouriteCountWithoutCurrentUser } from '@townsquare/directory-view-modals/utils';
import { useDirectoryViewStore } from '@townsquare/directory-view-store';
import { useModalActions } from '@townsquare/modals';

import { SaveViewMoreActions } from './SaveViewMoreActions';
import { ViewsListIcon } from './ViewsListIcon';
import { SaveViewText } from './style';

type SaveViewActionProps = {
  isMobileView?: boolean;
};

export const SaveViewAction = (props: SaveViewActionProps) => {
  const { openModal } = useModalActions();
  const isEditingView = useIsEditingView();
  const [selectedDirectoryView] = useDirectoryViewStore();
  const editDirectoryMutation = useEditDirectoryMutation();
  const [isLoading, setIsLoading] = useState(false);
  const entityType = useDirectoryEntityTypeFromRoute();
  const analytics = useAnalytics();
  const [routerStore] = useRouter();

  useEffect(() => {
    if (isEditingView) {
      window.Intercom?.('trackEvent', 'save-directory-view-button-visible');
    }
  }, [isEditingView]);

  if (!isEditingView) {
    return null;
  }

  if (selectedDirectoryView.uuid) {
    const favouriteCountWithoutCurrentUser = getFavouriteCountWithoutCurrentUser(
      selectedDirectoryView.favouriteCount,
      selectedDirectoryView.isFavourite,
    );
    const skipDialog = favouriteCountWithoutCurrentUser < 1;

    return (
      <SplitButtons>
        <LeftButton borderRadius={3}>
          <LoadingButton
            testId="edit-view-button"
            iconBefore={props.isMobileView ? undefined : <ViewsListIcon />}
            isLoading={isLoading}
            onClick={async () => {
              if (skipDialog) {
                setIsLoading(true);
                void analytics.ui('saveDirectoryViewButton', 'clicked', {
                  screen: routerStore.route.name,
                });
                await editDirectoryMutation();
                setIsLoading(false);
              } else {
                openModal('edit-directory-view-modal', {});
              }
            }}
            appearance="primary"
          >
            <SaveViewText>
              {skipDialog ? (
                <FormattedMessage
                  id="townsquare.directory.save-view-action.save"
                  description="Edits a directory view"
                  defaultMessage="Save"
                />
              ) : (
                <FormattedMessage
                  id="townsquare.directory.save-view-action.save-for-everyone"
                  description="Saves edits for directory view that multiple people have currently favourited"
                  defaultMessage="Save for everyone"
                />
              )}
            </SaveViewText>
          </LoadingButton>
        </LeftButton>
        <RightButton borderRadius={3} isSplitButton>
          <SaveViewMoreActions />
        </RightButton>
      </SplitButtons>
    );
  }

  return (
    <Button
      testId="save-new-view-button"
      iconBefore={props.isMobileView ? undefined : <ViewsListIcon />}
      onClick={() => openModal('create-directory-view-modal', { entityType })}
      appearance="primary"
    >
      <SaveViewText>
        {props.isMobileView ? (
          <FormattedMessage
            id="townsquare.directory.save-view-action.save-as-view"
            description="Saves a directory view (mobile text)"
            defaultMessage="Save as view"
          />
        ) : (
          <FormattedMessage
            id="townsquare.directory.save-view-action.create-view"
            description="Saves a directory view"
            defaultMessage="Create view"
          />
        )}
      </SaveViewText>
    </Button>
  );
};
