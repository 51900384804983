import { createHook, createStateHook } from 'react-sweet-state';

import { getConfig } from '@townsquare/config';
import {
  ATLASSIAN_PRODUCT_NAMES,
  ANALYTICS_PRODUCT_IDENTIFIER,
  GLOBAL_EXPERIENCE_DISPLAY_NAME,
  GLOBAL_EXPERIENCE_DISPLAY_NAME_FULL,
  PEOPLE_PRODUCT_IDENTIFIER,
  PRODUCT_DISPLAY_NAME,
} from '@townsquare/config/constants';
import { useFeatureGate } from '@townsquare/stat-sig/gate';

import { workspaceStore } from './store';
import { WorkspaceDetails } from './types';

const LEGACY_LINK_PICKER_CONFIG_PRODUCT = 'atlas';
const LINK_PICKER_CONFIG_PRODUCT = 'townsquare';

export const useWorkspaceStore = createHook(workspaceStore);
export const useWorkspaceUUID = createHook(workspaceStore, {
  selector: state => state.UUID,
});
export const useWorkspaceGlobalId = createHook(workspaceStore, {
  selector: state => state.globalId,
});
export const useWorkspaceCloudId = createHook(workspaceStore, {
  selector: state => state.cloudId,
});
export const useWorkspaceCloudUrl = createHook(workspaceStore, {
  selector: state => state.cloudUrl,
});
export const useWorkspaceFeatureContext = createHook(workspaceStore, {
  selector: state => state.featureContext,
});

export const useWorkspaceCapabilities = createHook(workspaceStore, {
  selector: state => state.capabilities,
});

export const useProductDisplayName = createStateHook(workspaceStore, {
  selector: state => (state.type === 'PRODUCT' ? PRODUCT_DISPLAY_NAME : GLOBAL_EXPERIENCE_DISPLAY_NAME),
});

export const useProductDisplayNameFull = createStateHook(workspaceStore, {
  selector: state => (state.type === 'PRODUCT' ? PRODUCT_DISPLAY_NAME : GLOBAL_EXPERIENCE_DISPLAY_NAME_FULL),
});

export const useWorkspaceType = createStateHook(workspaceStore, {
  selector: state => state.type,
});

export const useCPUSScope = createStateHook(workspaceStore, {
  selector: (state: WorkspaceDetails, { isInviteRelated }: { isInviteRelated: boolean }) =>
    state.type === 'GLOBAL_EXPERIENCE' || (isInviteRelated && state.capabilities?.inviteToWorkspace)
      ? PEOPLE_PRODUCT_IDENTIFIER
      : ANALYTICS_PRODUCT_IDENTIFIER,
});

export const useSiteName = createStateHook(workspaceStore, {
  selector: state => state.cloudName,
});

export const useCloudId = createStateHook(workspaceStore, {
  selector: state => state.cloudId,
});

export const useNoWorkspaceExperience = createStateHook(workspaceStore, {
  selector: state => !state.globalId,
});

export const useSmartCardBaseUrl = createStateHook(workspaceStore, {
  selector: state =>
    state.type === 'GLOBAL_EXPERIENCE' ? getConfig().homeSmartCardBaseUrl : getConfig().productSmartCardBaseUrl,
});

export const useActivationId = createStateHook(workspaceStore, {
  selector: state => state.activationId,
});

export const useLinkPickerConfigProduct = createStateHook(workspaceStore, {
  selector: state =>
    state.type === 'GLOBAL_EXPERIENCE' ? LINK_PICKER_CONFIG_PRODUCT : LEGACY_LINK_PICKER_CONFIG_PRODUCT,
});

/**
 * Returns workspaceIdentifier of active workspace, OR null when feature flag is off
 */
export const useWorkspaceIdentifier = () => {
  return null;
};

export const useJiraProductName = () =>
  useFeatureGate('platform_jsw_jwm_spork_rebranding')
    ? ATLASSIAN_PRODUCT_NAMES.JIRA
    : ATLASSIAN_PRODUCT_NAMES.JIRA_SOFTWARE;
