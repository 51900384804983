/**
 * @generated SignedSource<<fa64ed33d7d406d416373b330af1f7b3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type LearningModifierSignatures_data$data = {
  readonly ari: string;
  readonly creationDate: string | null;
  readonly creator: {
    readonly pii: {
      readonly name: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatar">;
  } | null;
  readonly goal: {
    readonly ari: string;
    readonly key: string;
  } | null;
  readonly lastEditedBy: {
    readonly pii: {
      readonly name: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatar">;
  } | null;
  readonly lastEditedDate: string | null;
  readonly project: {
    readonly ari: string;
    readonly key: string;
  } | null;
  readonly uuid: string;
  readonly " $fragmentType": "LearningModifierSignatures_data";
};
export type LearningModifierSignatures_data$key = {
  readonly " $data"?: LearningModifierSignatures_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"LearningModifierSignatures_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "ari"
},
v1 = [
  {
    "kind": "ScalarField",
    "name": "key"
  },
  (v0/*: any*/)
],
v2 = [
  {
    "concreteType": "UserPII",
    "kind": "LinkedField",
    "name": "pii",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "name"
      }
    ]
  },
  {
    "args": [
      {
        "kind": "Literal",
        "name": "showName",
        "value": false
      }
    ],
    "kind": "FragmentSpread",
    "name": "UserAvatar"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "LearningModifierSignatures_data",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "uuid"
    },
    (v0/*: any*/),
    {
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "concreteType": "Goal",
      "kind": "LinkedField",
      "name": "goal",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "ScalarField",
      "name": "creationDate"
    },
    {
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "lastEditedBy",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "ScalarField",
      "name": "lastEditedDate"
    }
  ],
  "type": "Highlight",
  "abstractKey": "__isHighlight"
};
})();

(node as any).hash = "1bb98e49726a6f99d3980b0b54f52e36";

export default node;
