import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { useOnMount } from '@townsquare/hooks';
import { filterNull } from '@townsquare/type-helpers';

import { PeopleFieldIllustrationCard, PeopleLocationCard } from '../Cards/Browse/PeopleField';
import { ScreenType, getPeopleFieldType } from '../ScreenType';

import { BrowsePeopleFieldsScreenQuery$key } from './__generated__/BrowsePeopleFieldsScreenQuery.graphql';
import { ScrollingResultsContainer, SearchResultsContainer } from './styles';

export const BrowsePeopleFieldsScreen = (props: {
  data: BrowsePeopleFieldsScreenQuery$key;
  screenType: ScreenType;
}) => {
  const data = useFragment<BrowsePeopleFieldsScreenQuery$key>(
    graphql`
      fragment BrowsePeopleFieldsScreenQuery on Query
      @argumentDefinitions(
        query: { type: "String!" }
        first: { type: "Int" }
        after: { type: "String" }
        peopleFieldType: { type: "PeopleFieldType!" }
        workspaceId: { type: "ID!" }
      ) {
        peopleFieldAggregates(
          searchString: $query
          workspaceId: $workspaceId
          peopleFieldType: $peopleFieldType
          first: $first
          after: $after
        ) {
          results {
            ...LocationCard
            ...IllustrationCard
          }
        }
      }
    `,
    props.data,
  );

  const fieldType = useMemo(() => {
    return getPeopleFieldType(props.screenType);
  }, [props.screenType]);

  const analytics = useAnalytics();
  useOnMount(() => {
    void analytics.ui('browsePeopleFieldScreen', 'viewed', { fieldType });
  });

  const fieldResults = data.peopleFieldAggregates?.results?.filter(filterNull) ?? [];

  const resultCards =
    fieldType === 'LOCATION'
      ? fieldResults.map(field => <PeopleLocationCard data={field} />)
      : fieldResults.map(field => <PeopleFieldIllustrationCard data={field} fieldType={fieldType} />);

  // We can't paginate this graphql endpoint due to legion limitations, so we load and show all the results in one go
  return (
    <ScrollingResultsContainer>
      <SearchResultsContainer
        data-testid="staff-dir-results-container"
        columnGapPx={24}
        rowGapPx={16}
        margin="0 0 0 -8px"
      >
        {resultCards}
      </SearchResultsContainer>
    </ScrollingResultsContainer>
  );
};
