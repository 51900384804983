import { token } from '@atlaskit/tokens';
import React from 'react';

export const ViewsListIcon = () => {
  const primaryColour = token('color.text.inverse');

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4"
        y="16"
        width="12"
        height="13"
        rx="1"
        transform="rotate(-90 4 16)"
        stroke={primaryColour}
        strokeWidth="2"
      />
      <rect x="10" y="12" width="4" height="2" rx="1" fill={primaryColour} />
      <rect x="10" y="9" width="5" height="2" rx="1" fill={primaryColour} />
      <rect x="10" y="6" width="5" height="2" rx="1" fill={primaryColour} />
      <rect x="6" y="12" width="3" height="2" rx="1" fill={primaryColour} />
      <rect x="6" y="9" width="3" height="2" rx="1" fill={primaryColour} />
      <rect x="6" y="6" width="3" height="2" rx="1" fill={primaryColour} />
      <path
        d="M6 18C6 19.1046 6.89543 20 8 20L19 20C20.1046 20 21 19.1046 21 18V8C21 6.89543 20.1046 6 19 6L19 18L6 18Z"
        fill={primaryColour}
      />
    </svg>
  );
};
