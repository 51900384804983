import React, { useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { IntlProvider as IntlNextProvider } from 'react-intl-next';

import { AppState, useAppStateStore } from '@townsquare/app-state-store';
import { useUserStore } from '@townsquare/user-store';

import DateLocaleContextProvider from './DateLocaleProvider';
import {
  defaultMessages,
  DEFAULT_LOCALE,
  determineLocale,
  IntlMessages,
  loadLocaleData,
  loadSmartCardMessages,
} from './locales';

const intlErrorHandler = () => {
  return;
};

interface Props {
  children: React.ReactNode;
  localeSelected?: string;
}

const TownsquareIntlProvider = ({ localeSelected, children }: Props) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [messages, setMessages] = useState<IntlMessages>(defaultMessages);
  const [user] = useUserStore();
  const [{ appState }] = useAppStateStore();

  const loadAndSetLocale = useCallback((locale: string) => {
    loadLocaleData(locale).then(resolvedMessages => {
      setLocale(locale);
      setMessages(messages => ({ ...messages, ...resolvedMessages }));
    });
  }, []);

  const loadMessagesForPackages = useCallback((locale: string) => {
    loadSmartCardMessages(locale).then(resolvedMessages => {
      setMessages(messages => ({ ...messages, ...resolvedMessages }));
    });
  }, []);

  useEffect(() => {
    // the language selected personally by the user (i.e from the toolbar) takes precedence
    // so the UI and user experience aligns
    if (localeSelected && localeSelected !== locale) {
      loadAndSetLocale(localeSelected);
      loadMessagesForPackages(localeSelected);
      // in storybook, the user is seeded (and an empty {}) and the value will always be truthy
    } else if (user && appState !== AppState.LOADING) {
      const resolvedLocale = determineLocale(user.locale) || DEFAULT_LOCALE;
      if (resolvedLocale !== locale) {
        loadAndSetLocale(resolvedLocale);
        loadMessagesForPackages(resolvedLocale);
      }
    }
  }, [user, appState, loadAndSetLocale, localeSelected, locale, loadMessagesForPackages]);

  return (
    <DateLocaleContextProvider locale={locale}>
      <IntlProvider locale={locale} messages={messages} onError={intlErrorHandler}>
        <IntlNextProvider locale={locale} messages={messages} onError={intlErrorHandler}>
          <React.Fragment>{children}</React.Fragment>
        </IntlNextProvider>
      </IntlProvider>
    </DateLocaleContextProvider>
  );
};

export default TownsquareIntlProvider;
