import Loadable from 'react-loadable';

import { PATH_PREFIX, SETTINGS_PAGE } from '@townsquare/config/routes';
import { createSiteRoute } from '@townsquare/router/create-route';

import { settingsPageResource } from './resources/settings';

export const settingsRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "settings-view" */ './components/SettingsView'),
    loading: () => null,
  }),
  exact: false,
  name: SETTINGS_PAGE,
  path: `${PATH_PREFIX.SETTINGS}/:pageName/:entity?`,
  resources: [settingsPageResource],
});
