import styled from 'styled-components';

const IconButtonWrapper = styled.div({
  display: 'flex',
  width: '24px',
  height: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default IconButtonWrapper;
