import { directoryViewColumnInput } from '@townsquare/directory-view-modals';
import { StandardDirectoryViewColumnName } from '@townsquare/directory-view-store';

import { ColumnInfo } from '../types';

const urlColumnToDirectoryViewColumnMap: Record<string, StandardDirectoryViewColumnName> = {
  contributors: 'CONTRIBUTORS',
  followerCount: 'FOLLOWER_COUNT',
  following: 'FOLLOWING',
  goals: 'GOALS',
  lastUpdated: 'LAST_UPDATED',
  owner: 'OWNER',
  relatedProjects: 'RELATED_PROJECTS',
  status: 'STATUS',
  tags: 'TAGS',
  startDate: 'START_DATE',
  targetDate: 'TARGET_DATE',
  team: 'TEAMS',
};

export const urlColumnToDirectoryViewColumn = (columnId: string): StandardDirectoryViewColumnName => {
  return urlColumnToDirectoryViewColumnMap[columnId] ?? columnId;
};

export const shownColumnsToDirectoryViewColumns = (columns: ColumnInfo[]): directoryViewColumnInput[] =>
  columns
    .filter(c => !c.locked && !c.custom)
    .map(({ id }) => urlColumnToDirectoryViewColumn(id))
    .map<directoryViewColumnInput>(id => ({ standardFieldName: id }))
    .concat(
      columns.filter(c => !c.locked && !!c.custom).map<directoryViewColumnInput>(c => ({ customFieldUuid: c.id })),
    );

const directoryViewColumnToUrlColumnMap: Record<string, string> = {
  CONTRIBUTORS: 'contributors',
  FOLLOWER_COUNT: 'followerCount',
  FOLLOWING: 'following',
  GOALS: 'goals',
  LAST_UPDATED: 'lastUpdated',
  OWNER: 'owner',
  RELATED_PROJECTS: 'relatedProjects',
  STATUS: 'status',
  TAGS: 'tags',
  START_DATE: 'startDate',
  TARGET_DATE: 'targetDate',
  TEAMS: 'team',
};

export const directoryViewColumnToUrlColumn = (column: string): string => {
  return directoryViewColumnToUrlColumnMap[column] ?? column;
};

export const columnToWidthFormat = (
  column: ColumnInfo,
): 'user' | 'userMulti' | 'number' | 'textSelect' | 'textSelectMulti' | 'text' | string => {
  if (column.custom) {
    switch (column.type) {
      case 'USER':
        if (column.isMulti) {
          return 'userMulti';
        }
        return 'user';
      case 'NUMBER':
        return 'number';
      case 'TEXT_SELECT':
        if (column.isMulti) {
          return 'textSelectMulti';
        }
        return 'textSelect';
      case 'TEXT':
      default:
        return 'text';
    }
  }

  return column.id;
};
