/**
 * @generated SignedSource<<3c8b47bda13988701e46c73201fb348b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BrowsePeopleFieldsScreenQuery$data = {
  readonly peopleFieldAggregates: {
    readonly results: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"IllustrationCard" | "LocationCard">;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "BrowsePeopleFieldsScreenQuery";
};
export type BrowsePeopleFieldsScreenQuery$key = {
  readonly " $data"?: BrowsePeopleFieldsScreenQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrowsePeopleFieldsScreenQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "peopleFieldType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "workspaceId"
    }
  ],
  "kind": "Fragment",
  "name": "BrowsePeopleFieldsScreenQuery",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "peopleFieldType",
          "variableName": "peopleFieldType"
        },
        {
          "kind": "Variable",
          "name": "searchString",
          "variableName": "query"
        },
        {
          "kind": "Variable",
          "name": "workspaceId",
          "variableName": "workspaceId"
        }
      ],
      "concreteType": "PeopleFieldAggregateResponse",
      "kind": "LinkedField",
      "name": "peopleFieldAggregates",
      "plural": false,
      "selections": [
        {
          "concreteType": "PeopleFieldAggregate",
          "kind": "LinkedField",
          "name": "results",
          "plural": true,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "LocationCard"
            },
            {
              "kind": "FragmentSpread",
              "name": "IllustrationCard"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "55bb31a05d9a9ea005a61e3faca3a078";

export default node;
