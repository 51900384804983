/* eslint-disable @atlaskit/design-system/no-deprecated-apis */
import { HeadingItem, MenuGroup, Section } from '@atlaskit/menu';
import React, { KeyboardEvent, MouseEvent, Suspense } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { Route, useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { redirect } from '@townsquare/facade/window';
import { filterNull } from '@townsquare/filter-type';
import { goalViewRoute } from '@townsquare/goal-view/route';
import { goalDirectoryRoute } from '@townsquare/goals-directory-view/route';
import { homeTabRoute } from '@townsquare/hometab-view';
import { LoadingState } from '@townsquare/loading-state';
import { projectRoute } from '@townsquare/project-view/route';
import { projectDirectoryRoute } from '@townsquare/projects-directory-view/route';
import { generatePath, useRouteDependencies } from '@townsquare/router/primitives';
import { tagsDirectoryRoute } from '@townsquare/tags-directory-view/route';
import { topicRoute } from '@townsquare/topic-view/route';
import { createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';
import { useCloudId } from '@townsquare/workspace-store/hooks';

import { SwitchWorkspaceItem } from './SwitchWorkspaceItem';
import { HomeWorkspaceSwitcherQuery as HomeWorkspaceSwitcherQueryType } from './__generated__/HomeWorkspaceSwitcherQuery.graphql';
import { WorkspaceDetailsForSwitcher } from './types';

const HomeWorkspaceSwitcherQuery = graphql`
  query HomeWorkspaceSwitcherQuery($context: OrganizationContextInput) {
    userOrganizationByContext(organizationContext: $context) @required(action: THROW) {
      workspaces {
        UUID: uuid
        cloudId
        name
        type
      }
    }
  }
`;
type HomeWorkspaceSwitcherProps = {
  queryReference: PreloadedQuery<HomeWorkspaceSwitcherQueryType>;
};

function getRedirectPathForRoute(route: Route, routeDependencies: ReturnType<typeof useRouteDependencies>) {
  if ([projectRoute.name, projectDirectoryRoute.name].includes(route.name)) {
    // If on a project or project directory -> navigate to the project directory
    return generatePath(projectDirectoryRoute, routeDependencies);
  } else if ([goalViewRoute.name, goalDirectoryRoute.name].includes(route.name)) {
    // If on a goal or goal directory -> goal directory
    return generatePath(goalDirectoryRoute, routeDependencies);
  } else if ([topicRoute.name, tagsDirectoryRoute.name].includes(route.name)) {
    // If on a tag or tag directory -> tag directory
    return generatePath(tagsDirectoryRoute, routeDependencies);
  } else {
    // All other cases, send them to the home page
    return generatePath(homeTabRoute, routeDependencies);
  }
}

const HomeWorkspaceSwitcher = (props: HomeWorkspaceSwitcherProps) => {
  const cloudId = useCloudId();
  const routeDependencies = useRouteDependencies();
  const data = usePreloadedQuery<HomeWorkspaceSwitcherQueryType>(HomeWorkspaceSwitcherQuery, props.queryReference);

  const analytics = useAnalytics();
  const [{ route }] = useRouter();

  const allWorkspaceOptions =
    data?.userOrganizationByContext?.workspaces
      ?.filter(filterNull)
      .filter(workspace => workspace?.type === 'GLOBAL_EXPERIENCE') ?? [];

  const onItemClick = (event: MouseEvent | KeyboardEvent, selectedWorkspace: WorkspaceDetailsForSwitcher) => {
    // Handled by redirects below, block native link functionality
    event.preventDefault();
    void analytics.ui('workspaceSwitcherNavWorkspaceOption', 'clicked', {
      workspaceUuid: selectedWorkspace.UUID,
    });

    const query = createSearchParamsFromScratch({ cloudId: selectedWorkspace.cloudId });
    const path = getRedirectPathForRoute(route, routeDependencies);
    redirect(`${path}?${query}`);
  };

  return (
    // Hack to position workspace switcher on the far right side of the nav
    <MenuGroup minWidth={192} maxWidth={480}>
      <Section>
        <HeadingItem>
          <FormattedMessage
            id="townsquare.home-navigation.profile-workspace-switcher.tooltip"
            description="Tooltip for the switch workspace option"
            defaultMessage="Switch workspace"
          />
        </HeadingItem>
        {allWorkspaceOptions.map(ws => (
          <SwitchWorkspaceItem
            onItemClick={onItemClick}
            workspace={ws}
            key={ws.cloudId}
            isSelected={ws.cloudId === cloudId}
          />
        ))}
      </Section>
    </MenuGroup>
  );
};

type HomeWorkspaceSwitcherWrapperProps = {
  queryReference: PreloadedQuery<HomeWorkspaceSwitcherQueryType> | null | undefined;
};

export const HomeWorkspaceSwitcherWrapper = (props: HomeWorkspaceSwitcherWrapperProps) => {
  return (
    <Suspense
      fallback={
        <MenuGroup minWidth={280}>
          <LoadingState />
        </MenuGroup>
      }
    >
      {props.queryReference && <HomeWorkspaceSwitcher queryReference={props.queryReference} />}
    </Suspense>
  );
};
