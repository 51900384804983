import { Box, xcss } from '@atlaskit/primitives';
import React from 'react';

import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

const boxBaseStyles = xcss({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const boxBaseStylesWithMargin = xcss({
  marginBlockStart: 'space.400',
});

export const ProjectUpdatesContainer = ({ children }: { children: React.ReactNode }) => {
  // In the future, we should likely control the margin-top globally at the layout level
  // But his would break several other views that already handle margin in them, so applying locally for now
  const isNavRefreshEnabled = useIsNavRefreshEnabled();
  return <Box xcss={[boxBaseStyles, isNavRefreshEnabled && boxBaseStylesWithMargin]}>{children}</Box>;
};
