/**
 * @generated SignedSource<<0ac6c91394d5cfc368d277639ee9ac7d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type PersonSearchResultCard$data = {
  readonly account_id: string | null;
  readonly highResolutionProfilePicture: {
    readonly url: string | null;
  } | null;
  readonly job_title: string | null;
  readonly location: string | null;
  readonly name: string | null;
  readonly reportCounts: {
    readonly directReports: number;
  } | null;
  readonly zoneinfo: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CardTeamsSection">;
  readonly " $fragmentType": "PersonSearchResultCard";
};
export type PersonSearchResultCard$key = {
  readonly " $data"?: PersonSearchResultCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonSearchResultCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "PersonSearchResultCard",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "account_id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "size",
          "value": "MEDIUM"
        }
      ],
      "concreteType": "HighResolutionProfilePicture",
      "kind": "LinkedField",
      "name": "highResolutionProfilePicture",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "url"
        }
      ],
      "storageKey": "highResolutionProfilePicture(size:\"MEDIUM\")"
    },
    {
      "kind": "ScalarField",
      "name": "job_title"
    },
    {
      "concreteType": "ReportCounts",
      "kind": "LinkedField",
      "name": "reportCounts",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "directReports"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "location"
    },
    {
      "kind": "ScalarField",
      "name": "zoneinfo"
    },
    {
      "kind": "FragmentSpread",
      "name": "CardTeamsSection"
    }
  ],
  "type": "CpusSearchUser"
};

(node as any).hash = "5546b73003b5773cb3402a94beae07e3";

export default node;
