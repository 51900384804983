/**
 * @generated SignedSource<<d6f989d4f7a307bafa09c878300836b1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BrowseLocationSection$data = {
  readonly location: {
    readonly " $fragmentSpreads": FragmentRefs<"BrowsePeopleFieldSection">;
  } | null;
  readonly " $fragmentType": "BrowseLocationSection";
};
export type BrowseLocationSection$key = {
  readonly " $data"?: BrowseLocationSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrowseLocationSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "workspaceId"
    }
  ],
  "kind": "Fragment",
  "name": "BrowseLocationSection",
  "selections": [
    {
      "alias": "location",
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Literal",
          "name": "peopleFieldType",
          "value": "LOCATION"
        },
        {
          "kind": "Variable",
          "name": "workspaceId",
          "variableName": "workspaceId"
        }
      ],
      "concreteType": "PeopleFieldAggregateResponse",
      "kind": "LinkedField",
      "name": "peopleFieldAggregates",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "BrowsePeopleFieldSection"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "a7a4647c8a5305595501c3762deeaf34";

export default node;
