/**
 * @generated SignedSource<<a5ef3af4004e86a1004b3bb9f768434f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type StayInLoopPopularProjectsQuery$variables = {
  aaidOrHash: string;
  q: string;
  workspaceUuid?: string | null;
};
export type StayInLoopPopularProjectsQuery$data = {
  readonly projectTql: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly iconUrl: {
          readonly square: {
            readonly dark: string | null;
            readonly light: string | null;
          } | null;
        } | null;
        readonly id: string;
        readonly key: string;
        readonly name: string;
        readonly watcherCount: number;
        readonly watching: boolean;
        readonly " $fragmentSpreads": FragmentRefs<"ProjectFollowButton">;
      } | null;
    } | null> | null;
  } | null;
  readonly reportingLines: {
    readonly __typename: "ReportingLines";
  } | null;
};
export type StayInLoopPopularProjectsQuery = {
  response: StayInLoopPopularProjectsQuery$data;
  variables: StayInLoopPopularProjectsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "aaidOrHash"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "q"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": [
      "WATCHER_COUNT_DESC"
    ]
  },
  {
    "kind": "Variable",
    "name": "workspaceUuid",
    "variableName": "workspaceUuid"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "watching"
},
v7 = {
  "concreteType": "IconURIs",
  "kind": "LinkedField",
  "name": "iconUrl",
  "plural": false,
  "selections": [
    {
      "concreteType": "ThemeURIs",
      "kind": "LinkedField",
      "name": "square",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "light"
        },
        {
          "kind": "ScalarField",
          "name": "dark"
        }
      ]
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "name": "watcherCount"
},
v9 = {
  "kind": "ScalarField",
  "name": "key"
},
v10 = {
  "args": [
    {
      "kind": "Variable",
      "name": "aaidOrHash",
      "variableName": "aaidOrHash"
    }
  ],
  "concreteType": "ReportingLines",
  "kind": "LinkedField",
  "name": "reportingLines",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "StayInLoopPopularProjectsQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "ProjectConnection",
        "kind": "LinkedField",
        "name": "projectTql",
        "plural": false,
        "selections": [
          {
            "concreteType": "ProjectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "ProjectFollowButton"
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      (v10/*: any*/)
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "StayInLoopPopularProjectsQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "ProjectConnection",
        "kind": "LinkedField",
        "name": "projectTql",
        "plural": false,
        "selections": [
          {
            "concreteType": "ProjectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "uuid"
                  },
                  (v6/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      (v10/*: any*/)
    ]
  },
  "params": {
    "cacheID": "1ef4d4a2b9fe48fdd544943e7b8c117d",
    "id": null,
    "metadata": {},
    "name": "StayInLoopPopularProjectsQuery",
    "operationKind": "query",
    "text": "query StayInLoopPopularProjectsQuery(\n  $q: String!\n  $aaidOrHash: String!\n  $workspaceUuid: UUID\n) {\n  projectTql(first: 3, workspaceUuid: $workspaceUuid, q: $q, sort: [WATCHER_COUNT_DESC]) {\n    edges {\n      node {\n        ...ProjectFollowButton\n        id\n        name\n        watching\n        iconUrl {\n          square {\n            light\n            dark\n          }\n        }\n        watcherCount\n        key\n      }\n    }\n  }\n  reportingLines(aaidOrHash: $aaidOrHash) {\n    __typename\n  }\n}\n\nfragment ProjectFollowButton on Project {\n  id\n  uuid\n  watching\n}\n"
  }
};
})();

(node as any).hash = "5ed9fe62a462ff8c763df194197a82d5";

export default node;
