import React from 'react';

import { getStatusColor } from './scoreMap';
import * as styled from './styles';
import { GoalPhase } from './types';
import { fixScore, goalScoreToStatusEntity } from './utils';

type Props = {
  score?: number | null;
  phase?: GoalPhase | null;
};

export const MiniLozenge = (props: Props) => {
  const fixedScore = fixScore(props.score);
  const status = goalScoreToStatusEntity(fixedScore, props.phase);
  return (
    <styled.MiniLozenge palette={getStatusColor(status.name)}>
      {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
      {fixedScore !== null ? fixedScore.toFixed(1) : '-'}
    </styled.MiniLozenge>
  );
};
