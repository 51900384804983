export const FOLLOWERS_ON_PROJECT_EXPERIMENT = 'followers-visible-on-project';

export const ATLAS_INVITING_EXPERIMENT_A = 'atlas_inviting_experiment_a';
export const ATLAS_INVITING_EXPERIMENT_B = 'atlas_inviting_experiment_b';
export const ATLAS_INVITING_EXPERIMENT_C = 'atlas_inviting_experiment_c';
export const ATLAS_JIRA_GET_ATLAS_FREE = 'atlas_jira_get_atlas_free';
export const ATLAS_JIRA_SEARCH_JIRA_ISSSUES = 'atlas_jira_search_jira_issues';

// https://switcheroo.atlassian.com/ui/gates/1705872e-b4eb-4f1d-9c1f-9ac0aca67f3d
export const ENABLE_CREATE_PROJECT_MENU_VISUAL_REFRESH = 'enable_create_project_menu_visual_refresh';

// For experiment details visit go/trailblazerexperiments
export const JPD_ADD_TO_ATLAS_YOUR_WORK_WAC_LINKS = 'atlas_your_work_crossflow_reco_jpd_wac_link';

// Make sure you add your experiment to the list of valid experiments
export const experiments = {
  ATLAS_INVITING_EXPERIMENT_A,
  ATLAS_INVITING_EXPERIMENT_B,
  ATLAS_INVITING_EXPERIMENT_C,
  ATLAS_JIRA_GET_ATLAS_FREE,
  ATLAS_JIRA_SEARCH_JIRA_ISSSUES,
  ENABLE_CREATE_PROJECT_MENU_VISUAL_REFRESH,
  FOLLOWERS_ON_PROJECT_EXPERIMENT,
  JPD_ADD_TO_ATLAS_YOUR_WORK_WAC_LINKS,
} as const;

export type ExperimentKeys = keyof typeof experiments;
export type ExperimentNames = (typeof experiments)[ExperimentKeys];
