import React from 'react';

import { isEmptyDoc } from '@townsquare/adf';
import { NAME_EMBED_SUFFIX } from '@townsquare/config/routes';
import { Context } from '@townsquare/context-identifier';
import { Renderer } from '@townsquare/renderer';

export const setEmbedClickHandler = () => {
  const clickHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // Either returns itself if it's an anchor element, or
    // traverse up its parents until this finds the first anchor element
    const target = e.target.closest('A');

    if (target?.tagName === 'A') {
      window.open(target.getAttribute('href'), '_blank', 'noreferrer');
    }
  };

  document.addEventListener('click', clickHandler);
  return () => document.removeEventListener('click', clickHandler);
};

export const isMissedUpdate = (update: { missedUpdate: boolean; updateType?: 'SYSTEM' | 'USER' }): boolean => {
  return Boolean(update.missedUpdate && update.updateType === 'SYSTEM');
};

export const EMBED_ROUTE_NAMES = {
  label: `label-${NAME_EMBED_SUFFIX}`,
  goalsDirectory: `goal-${NAME_EMBED_SUFFIX}`,
  projectsDirectory: `project-${NAME_EMBED_SUFFIX}`,
  projectEmbed: `project-embed-${NAME_EMBED_SUFFIX}`,
  projectUpdate: `project-update-${NAME_EMBED_SUFFIX}`,
  projectAbout: `project-about-${NAME_EMBED_SUFFIX}`,
  goalUpdate: `goal-update-${NAME_EMBED_SUFFIX}`,
  goalAbout: `goal-about-${NAME_EMBED_SUFFIX}`,
  goalEmbed: `goal-embed-${NAME_EMBED_SUFFIX}`,
  createKudosOld: `create-kudos-old-${NAME_EMBED_SUFFIX}`,
  createKudos: `create-kudos-${NAME_EMBED_SUFFIX}`,
  userProfile: `user-profile-${NAME_EMBED_SUFFIX}`,
  teamProfile: `team-profile-${NAME_EMBED_SUFFIX}`,
  teamHealth: `team-health-${NAME_EMBED_SUFFIX}`,
};

export const renderContent = (adfDoc: string | null, placeHolder: React.ReactNode, context?: Context) => {
  return adfDoc && !isEmptyDoc(adfDoc) ? <Renderer document={adfDoc} context={context} mode="normal" /> : placeHolder;
};

export function whenNotViewReturn<T>(value: T, viewUuid: string | undefined) {
  return viewUuid ? undefined : value;
}
