import { GetValueOptions, SupportedFlagTypes } from '@atlassiansox/feature-flag-web-client';
import { captureException } from '@sentry/react';
import { useLayoutEffect, useMemo, useState } from 'react';

import { AppState, useAppStateStore } from '@townsquare/app-state-store';

import { featureFlagClient } from './client';

const INITILIASED_VALUES = [AppState.INITIALISED_WITHOUT_WORKSPACE, AppState.FULLY_INITIALISED];

export const useFeatureFlag = <T extends SupportedFlagTypes>(
  flagKey: string,
  defaultValue: T,
  options?: GetValueOptions<T>,
) => {
  const [{ appState }] = useAppStateStore();
  const initialValue = useMemo(() => {
    // Feature flag client won't be initialised yet
    if (!INITILIASED_VALUES.includes(appState)) {
      return defaultValue;
    }
    let value = defaultValue;
    try {
      value = featureFlagClient.getFlagValue(flagKey, defaultValue, options);
      return value;
    } catch (e) {
      captureException(e);
      return value;
    }
  }, [flagKey, defaultValue, options, appState]);

  const [flagValue, setFlagValue] = useState(initialValue);

  useLayoutEffect(() => {
    // Feature flag client won't be initialised yet
    if (!INITILIASED_VALUES.includes(appState)) {
      return;
    }
    const initialFlagValue = featureFlagClient.getFlagValue(flagKey, defaultValue, options);
    setFlagValue(initialFlagValue);

    const unsubscribe = featureFlagClient.on(
      flagKey,
      defaultValue,
      newFlagValue => {
        setFlagValue(newFlagValue);
      },
      options,
    );

    return unsubscribe;
    // FIXME: TC-4035
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagKey, appState]);

  return flagValue;
};
