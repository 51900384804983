import {
  ExperiencePerformanceTypes,
  ExperienceSuccess,
  ExperienceTypes,
  UFOExperience,
  useUFOComponentExperience,
} from '@atlassian/ufo';
import { ApolloError } from 'apollo-client';
import React, { useEffect, useMemo } from 'react';
import type { PayloadError } from 'relay-runtime';

import {
  CapabilitySubject,
  configPresets,
  ExperienceConfigPreset,
  type ExperienceTrackerProps,
  type ExperienceFailureProps,
} from './types';

const useUFOExperience = (
  id: CapabilitySubject,
  config: UFOExperience['config'],
  instanceId?: UFOExperience['instanceId'],
): UFOExperience => {
  return useMemo(() => new UFOExperience(id, config, instanceId), [id, config, instanceId]);
};

const useExperienceTracker = (
  id: CapabilitySubject,
  configPreset: ExperienceConfigPreset,
  instanceId?: UFOExperience['instanceId'],
) => {
  const experience = useUFOExperience(id, configPresets[configPreset], instanceId);
  useUFOComponentExperience(experience);
  return experience;
};

const ExperienceTracker = ({ id, loading, error }: ExperienceTrackerProps) => {
  const experience = useExperienceTracker(id, ExperienceConfigPreset.LoadPageLoad);

  if (loading) {
    return null;
  }

  if (error) {
    if ('graphQLErrors' in error) {
      const hasForbidden = error.graphQLErrors.some(fieldError => fieldError.extensions?.statusCode === 403);
      if (hasForbidden) {
        void experience.abort();
        return null;
      }
    }

    void experience.failure({
      metadata: generateErrorMetadata(error),
    });
    return null;
  }

  return <ExperienceSuccess experience={experience} />;
};

const ExperienceFailure = ({ experience, error }: ExperienceFailureProps) => {
  useEffect(() => {
    if (error) {
      void experience.failure({
        metadata: generateErrorMetadata(error),
      });
    }
  }, [error, experience]);

  return null;
};

const isApolloError = (error: ApolloError | Error | PayloadError): error is ApolloError => {
  return 'graphQLErrors' in error || 'networkError' in error;
};

const generateErrorMetadata = (error: ApolloError | Error | PayloadError, componentStack?: string) => {
  let metadata: UFOExperience['metadata'] = {};

  if (isApolloError(error)) {
    metadata = {
      ...error,
      graphQLErrors: error.graphQLErrors.join('\n'),
      networkError: error.networkError?.message,
    };
  } else if (error instanceof Error) {
    metadata = {
      ...error,
    };
  } else {
    metadata = {
      message: error.message,
    };
  }

  if (componentStack) {
    metadata.componentStack = componentStack;
  }

  return metadata;
};

export {
  ExperienceTracker,
  ExperiencePerformanceTypes,
  ExperienceFailure,
  ExperienceSuccess,
  ExperienceTypes,
  generateErrorMetadata,
  UFOExperience,
  useExperienceTracker,
};
