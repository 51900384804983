import type { MatchParams } from 'react-resource-router';

import { useWorkspaceType } from '@townsquare/workspace-store/hooks';

import { ORG_PREFIX, SITE_PREFIX } from '../home/constants';
import { HomepageRouteType } from '../home/types';

import { useRouteDependencies } from './useRouteDependencies';

// The non react hook version of the same logic
export function getRouteParams(
  baseParams: MatchParams | undefined,
  routeType: HomepageRouteType['routeType'] | undefined,
  orgId: string,
  cloudId: string | null | undefined,
  workspaceType: ReturnType<typeof useWorkspaceType> | undefined,
) {
  // If we're on an Atlas classic workspace this hook should no-op.
  if (workspaceType !== 'GLOBAL_EXPERIENCE') {
    return baseParams;
  }

  const params: MatchParams = baseParams ?? {};

  // We always want to add orgIdentifier and orgPrefix to the params
  // for both global and site routes.
  // We check for orgId existence to cater for the use of this route
  // in an Atlas classic workspace where orgId is not available.
  if (orgId) {
    params.orgIdentifier = orgId;
    params.orgPrefix = ORG_PREFIX;
  }

  // If the destination route is a site route, we also want to add
  // sitePrefix and siteIdentifier to the params. These are required
  // params for site routes.
  if (routeType === 'site') {
    params.sitePrefix = SITE_PREFIX;
    params.siteIdentifier = cloudId;
  }

  return params;
}

export function useRouteParams(
  baseParams: MatchParams | undefined,
  routeType: HomepageRouteType['routeType'] | undefined,
) {
  const { orgId, cloudId, workspaceType } = useRouteDependencies();

  return getRouteParams(baseParams, routeType, orgId, cloudId, workspaceType);
}
