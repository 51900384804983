import DepartmentIcon from '@atlaskit/icon/glyph/department';
import React from 'react';
import { IntlShape } from 'react-intl-next';

import { Resolver, SupportedFiltersTypes } from '../../types';
import { isLikeOrFilterComparators } from '../filter-comparators';

import { PeopleFieldType } from './__generated__/createPeopleFieldResolverQuery.graphql';
import { createPeopleFieldSuggestionResolver } from './create-people-field-resolver';
import { PeopleFieldSuggestionResolverOptions } from './types';

const departmentType: PeopleFieldType = 'DEPARTMENT';

export const departmentResolver = (intl: IntlShape, { workspaceId }: PeopleFieldSuggestionResolverOptions): Resolver =>
  createPeopleFieldSuggestionResolver(
    intl.formatMessage({
      id: 'townsquare.packages.tql.resolvers-people-and-teams-department',
      defaultMessage: 'Department',
      description: 'Department title',
    }),
    SupportedFiltersTypes.DEPARTMENT,
    <DepartmentIcon label="" />,
    workspaceId,
    departmentType,
    isLikeOrFilterComparators,
  );
