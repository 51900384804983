import { useQueryParam, useRouter } from 'react-resource-router';

import { useCurrentUser } from '@townsquare/user-store';

import { archivedTql, followingTql, unarchivedTql, yourItemsTql } from '../utils';

import { useDirectoryEntityTypeFromRoute } from './useDirectoryEntityTypeFromRoute';

const DIRECTORY_PARAMS = new Set(['view', 'sort', 'timelineDateSpan']);

export function useIsEditingView() {
  const [{ query }] = useRouter();
  const [qsTql] = useQueryParam('tql');
  const [columns] = useQueryParam('columns');
  const currentUserId = useCurrentUser().accountId;
  const entity = useDirectoryEntityTypeFromRoute() === 'GOAL' ? 'goals' : 'projects';
  const qsParams = Object.keys(query);
  const isEditingColumns = columns !== undefined;
  const isEditingView = qsParams.filter(param => DIRECTORY_PARAMS.has(param)).length > 0;

  const STANDARD_TQLS = (userId: string) => [
    archivedTql,
    unarchivedTql,
    followingTql(userId),
    yourItemsTql(userId, entity),
  ];

  return isEditingView || isEditingColumns || (qsTql && !STANDARD_TQLS(currentUserId).includes(qsTql));
}
