import { RovoChatParams, getRovoParams } from '@atlassian/conversation-assistant-pubsub';
import { AssistanceServiceImpl } from '@atlassian/conversation-assistant-service';
import { useMemo } from 'react';

import { useWorkspaceStore } from '@townsquare/workspace-store';

export const ROVO_PRODUCT_HEADER = 'rovo';
export const ROVO_EXPERIENCE_ID_HEADER = 'ai-mate';

export const getCloudIdForRovo = <T>(rovoParams: RovoChatParams, workspaceCloudId: T) =>
  rovoParams?.cloudId ?? workspaceCloudId;

export const useRovoParamsAndAssistanceService = () => {
  const rovoParams = useMemo(() => {
    const params = getRovoParams();
    return params;
  }, []);

  const [{ cloudId: workspaceCloudId }] = useWorkspaceStore();
  const cloudId = getCloudIdForRovo(rovoParams, workspaceCloudId);
  const assistanceService = useMemo(
    () =>
      new AssistanceServiceImpl({
        experienceId: ROVO_EXPERIENCE_ID_HEADER,
        product: ROVO_PRODUCT_HEADER,
        headers: {
          'X-Cloudid': cloudId,
        },
      }),
    [cloudId],
  );

  return {
    assistanceService,
    cloudId,
    rovoParams,
  };
};
