import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import UserProfileEmbedQuery, {
  type UserProfileEmbedQuery as UserProfileEmbedQueryType,
} from '../components/Profile/UserEmbed/__generated__/UserProfileEmbedQuery.graphql';

export const userProfileEmbedResource = createRelayResource<UserProfileEmbedQueryType>({
  getQuery: (_routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const accountId = _routerContext.match.params.userAaid
      ? decodeURIComponent(_routerContext.match.params.userAaid)
      : '';
    const currentGoalQuery = `owner = ${accountId} and (phase = in_progress or phase = pending) and archived = false`;
    const currentProjectQuery = `(contributor = ${accountId}) and (phase = in_progress or phase = pending) and archived = false`;

    return {
      query: UserProfileEmbedQuery,
      variables: {
        accountId: accountId,
        currentGoalQuery: currentGoalQuery,
        currentProjectQuery: currentProjectQuery,
        workspaceId: context.workspace.globalId,
        workspaceUuid: context.workspace.UUID || '',
        first: 5,
      },
    };
  },
});
