import InlineDialog from '@atlaskit/inline-dialog';
import { SimpleTag } from '@atlaskit/tag';
import { TooltipProps } from '@atlaskit/tooltip';
import React, { KeyboardEvent, MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { teamProfileRoute } from '@townsquare/ptc-directory-view';
import { ReactSetStateFn } from '@townsquare/react-state';
import { Link } from '@townsquare/router/primitives';
import { TeamIcon } from '@townsquare/team-icon';

import { StyledTeamDropdown, StyledTeamDropdownItem, StyledTeamTag, TeamIconContainer } from './styles';
import { SearchResultUserTeam } from './types';

export interface TeamTagProps {
  name: string;
  teamId: string;
  tooltipPosition?: TooltipProps['position'];
  setVisibleTeams?: ReactSetStateFn<Set<string>>;
}

export const TeamTag = ({ name, teamId, tooltipPosition, setVisibleTeams }: TeamTagProps) => {
  const analytics = useAnalytics();
  const onClick = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation();
    void analytics.ui('searchResultPersonTeamTag', 'clicked');
  };

  const tagRef: RefObject<HTMLDivElement> = useRef(null);

  const visibleCallback = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      setVisibleTeams?.(visibleTeams => {
        if (entry.isIntersecting) {
          return new Set(visibleTeams.add(teamId));
        } else {
          if (visibleTeams.delete(teamId)) {
            return new Set(visibleTeams);
          }

          return visibleTeams;
        }
      });
    },
    [setVisibleTeams, teamId],
  );

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(visibleCallback);

    if (tagRef.current) {
      intersectionObserver.observe(tagRef.current);
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, [tagRef, visibleCallback]);

  return (
    <EllipsisTooltip content={name} position={tooltipPosition}>
      <StyledTeamTag innerRef={tagRef}>
        <SimpleTag
          linkComponent={({ className, children, href }: any) => {
            const props = { className, href };
            return (
              <Link to={teamProfileRoute} params={{ id: teamId }} onClick={onClick} {...props}>
                {children}
              </Link>
            );
          }}
          text={name}
          color="standard"
          elemBefore={
            <TeamIconContainer>
              <TeamIcon teamName={name} size="xsmall" />
            </TeamIconContainer>
          }
        />
      </StyledTeamTag>
    </EllipsisTooltip>
  );
};

export interface TeamTagDropdownProps {
  teams: SearchResultUserTeam[];
}

export const TeamTagDropdown = ({ teams }: TeamTagDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <StyledTeamDropdown>
      <InlineDialog
        content={
          <div
            onClick={(e: MouseEvent) => {
              // prevent opening profile by clicking in dialog
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {teams.map(({ name, teamId }: SearchResultUserTeam) => (
              <StyledTeamDropdownItem key={teamId}>
                <TeamTag name={name} teamId={teamId} tooltipPosition="right" />
              </StyledTeamDropdownItem>
            ))}
          </div>
        }
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <StyledTeamTag>
          <SimpleTag
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            text={`+ ${teams.length}`}
            color="standard"
            linkComponent={({ className, children }: any) => {
              const props = { className };
              return (
                <div
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    e.preventDefault();

                    setIsOpen(prev => !prev);
                  }}
                  onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.stopPropagation();
                      e.preventDefault();
                      setIsOpen(prev => !prev);
                    }
                  }}
                  tabIndex={0}
                  {...props}
                >
                  {children}
                </div>
              );
            }}
            href="#" // ignored in linkComponent, used to give link style
          />
        </StyledTeamTag>
      </InlineDialog>
    </StyledTeamDropdown>
  );
};
