import Flag, { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import InfoIcon from '@atlaskit/icon/glyph/info';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { token } from '@atlaskit/tokens';
import React from 'react';

import { FlagType, InternalFlag, useFlagsStore } from '../store';

const getTypeIcon = (type: FlagType, isBold = false) => {
  switch (type) {
    case 'error':
      return (
        <ErrorIcon
          label=""
          {...(isBold
            ? { secondaryColor: token('color.background.danger.bold') }
            : { primaryColor: token('color.icon.danger') })}
        />
      );

    case 'warning':
      return (
        <WarningIcon
          label=""
          {...(isBold
            ? { secondaryColor: token('color.background.warning.bold') }
            : { primaryColor: token('color.icon.warning') })}
        />
      );

    case 'info':
      return (
        <InfoIcon
          label=""
          {...(isBold
            ? { secondaryColor: token('color.background.information.bold') }
            : { primaryColor: token('color.icon.information') })}
        />
      );

    default:
      return (
        <SuccessIcon
          label=""
          {...(isBold
            ? { secondaryColor: token('color.background.success.bold') }
            : { primaryColor: token('color.icon.success') })}
        />
      );
  }
};

export const Flags = () => {
  const [flags, uiApi] = useFlagsStore();

  const renderedFlags = flags.map((flagProps: InternalFlag) => {
    const FlagComponent = flagProps.shouldAutoDismiss ? AutoDismissFlag : Flag;
    return (
      <FlagComponent
        appearance={flagProps.isBold ? flagProps.type : 'normal'}
        key={flagProps.id}
        icon={getTypeIcon(flagProps.type, flagProps.isBold)}
        {...flagProps}
      />
    );
  });

  return <FlagGroup onDismissed={id => uiApi.removeFlag(id as string)}>{renderedFlags}</FlagGroup>;
};
