import { createDiscoverySdk } from '@atlassian/discovery-sdk';
import { RemoteComponent } from '@atlassian/remote-module-loader/remote-component';
import AtlassianSwitcher from '@atlassian/switcher';
import { Journeys, TargetType, useCrossFlow } from '@atlassiansox/cross-flow-support';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import { useAtlasKitAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { PRODUCT_IDENTIFIER } from '@townsquare/config/constants';
import { Placement } from '@townsquare/post-office';
import { useFeatureGate } from '@townsquare/stat-sig/gate';
import { useThemeStore } from '@townsquare/theme';
import { useNoWorkspaceExperience, useWorkspaceStore } from '@townsquare/workspace-store';

import { remoteSwitcherPlugin } from '../../appSwitcherPlugin';

import { ContentContainer } from './styles';

const config = getConfig();

const crossFlowDisabledProps = { isDiscoverMoreForEveryoneEnabled: false, isDiscoverSectionEnabled: false };

type SwitcherComponentProps = React.ComponentProps<typeof AtlassianSwitcher>;

export const { DiscoverySurface, useDiscoverySdk } = createDiscoverySdk({
  context: {
    product: 'atlas',
  },
});

export const { fireEventOnce } = (() => {
  let hasFired = false;

  return {
    fireEventOnce: (fireFunction: () => void) => {
      if (!hasFired) {
        fireFunction();
        hasFired = true;
      }
    },
  };
})();

export const useDiscoverySDKCohort = (intl: { locale: string }) => {
  const { locale } = intl;
  const isEnglishLocale = locale.startsWith('en');
  if (isEnglishLocale) {
    return {
      isEnabled: true,
      locale,
      ffEnableOverFiftyUsers: true,
    };
  } else {
    return {
      isEnabled: false,
      locale,
      ffAllLocales: false,
    };
  }
};

export const useDiscoverySdkIfEnabled = (
  intl: {
    locale: string;
  },
  cloudId: string,
): {
  preloadSwitcherSurface: () => void;
  discoverySurface: any | undefined;
  ffEnableOverFiftyUsers: boolean | undefined;
  ffAllLocales: boolean | undefined;
} => {
  const { isEnabled, ffEnableOverFiftyUsers, ffAllLocales } = useDiscoverySDKCohort({ locale: intl.locale });
  const { setContext } = useDiscoverySdk();
  const isNoWorkspaceExperience = useNoWorkspaceExperience();

  return {
    preloadSwitcherSurface: () =>
      setContext(c => ({
        ...c,
        locale: intl.locale,
        tenantId: cloudId,
        switcherMenuHasBeenOpened: isEnabled,
        /**
         * Setting this is a bit of a hack to avoid an access check which requires
         * a cloudId to be set, which we don't have in the no workspace experience.
         */
        subproduct: isNoWorkspaceExperience ? 'no-tenant' : 'tenant',
      })),
    discoverySurface: isEnabled ? DiscoverySurface : undefined,
    ffEnableOverFiftyUsers,
    ffAllLocales,
  };
};

export const AppSwitcherContent = () => {
  const crossFlow = useCrossFlow();
  const [workspace] = useWorkspaceStore();

  const [theme] = useThemeStore();

  const crossFlowEnabledProps = useMemo(
    () => ({
      isDiscoverMoreForEveryoneEnabled: true,
      isDiscoverSectionEnabled: true,
      onDiscoverMoreClicked: () => {
        if (crossFlow.isEnabled) {
          void crossFlow.api.open({
            journey: Journeys.DISCOVER,
            sourceComponent: 'atlassian-switcher',
            sourceContext: 'more',
            experimentalOptions: {
              theme,
            },
          });
        }
      },
      // called on product item click in Discover/Try section
      triggerXFlow: (productKey: string) => {
        if (crossFlow.isEnabled) {
          void crossFlow.api.open({
            journey: Journeys.DECIDE,
            sourceComponent: 'atlassian-switcher',
            sourceContext: 'try',
            targetProduct: productKey as TargetType,
            experimentalOptions: {
              theme,
            },
          });
        }
      },
    }),
    [crossFlow, theme],
  );

  const crossFlowProps = crossFlow.isEnabled ? crossFlowEnabledProps : crossFlowDisabledProps;

  const { locale } = useIntl();
  const { discoverySurface, ffEnableOverFiftyUsers, ffAllLocales } = useDiscoverySdkIfEnabled(
    { locale },
    workspace.cloudId,
  );
  const { sendUIEvent } = useAtlasKitAnalytics();
  fireEventOnce(() =>
    sendUIEvent({
      action: 'viewed',
      actionSubject: 'section',
      actionSubjectId: 'switcherDiscoveryExposure',
      source: 'atlassianSwitcher',
      attributes: {
        locale,
        featureFlags: {
          ffEnableOverFiftyUsers,
          ffAllLocales,
        },
      },
    }),
  );

  const isPostOfficeEnabled = useFeatureGate('post_office_in_atlas_switcher');

  const switcherProps = useMemo<SwitcherComponentProps>(() => {
    return {
      appearance: 'standalone',
      product: PRODUCT_IDENTIFIER,
      disableCustomLinks: true,
      discoverySurface: discoverySurface,
      discoverySection: isPostOfficeEnabled ? <Placement placementId="app-switcher-discovery-section" /> : undefined,
      adminUrl: `${config.adminHubUrl}`,
      cloudId: workspace.cloudId,
      ...crossFlowProps,
    };
  }, [crossFlowProps, workspace.cloudId, discoverySurface, isPostOfficeEnabled]);

  return (
    <ContentContainer>
      <RemoteComponent<SwitcherComponentProps>
        remotePlugin={remoteSwitcherPlugin}
        analyticsChannel="navigation"
        ErrorFallback={<AtlassianSwitcher {...switcherProps} />}
        // switcher props
        {...switcherProps}
      />
    </ContentContainer>
  );
};
