import { useQueryParam } from 'react-resource-router';

export const ALL = 'all';
export const ARCHIVED = 'archived';
export const YOUR = 'your';
export const FOLLOWING = 'following';

export type ViewTab = typeof ALL | typeof ARCHIVED | typeof YOUR | typeof FOLLOWING;

export function useSelectedView() {
  const [viewUuid] = useQueryParam('viewUuid');
  const [viewTab] = useQueryParam('viewTab');

  const getSelectedView = () => {
    if (viewUuid) {
      return viewUuid;
    } else if (viewTab) {
      return viewTab;
    } else {
      // fallback in case we can't find a selected tabView
      return ALL;
    }
  };

  const selectedView = getSelectedView();

  return (view: string | null | undefined) => {
    return view === selectedView;
  };
}
