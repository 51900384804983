/**
 * @generated SignedSource<<e31b4dbb599053bee2015b9194fd0dc3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WorkspaceContextType = "Default" | "DirectoryViewUuid" | "GoalKey" | "ProjectKey" | "TagId" | "TagUuid" | "WorkspaceCloudId" | "WorkspaceIdentifier" | "WorkspaceInOrgByCloudId";
export type WorkspaceContextInput = {
  contextType: WorkspaceContextType;
  identifier?: string | null;
};
export type CreateProjectQuery$variables = {
  workspaceContext?: WorkspaceContextInput | null;
};
export type CreateProjectQuery$data = {
  readonly workspaceByContext: {
    readonly workspace?: {
      readonly site: {
        readonly name: string | null;
        readonly productActivations: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly __typename: "JiraProductActivation";
              readonly atlasForJiraCloudEnabled: boolean | null;
            } | {
              // This will never be '%other', but we need some
              // value in case none of the concrete values match.
              readonly __typename: "%other";
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type CreateProjectQuery = {
  response: CreateProjectQuery$data;
  variables: CreateProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceContext"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "workspaceContext",
    "variableName": "workspaceContext"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "concreteType": "ProductActivationConnection",
      "kind": "LinkedField",
      "name": "productActivations",
      "plural": false,
      "selections": [
        {
          "concreteType": "ProductActivationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "atlasForJiraCloudEnabled"
                    }
                  ],
                  "type": "JiraProductActivation"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "CreateProjectQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "workspaceByContext",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "Workspace",
                "kind": "LinkedField",
                "name": "workspace",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ],
            "type": "WorkspaceByContextSuccessfulResponse"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateProjectQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "workspaceByContext",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "Workspace",
                "kind": "LinkedField",
                "name": "workspace",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ],
            "type": "WorkspaceByContextSuccessfulResponse"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a3bda38863012bfb26a17d20a0f7a0c3",
    "id": null,
    "metadata": {},
    "name": "CreateProjectQuery",
    "operationKind": "query",
    "text": "query CreateProjectQuery(\n  $workspaceContext: WorkspaceContextInput\n) {\n  workspaceByContext(workspaceContext: $workspaceContext) {\n    __typename\n    ... on WorkspaceByContextSuccessfulResponse {\n      workspace {\n        site {\n          name\n          productActivations {\n            edges {\n              node {\n                __typename\n                ... on JiraProductActivation {\n                  atlasForJiraCloudEnabled\n                }\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a6e445289d5e8f6553897b6d2bbcdf9";

export default node;
