import { v4 as uuidv4 } from 'uuid';

import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import DirectoryActiveViewQuery, {
  DirectoryActiveViewQuery as DirectoryActiveViewQueryType,
} from '../components/__generated__/DirectoryActiveViewQuery.graphql';

// This never makes it to our backend - we have a skip setup on directoryViewUuidPresent.
// Required so graph doesn't complain though.
const dummyUuid = uuidv4().toString();

export const directoryActiveViewResource = createRelayResource<DirectoryActiveViewQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const { workspace } = resourceContext as ResourceContext;

    return {
      query: DirectoryActiveViewQuery,
      variables: {
        workspaceUuid: workspace.UUID ?? '',
        directoryViewUuid: routerContext.query.viewUuid || dummyUuid,
        isDirectoryViewUuidPresent: !!routerContext.query.viewUuid,
      },
    };
  },
});
