/**
 * @generated SignedSource<<5be7387241a912c0c504f1eccc9239fb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OnboardingItemKey = "ADD_PEOPLE_BANNER" | "DISCOVER_INTEGRATIONS" | "EXPLORE_DIRECTORY_BANNER" | "GLOBAL_HOME" | "GOALS_ABOUT_TAB_BANNER" | "GOALS_DIRECTORY" | "GOALS_DIRECTORY_BANNER" | "GOALS_METRICS_POPUP" | "GOALS_UPDATES_BANNER" | "GOALS_UPDATES_TAB_BANNER" | "GOAL_LEARNINGS_BANNER" | "LINKED_GOALS_IN_JIRA_BANNER" | "NO_WORKSPACE_BENEFITS_DIALOG" | "PASTE_WORK_TRACKING_JIRA_LINK_BANNER" | "PRODUCT_OVERVIEW" | "PROJECT_ABOUT_TAB_BANNER" | "PROJECT_DIRECTORY" | "PROJECT_DIRECTORY_BANNER" | "PROJECT_LEARNINGS_BANNER" | "PROJECT_SPOTLIGHT" | "PROJECT_UPDATES_TAB_BANNER" | "PROJECT_UPDATE_BANNER" | "QUESTIONS_FEED_BANNER" | "READING_MODE_FEEDBACK_COLLECTION" | "STAFF_DIRECTORY_UPLOAD_HIGH_RES_PIC" | "SYNC_WITH_JIRA_BANNER" | "TOPICS_DIRECTORY" | "WRITING_MODE_FEEDBACK_COLLECTION";
export type saveUserOnboardingInput = {
  clientMutationId?: string | null;
  onboardingKey?: OnboardingItemKey | null;
};
export type SaveOnboardingMutation$variables = {
  connections: ReadonlyArray<string>;
  input: saveUserOnboardingInput;
};
export type SaveOnboardingMutation$data = {
  readonly saveUserOnboarding: {
    readonly result: {
      readonly completionDate: string | null;
      readonly key: OnboardingItemKey | null;
    } | null;
  } | null;
};
export type SaveOnboardingMutation = {
  response: SaveOnboardingMutation$data;
  variables: SaveOnboardingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "key"
},
v4 = {
  "kind": "ScalarField",
  "name": "completionDate"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "SaveOnboardingMutation",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "saveUserOnboardingPayload",
        "kind": "LinkedField",
        "name": "saveUserOnboarding",
        "plural": false,
        "selections": [
          {
            "concreteType": "Onboarding",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SaveOnboardingMutation",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "saveUserOnboardingPayload",
        "kind": "LinkedField",
        "name": "saveUserOnboarding",
        "plural": false,
        "selections": [
          {
            "concreteType": "Onboarding",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          },
          {
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "result",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "OnboardingEdge"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b97138ce262c6a979baafe16b4949e80",
    "id": null,
    "metadata": {},
    "name": "SaveOnboardingMutation",
    "operationKind": "mutation",
    "text": "mutation SaveOnboardingMutation(\n  $input: saveUserOnboardingInput!\n) {\n  saveUserOnboarding(input: $input) {\n    result {\n      key\n      completionDate\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a31f770164464c3256371acb7621d8ae";

export default node;
