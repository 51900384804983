/**
 * @generated SignedSource<<8da3c3f7ebd4ef331587ce142f21c8d6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type updateDecisionInput = {
  clientMutationId?: string | null;
  description?: string | null;
  id: string;
  summary?: string | null;
};
export type UpdateDecisionMutation$variables = {
  input: updateDecisionInput;
};
export type UpdateDecisionMutation$data = {
  readonly updateDecision: {
    readonly decision: {
      readonly description: string | null;
      readonly id: string;
      readonly lastEditedBy: {
        readonly pii: {
          readonly name: string | null;
          readonly picture: string | null;
        } | null;
      } | null;
      readonly lastEditedDate: string | null;
      readonly summary: string | null;
    } | null;
  } | null;
};
export type UpdateDecisionMutation = {
  response: UpdateDecisionMutation$data;
  variables: UpdateDecisionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "summary"
},
v4 = {
  "kind": "ScalarField",
  "name": "description"
},
v5 = {
  "concreteType": "UserPII",
  "kind": "LinkedField",
  "name": "pii",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "picture"
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "lastEditedDate"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "UpdateDecisionMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "updateDecisionPayload",
        "kind": "LinkedField",
        "name": "updateDecision",
        "plural": false,
        "selections": [
          {
            "concreteType": "Decision",
            "kind": "LinkedField",
            "name": "decision",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "lastEditedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDecisionMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "updateDecisionPayload",
        "kind": "LinkedField",
        "name": "updateDecision",
        "plural": false,
        "selections": [
          {
            "concreteType": "Decision",
            "kind": "LinkedField",
            "name": "decision",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "lastEditedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "4e74b0e9e5c79de873e0dfb721b857aa",
    "id": null,
    "metadata": {},
    "name": "UpdateDecisionMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDecisionMutation(\n  $input: updateDecisionInput!\n) {\n  updateDecision(input: $input) {\n    decision {\n      id\n      summary\n      description\n      lastEditedBy {\n        pii {\n          name\n          picture\n        }\n        id\n      }\n      lastEditedDate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e2f232ea440a19247dcb2703000ca8b";

export default node;
