/**
 * @generated SignedSource<<3368166d4672283d03319f819b35ebda>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LearningType = "DECISION" | "LEARNING" | "RISK";
import type { FragmentRefs } from "relay-runtime";
export type LearningsList$data = {
  readonly learnings: {
    readonly __id: string;
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly type: LearningType | null;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"Learning_data">;
      } | null;
    } | null> | null;
  } | null;
  readonly tags: {
    readonly " $fragmentSpreads": FragmentRefs<"LearningTags_data">;
  } | null;
  readonly watcherCount: number;
  readonly " $fragmentType": "LearningsList";
};
export type LearningsList$key = {
  readonly " $data"?: LearningsList$data;
  readonly " $fragmentSpreads": FragmentRefs<"LearningsList">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [
        "SUMMARY_ASC"
      ],
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "learnings"
        ]
      }
    ]
  },
  "name": "LearningsList",
  "selections": [
    {
      "alias": "learnings",
      "args": [
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "type"
        }
      ],
      "concreteType": "LearningConnection",
      "kind": "LinkedField",
      "name": "__LearningsList__learnings_connection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "count"
        },
        {
          "concreteType": "LearningEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "Learning",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "kind": "ScalarField",
                  "name": "uuid"
                },
                {
                  "kind": "ScalarField",
                  "name": "type"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "Learning_data"
                    }
                  ],
                  "type": "Highlight",
                  "abstractKey": "__isHighlight"
                },
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            },
            {
              "kind": "ScalarField",
              "name": "cursor"
            }
          ]
        },
        {
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "endCursor"
            },
            {
              "kind": "ScalarField",
              "name": "hasNextPage"
            }
          ]
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__id"
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "watcherCount"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "tagCount"
        }
      ],
      "concreteType": "TagConnection",
      "kind": "LinkedField",
      "name": "tags",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "LearningTags_data"
        }
      ]
    }
  ],
  "type": "Project"
};

(node as any).hash = "0f413a8c767958c7610de1cbb9960d05";

export default node;
