import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import React, { Ref } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { useDirectoryEntityTypeFromRoute } from '@townsquare/directory-header/hooks';
import { useDirectoryViewStore } from '@townsquare/directory-view-store';
import { useModalActions } from '@townsquare/modals';

import { messages } from '../messages';

export const SaveViewMoreActions = () => {
  const { openModal } = useModalActions();
  const [directoryView] = useDirectoryViewStore();
  const entityType = useDirectoryEntityTypeFromRoute();
  const intl = useIntl();

  const openSaveModal = () => {
    openModal('create-directory-view-modal', {
      entityType,
      isSavingCopy: true,
      namePlaceholder: intl.formatMessage(messages.saveAsCopyFieldPlaceholder, {
        name: directoryView.name,
      }),
    });
  };

  return (
    <DropdownMenu
      trigger={({ triggerRef, ...providedProps }) => (
        <Button
          appearance="primary"
          iconAfter={
            <ChevronDownIcon
              label={intl.formatMessage({
                id: 'townsquare.directory-actions.save-view-more-actions.icon',
                description: 'Icon label for more actions',
                defaultMessage: 'More actions',
              })}
            />
          }
          {...providedProps}
          ref={triggerRef as Ref<HTMLButtonElement>}
        />
      )}
      placement="bottom-end"
    >
      <DropdownItemGroup>
        <DropdownItem onClick={openSaveModal}>
          <FormattedMessage {...messages.SaveAsCopyButton} />
        </DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
  );
};
