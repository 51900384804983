import { useEffect } from 'react';

import { ATLAS_LOCALE_OVERRIDE, useFeatureFlag } from '@townsquare/feature-flags';
import { useUserStore } from '@townsquare/user-store';

export const UserLocaleOverrideHandler = () => {
  const localeOverride = useFeatureFlag(ATLAS_LOCALE_OVERRIDE, '');
  const [{ locale }, { setUserLocale }] = useUserStore();

  useEffect(() => {
    if (localeOverride && localeOverride !== locale) {
      void setUserLocale(localeOverride);
    }
  }, [locale, localeOverride, setUserLocale]);

  return null;
};
