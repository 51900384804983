import { PrimaryButton, PrimaryDropdownButton, useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { ButtonProps } from '@atlaskit/button';
import { CustomItem } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import React, { forwardRef, KeyboardEvent, MouseEvent, MutableRefObject, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { goalViewRoute } from '@townsquare/goal-view/route';
import { goalDirectoryRoute } from '@townsquare/goals-directory-view/route';
import { NavIconMarginFix } from '@townsquare/icons/margin-fix';
import { useModalActions } from '@townsquare/modals';
import { Link, matchRoute, useGeneratePath } from '@townsquare/router/primitives';

import { GoalMenuWrapper as GoalMenu } from './GoalMenu';
import { GoalButtonsContainer, IsHighlightedBar } from './styles';
import { onItemClickContextOptions } from './types';

export const GoalButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const analytics = useAnalytics();
  const [routerState] = useRouter();
  const path = useGeneratePath(goalDirectoryRoute);

  const { openModal } = useModalActions();
  const { isVisible: isHorizontalVisible, closeOverflowMenu } = useOverflowStatus();

  const onClose = () => {
    setIsOpen(false);
  };

  const onClick = () => {
    void analytics.ui('goalsNavButton', 'clicked');
    onClose();
  };

  const createOnDropdownClick =
    (preventBubbling = false) =>
    (e: MouseEvent) => {
      if (preventBubbling) {
        e.stopPropagation();
        e.preventDefault();
      }

      void analytics.ui('goalsNavDropdown', 'clicked');
      setIsOpen(currentIsOpen => !currentIsOpen);
    };
  const onDropdownClick = createOnDropdownClick();
  const onDropdownClickNoBubbling = createOnDropdownClick(true);

  const onItemClick = (ctx: onItemClickContextOptions, e?: MouseEvent | KeyboardEvent) => {
    void analytics.ui('goalsNavMenuItem', 'clicked', {
      ctx,
    });
    if (!e?.metaKey) {
      setIsOpen(false);
      closeOverflowMenu();
    }
    if (ctx === 'openGoalModal') {
      openModal('create-goal-modal', {
        redirectOnSave: true,
      });
    }
  };

  const match = matchRoute([goalDirectoryRoute, goalViewRoute], routerState.location.pathname, undefined);
  const isGoalsPath = Boolean(match);

  const RouterLink = forwardRef<HTMLAnchorElement | null, ButtonProps>(function Component({ href = '', ...rest }, ref) {
    // @ts-expect-error - ref is not a public prop
    return <Link {...rest} key={href.substr(1)} prefetch="hover" to={goalDirectoryRoute} ref={ref} />;
  });

  return (
    <Popup
      placement="bottom-start"
      offset={[0, isHorizontalVisible ? -8 : 0]}
      content={() => <GoalMenu onItemClick={onItemClick} shortView={!isHorizontalVisible} />}
      isOpen={isOpen}
      onClose={onClose}
      trigger={({ ref, ...otherTriggerProps }) => {
        return isHorizontalVisible ? (
          <GoalButtonsContainer
            innerRef={ref as MutableRefObject<HTMLDivElement>}
            isOpen={isOpen}
            isGoalsPath={isGoalsPath}
          >
            <PrimaryButton href={path} onClick={onClick} component={RouterLink} id="goals-button" testId="goals-button">
              <FormattedMessage
                id="townsquare.navigation-goals.goal-button.primary-button"
                description="Goals navigation primary button"
                defaultMessage="Goals"
              />
            </PrimaryButton>
            <NavIconMarginFix>
              <PrimaryDropdownButton
                {...otherTriggerProps}
                id="goals-dropdown-button"
                testId="goals-dropdown-button"
                onClick={onDropdownClick}
              />
            </NavIconMarginFix>
            {isGoalsPath && <IsHighlightedBar />}
          </GoalButtonsContainer>
        ) : (
          <CustomItem
            component={RouterLink}
            href={path}
            onClick={() => closeOverflowMenu()}
            iconAfter={
              <NavIconMarginFix>
                <PrimaryDropdownButton
                  {...otherTriggerProps}
                  id="goals-dropdown-button"
                  testId="goals-dropdown-button"
                  onClick={onDropdownClickNoBubbling}
                  ref={ref}
                />
              </NavIconMarginFix>
            }
            // eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
            cssFn={() => {
              return {
                padding: `${token('space.050')} ${token('space.200')}`,
              };
            }}
          >
            <FormattedMessage
              id="townsquare.navigation-goals.goal-button.content"
              description="Goals navigation primary button"
              defaultMessage="Goals"
            />
          </CustomItem>
        );
      }}
      testId="goals-menu"
    />
  );
};
