import styled from 'styled-components';

import { Size, SizeMap } from './types';

const sizeMap: SizeMap = {
  xxlarge: {
    iconSize: 96,
    borderRadius: 8,
  },
  xlarge: {
    iconSize: 48,
    borderRadius: 3,
  },
  large: {
    iconSize: 40,
    borderRadius: 3,
  },
  medium: {
    iconSize: 32,
    borderRadius: 3,
  },
  small: {
    iconSize: 24,
    borderRadius: 3,
  },
  xsmall: {
    iconSize: 20,
    borderRadius: 3,
  },
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const Icon = styled.div<{ color: string; size: Size }>(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  background: props.color,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: `${sizeMap[props.size].iconSize}px`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  height: `${sizeMap[props.size].iconSize}px`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: `${sizeMap[props.size].borderRadius}px`,
}));
