import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator as CO } from '@townsquare/tql/types';
import type { User } from '@townsquare/user-store';

import DirectoryFavoriteViewsQuery, {
  DirectoryFavoriteViewsQuery as DirectoryFavoriteViewsQueryType,
  DirectoryViewSortEnum,
} from '../components/__generated__/DirectoryFavoriteViewsQuery.graphql';
import { DirectoryViewEntityType } from '../components/__generated__/DirectoryManageViewDialog.graphql';

export const FAVORITE_VIEWS_DEFAULT_SORT: [DirectoryViewSortEnum] = ['CREATION_DATE_DESC'];
export const FAVORITE_VIEWS_FAVOURITED_SORT: [DirectoryViewSortEnum] = ['FAVOURITED_DATE_DESC'];
export const FAVORITE_VIEWS_COUNT = 4;

export const favoriteViewsTqlQuery = (user: User, entityType: DirectoryViewEntityType) =>
  tqlQueryFromInput({
    input: [
      { fieldName: 'favouritedBy', fieldValue: user.accountId, comparator: CO.EQUALS },
      { fieldName: 'entityType', fieldValue: entityType, comparator: CO.EQUALS },
    ],
  });

export const directoryFavoriteViewsResource = createRelayResource<DirectoryFavoriteViewsQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const { user, workspace } = resourceContext as ResourceContext;
    const entity = routerContext.route.name === 'channelList' ? 'PROJECT' : 'GOAL';

    const tqlQuery = favoriteViewsTqlQuery(user, entity);

    return {
      query: DirectoryFavoriteViewsQuery,
      variables: {
        q: tqlQuery,
        first: FAVORITE_VIEWS_COUNT,
        workspaceUuid: workspace.UUID ?? '',
        sort: FAVORITE_VIEWS_FAVOURITED_SORT,
      },
    };
  },
});
