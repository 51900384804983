import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useQueryParam, useRouter, useRouterActions } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { ARCHIVED, archivedTql, useIsEditingView, useSelectedView } from '@townsquare/directory-header/hooks';

import { ResetButton } from './ResetButton';

export const ResetView = () => {
  const { push } = useRouterActions();
  const analytics = useAnalytics();
  const [viewUuid] = useQueryParam('viewUuid');
  const isEditingView = useIsEditingView();
  const [{ location, route }] = useRouter();
  const isViewSelected = useSelectedView();

  if (!isEditingView) {
    return null;
  }

  const resetPathParams = (viewUuid: string | undefined) => {
    if (viewUuid) {
      return `?viewUuid=${viewUuid}`;
    } else if (isViewSelected(ARCHIVED)) {
      return `?tql=${archivedTql}`;
    } else {
      return '';
    }
  };

  const resetView = () => {
    const resetPath = `${location.pathname}${resetPathParams(viewUuid)}`;
    void push(resetPath);
    void analytics.ui('directoryViewReset', 'clicked', {
      isResettingSavedView: !!viewUuid,
      screen: route.name,
    });
  };

  return <ResetButton onClick={resetView} />;
};
