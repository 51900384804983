import AtlasProvider from '@atlassiansox/cross-flow-support/atlas';
import React, { ComponentType, useEffect, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { useAtlasKitAnalytics } from '@townsquare/analytics';
import { resolvedAvailableSitesProvider } from '@townsquare/available-sites-provider';
import { useWorkspaceCloudId } from '@townsquare/workspace-store';

import { getUsersPlugin } from './utils/get-users';

const CrossFlowProvider: ComponentType = props => {
  const { children } = props;
  const { locale } = useIntl();
  const [cloudId, setCloudId] = useState<string | undefined>();
  const akAnalytics = useAtlasKitAnalytics();
  const [workspaceCloudId] = useWorkspaceCloudId();
  useEffect(() => {
    setCloudId(workspaceCloudId);
  }, [workspaceCloudId]);

  useEffect(() => {
    if (!cloudId) {
      // If the user doesn't have an active workspace, but is on a single tenant site, we can use the tenantId as the cloudId
      resolvedAvailableSitesProvider.tenantIdIfSingleTenant().then(tenantId => {
        if (tenantId && !cloudId) {
          setCloudId(tenantId);
        }
      });
    }
  }, [cloudId]);

  return (
    <AtlasProvider analyticsClient={akAnalytics} locale={locale} plugins={[getUsersPlugin]} cloudId={cloudId}>
      {children}
    </AtlasProvider>
  );
};

export default CrossFlowProvider;
