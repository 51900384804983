import { UFOExperience } from '@atlassian/ufo';
import { captureException } from '@sentry/react';
import React, { useCallback } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { FullPageError, ForbiddenContentError, FourOhFourError, ErrorContainer } from '@townsquare/error-state';
import { ForbiddenError, NotFound } from '@townsquare/error-state/classes';
import { ResponsivePage } from '@townsquare/responsive-grid';

import { generateErrorMetadata } from './Monitoring';

type MonitoringErrorBoundaryProps = {
  experience?: UFOExperience;
  FallbackComponent?: React.ComponentType<FallbackProps>;
  onError?: () => void;
  // Used for the redirect action - 404s are expected here.
  ignoreNotFoundSentryException?: boolean;
};

export const MonitoringErrorBoundary = (props: React.PropsWithChildren<MonitoringErrorBoundaryProps>) => {
  const { ignoreNotFoundSentryException, experience, children, FallbackComponent, onError } = props;
  const FallbackRender = FallbackComponent ?? FullPageError;

  const handleOnError = useCallback(
    (
      error: Error,
      info: {
        componentStack: string;
      },
    ) => {
      onError?.();
      if (error instanceof ForbiddenError || error instanceof NotFound) {
        void experience?.abort();
      } else {
        void experience?.failure({
          metadata: generateErrorMetadata(error, info.componentStack),
        });
      }
      if (!(error instanceof NotFound && ignoreNotFoundSentryException)) {
        captureException(error, scope => scope.setExtra('componentStack', info.componentStack));
      }
    },
    [experience, ignoreNotFoundSentryException, onError],
  );

  return (
    <ErrorBoundary
      onError={handleOnError}
      fallbackRender={({ error, resetErrorBoundary }) => {
        if (error instanceof ForbiddenError) {
          return <ForbiddenContentError />;
        }

        if (error instanceof NotFound) {
          return (
            <ResponsivePage>
              <ErrorContainer isFullPage imageMaxWidthPx="400px">
                <FourOhFourError />
              </ErrorContainer>
            </ResponsivePage>
          );
        }

        return <FallbackRender error={error} resetErrorBoundary={resetErrorBoundary} />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
