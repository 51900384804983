/**
 * @generated SignedSource<<a49afb41c9bb9a890c7ab6ba63f100ca>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TqlEntity = "CUSTOM_FIELD" | "DIRECTORY_VIEW" | "GOAL" | "HELP_POINTER" | "KUDOS" | "LEARNING" | "PERSON" | "PROJECT" | "TAG" | "TEAM" | "UNKNOWN";
export type TqlExplainerQuery$variables = {
  directoryViewUuid?: string | null;
  entity: TqlEntity;
  hasTqlOrViewUuid: boolean;
  tql?: string | null;
  workspaceUuid?: string | null;
};
export type TqlExplainerQuery$data = {
  readonly explainTql?: {
    readonly explanation: string | null;
  } | null;
};
export type TqlExplainerQuery = {
  response: TqlExplainerQuery$data;
  variables: TqlExplainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "directoryViewUuid"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entity"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasTqlOrViewUuid"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tql"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v5 = [
  {
    "condition": "hasTqlOrViewUuid",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "directoryViewUuid",
            "variableName": "directoryViewUuid"
          },
          {
            "kind": "Variable",
            "name": "entity",
            "variableName": "entity"
          },
          {
            "kind": "Variable",
            "name": "q",
            "variableName": "tql"
          },
          {
            "kind": "Variable",
            "name": "workspaceUuid",
            "variableName": "workspaceUuid"
          }
        ],
        "concreteType": "TQLExplanation",
        "kind": "LinkedField",
        "name": "explainTql",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "explanation"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "TqlExplainerQuery",
    "selections": (v5/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "TqlExplainerQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "b2941ffe306ab5befd0584b5902f46c8",
    "id": null,
    "metadata": {},
    "name": "TqlExplainerQuery",
    "operationKind": "query",
    "text": "query TqlExplainerQuery(\n  $tql: String\n  $entity: TqlEntity!\n  $directoryViewUuid: UUID\n  $workspaceUuid: UUID\n  $hasTqlOrViewUuid: Boolean!\n) {\n  explainTql(q: $tql, entity: $entity, directoryViewUuid: $directoryViewUuid, workspaceUuid: $workspaceUuid) @include(if: $hasTqlOrViewUuid) {\n    explanation\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e3568457f233d7a51e77187b0c8d1b6";

export default node;
