import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/editor/close';
import { Box, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { DirectoryActions } from '@townsquare/directory-actions';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import {
  ComparatorOperator,
  Content,
  Entity,
  FilterDoc,
  onComparatorChangeFn,
  onOperatorChangeFn,
  onRemoveFn,
  onRootOperatorChangeFn,
  onValueFn,
  OptionTypes,
  Resolver,
  Resolvers,
} from '../types';
import {
  appendContentEntry,
  EMPTY_FILTER_DOC,
  removeContentEntry,
  updateComparatorEntry,
  updateDocOperator,
  updateOperatorEntry,
} from '../util';

import FilterDocument from './FilterDocument';
import FilterOptions from './FilterOptions';
import { ResolverIcon } from './ResolverIcon';
import { ResolverPopup } from './ResolverPopup';
import { SelectedResolverIconWrapper, SelectedResolverWrapper, Title } from './style';

interface Props {
  resolvers: Resolver[];
  onChange?: (filterDoc: FilterDoc) => void;
  filter?: FilterDoc;
  analyticsContext: 'goal' | 'project' | 'staffDirectory';
  analyticsView?: string;
  hasSavedViews?: boolean;
}

function filterGroupsByResolver(entity: Entity, resolvers: Resolvers) {
  return Boolean(resolvers.find(resolver => resolver.type === entity.type));
}

export const TqlFilter = ({ resolvers, filter, onChange, analyticsContext, analyticsView, hasSavedViews }: Props) => {
  const analytics = useAnalytics();
  const intl = useIntl();
  const isNavRefreshEnabled = useIsNavRefreshEnabled();
  const [selectedFilter, setSelectedFilter] = useState<Resolver | undefined>();
  const [doc, setDoc] = useState<FilterDoc>(filter || EMPTY_FILTER_DOC);
  const [resolverOpen, setResolverOpen] = useState(false);
  const [selectedComparator, setSelectedComparator] = useState<
    { label: string; value: ComparatorOperator } | undefined
  >();

  useEffect(() => {
    // If what we've been given is the same as our state
    // No need to notify a change to our consumers.
    if (filter !== doc) {
      onChange?.(doc);
    }
    // FIXME: TC-4035
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc]);

  useEffect(() => {
    if (filter) {
      setDoc(filter);
    }
  }, [filter]);

  const onSelectedFilterValue = (value: Content | number[]) => {
    if (!selectedFilter) {
      return;
    }

    void analytics.ui('metaFilter', 'changed', {
      changed: 'value',
      type: selectedFilter.type,
      context: analyticsContext,
      view: analyticsView,
    });

    const newDoc = appendContentEntry(
      doc,
      doc.model.length,
      selectedFilter.type,
      value,
      selectedFilter.filterComparators.flatMap(item => item.allowedOperators.map(operator => operator.operator)),
      selectedComparator
        ? [selectedComparator.value]
        : selectedFilter.filterComparators.map(item => item.comparatorOption),
    );

    setDoc(newDoc);
    setSelectedFilter(undefined);
  };

  const onValue: onValueFn = (entityIndex, type, value, operator, comparators) => {
    const newDoc = appendContentEntry(doc, entityIndex, type, value, operator, comparators);
    setDoc(newDoc);
  };

  const onOperatorChange: onOperatorChangeFn = (entityIndex, type, operator) => {
    void analytics.ui('metaFilter', 'changed', {
      changed: 'operator',
      context: analyticsContext,
      view: analyticsView,
      type,
    });

    const newDoc = updateOperatorEntry(doc, entityIndex, type, operator);
    setDoc(newDoc);
  };

  const onComparatorChange: onComparatorChangeFn = (entityIndex, type, comparator, operator) => {
    void analytics.ui('metaFilter', 'changed', {
      changed: 'comparator',
      context: analyticsContext,
      view: analyticsView,
      type,
    });
    const newDoc = updateComparatorEntry(doc, entityIndex, type, comparator, operator);
    setDoc(newDoc);
  };

  const onRootOperatorChange: onRootOperatorChangeFn = operator => {
    void analytics.ui('metaFilter', 'changed', {
      changed: 'rootOperator',
      context: analyticsContext,
      view: analyticsView,
    });

    const newDoc = updateDocOperator(doc, operator);
    setDoc(newDoc);
  };

  const onRemove: onRemoveFn = (entityIndex, type, modelIndex) => {
    const newDoc = removeContentEntry(doc, entityIndex, type, modelIndex);
    setDoc(newDoc);
  };

  doc.model = doc.model.filter(entity => filterGroupsByResolver(entity, resolvers));

  // TODO TC-6730: refactor toggle state resolvers to be rendered like other resolver types
  if (selectedFilter && selectedFilter.optionType === OptionTypes.TOGGLE) {
    onSelectedFilterValue(selectedFilter.render().value);
  }

  return (
    <Inline shouldWrap alignBlock="center" rowSpace="space.100">
      <FilterDocument
        document={doc}
        resolvers={resolvers}
        onValue={onValue}
        onRemove={onRemove}
        onOperatorChange={onOperatorChange}
        onRootOperatorChange={onRootOperatorChange}
        onComparatorChange={onComparatorChange}
      />
      {selectedFilter ? (
        <ResolverPopup
          isOpen={resolverOpen}
          resolver={selectedFilter.emptyStateComponent || selectedFilter}
          onChange={item => onSelectedFilterValue(Array.isArray(item) ? item : item.value)}
          onComparatorChange={comparator => setSelectedComparator(comparator)}
          onClose={() => {
            setSelectedFilter(undefined);
            setResolverOpen(false);
            setSelectedComparator(undefined);
          }}
          target={({ ref, isOpen, ...triggerProps }) => {
            return (
              <SelectedResolverWrapper
                innerRef={ref as React.MutableRefObject<HTMLDivElement>}
                data-testid="metafiler-selected-resolver"
              >
                <ResolverIcon resolver={selectedFilter} primaryColor={token('color.icon.brand')} />
                <Title>
                  {selectedFilter.title}&nbsp;
                  {selectedComparator?.label ??
                    (selectedFilter.filterComparators[0].comparatorText ||
                      intl.formatMessage({
                        id: `townsquare.tql.tql-filter.comparator-default-label`,
                        description: 'Default comparator label',
                        defaultMessage: 'is',
                      }))}
                </Title>
                <Button
                  {...triggerProps}
                  appearance="subtle"
                  spacing="none"
                  selected={isOpen}
                  onClick={() => setSelectedFilter(undefined)}
                  iconAfter={
                    <SelectedResolverIconWrapper>
                      <CrossIcon
                        primaryColor={token('color.icon.inverse')}
                        label={intl.formatMessage({
                          id: 'townsquare.tql.tql-filter.remove-filter-icon',
                          description: 'Remove filter icon',
                          defaultMessage: 'Remove current filter',
                        })}
                      />
                    </SelectedResolverIconWrapper>
                  }
                  style={{
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
                    margin: '0',
                  }}
                />
              </SelectedResolverWrapper>
            );
          }}
        />
      ) : (
        <FilterOptions
          resolvers={resolvers}
          onSelect={resolver => {
            setSelectedFilter(resolver);
            setResolverOpen(true);
          }}
          mode={doc.model.length === 0 ? 'button' : 'menu'}
        />
      )}
      {isNavRefreshEnabled && hasSavedViews && (
        <Box paddingBlock="space.050">
          <DirectoryActions viewContext="desktop" />
        </Box>
      )}
    </Inline>
  );
};
