import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

import { hideOnMobile, onMobile } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardRow = styled.div<{ desktopFlexGapPx?: number; shouldAvoidBleed?: boolean }>(
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  ({ desktopFlexGapPx, shouldAvoidBleed }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    gap: desktopFlexGapPx ? `${desktopFlexGapPx}px` : token('space.300'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    marginInline: shouldAvoidBleed ? 0 : token('space.negative.100'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    marginBlockStart: shouldAvoidBleed ? 0 : token('space.negative.100'),
    marginBlockEnd: token('space.600'),
  }),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      gap: token('space.100'),
      margin: `0 0 ${token('space.400')} 0`,
    }),
  ),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BrowseSectionHeading = styled.h4({
  font: token('font.heading.medium'),
  marginTop: '0px',
  marginBottom: token('space.250'),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ArrowRightIconBackground = styled.div({
  borderRadius: '6px',
  backgroundColor: token('color.background.neutral'),
  width: '24px',
  height: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const BrowseMoreLink = styled.a`
  color: ${token('color.text.subtlest')};

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  gap: ${token('space.100')};

  &:hover {
    ${ArrowRightIconBackground} {
      background-color: ${token('color.background.neutral.hovered')};
    }

    text-decoration: none;
  }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BrowseMoreText = styled.span`
  ${hideOnMobile()}
`;

const clearAllFiltersButtonStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- grandfathered in, to be reviewed later, Ignored via go/DSP-18766
  '> button': {
    lineHeight: '21px',
    padding: 0,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptySearchStateText = styled.p(
  {
    maxWidth: '360px',
    textAlign: 'center',
    marginTop: token('space.300'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  clearAllFiltersButtonStyles,
);

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyStateSearchAllSectionText = styled.p`
  ${clearAllFiltersButtonStyles}
`;

export const arrowRightIconColor = token('color.icon.subtle');
