import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

import { ellipsis, multilineEllipsis } from '@townsquare/ellipsis';
import { onMobile } from '@townsquare/responsive-grid';

const STYLED_ILLUSTRATION_CARD_HEIGHT_PX = 220;
const STYLED_ILLUSTRATION_CARD_WIDTH_PX = 302;
const HOVER_CARD_HEIGHT_PX = STYLED_ILLUSTRATION_CARD_HEIGHT_PX + 8;
const HOVER_CARD_WIDTH_PX = STYLED_ILLUSTRATION_CARD_WIDTH_PX + 8;

export const STYLED_ILLUSTRATION_CARD_HEIGHT = `${STYLED_ILLUSTRATION_CARD_HEIGHT_PX}px`;
export const STYLED_ILLUSTRATION_CARD_WIDTH = `${STYLED_ILLUSTRATION_CARD_WIDTH_PX}px`;
export const STYLED_KUDOS_CARD_WIDTH = '240px';
export const STYLED_KUDOS_CARD_HEIGHT = '300px';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const HOVER_CARD_HEIGHT = `${HOVER_CARD_HEIGHT_PX}px`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const HOVER_CARD_WIDTH = `${HOVER_CARD_WIDTH_PX}px`;

const getHoverItemStyles = () => {
  return css({
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    transition: 'background-color 0.1s ease-in-out',
  });
};

const getHoverItemHoveredStyles = () => {
  return css(
    {
      boxShadow: token('elevation.shadow.overlay'),
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    onMobile(
      `
        box-shadow: none;
      `,
    ),
  );
};

const getHoverItemContainerStyles = () => {
  return css(
    {
      width: HOVER_CARD_WIDTH,
      height: HOVER_CARD_HEIGHT,
      borderRadius: '6px',
      margin: token('space.negative.050'),
      padding: token('space.050'),
      transition: 'padding 0.1s',
      boxSizing: 'border-box',
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    onMobile(
      `
      width: 100%;
      height: 120px;
      margin: 0;
      padding: 0;
    `,
    ),
  );
};

const getHoverItemContainerHoveredStyles = () => {
  return css({
    padding: 0,
  });
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IllustrationTextContainer = styled.div({
  padding: token('space.300'),
  maxWidth: '254px',
  transition: 'padding 0.1s',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IllustrationCardNameText = styled.p(
  {
    font: token('font.heading.small'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  multilineEllipsis(2),
  {
    wordWrap: 'break-word',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
    font: ${token('font.body')};
    font-weight: ${token('font.weight.medium')};
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CountText = styled.p(
  {
    color: token('color.text.subtle'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`font: ${token('font.body')};`),
  {
    marginTop: 0,
  },
);

interface PeopleFieldImageProps {
  desktopImageUrl?: string;
  mobileImageUrl?: string;
}

const getGreyBackground = () => token('color.background.neutral');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledIllustrationCard = styled.div<{ hoverColor?: string } & PeopleFieldImageProps>`
  background-color: ${getGreyBackground()};

  ${getHoverItemStyles()}

  background-image: url(${({ desktopImageUrl }) => desktopImageUrl});
  background-size: cover;
  ${({ mobileImageUrl, hoverColor }) =>
    onMobile(`
      background-image: url(${mobileImageUrl});
      background-color: ${hoverColor};
  `)};

  cursor: pointer;

  &:hover {
    ${({ hoverColor }) => (hoverColor ? `background-color: ${hoverColor};` : '')}
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const IllustrationCardHoverContainer = styled.div`
  ${getHoverItemContainerStyles()}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const IllustrationCardContainer = styled.div`
  width: ${STYLED_ILLUSTRATION_CARD_WIDTH};
  padding: ${token('space.100')};
  box-sizing: border-box;

  ${onMobile(
    css({
      width: 'calc(50% - 5px)',
      padding: 0,
    }),
  )}

  &:hover {
    ${IllustrationCardHoverContainer} {
      ${getHoverItemContainerHoveredStyles()}

      > ${StyledIllustrationCard} {
        ${getHoverItemHoveredStyles()}

        ${IllustrationTextContainer} {
          padding: ${token('space.300')};
        }
      }
    }
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LocationDetails = styled.div({
  paddingTop: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LocationCardNameText = styled.p(
  {
    font: token('font.heading.small'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  ellipsis,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
    font: ${token('font.body')};
    font-weight: ${token('font.weight.medium')};
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LocationImage = styled.div<PeopleFieldImageProps>`
  ${getHoverItemStyles()}

  background-image: url(${({ desktopImageUrl }) => desktopImageUrl});
  background-size: cover;
  ${({ mobileImageUrl }) => onMobile(`background-image: url(${mobileImageUrl})`)};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LocationImageBoundingBox = styled.div`
  ${getHoverItemContainerStyles()}
`;

const getGenericImageColoredBackground = () => token('color.background.accent.purple.subtlest');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledLocationCard = styled.div<{ hasGenericImage: boolean }>`
  box-sizing: border-box;
  width: ${STYLED_ILLUSTRATION_CARD_WIDTH};
  ${onMobile(
    css({
      width: 'calc(50% - 5px)',
      padding: 0,
    }),
  )}

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: ${token('space.100')};
  border-radius: 6px;

  cursor: pointer;

  ${LocationImage} {
    ${({ hasGenericImage }) => (hasGenericImage ? `background-color: ${getGreyBackground()};` : '')}
    ${({ hasGenericImage }) =>
      onMobile(hasGenericImage ? `background-color: ${getGenericImageColoredBackground()};` : '')}
  }

  &:hover {
    ${LocationImageBoundingBox} {
      ${getHoverItemContainerHoveredStyles()}

      > ${LocationImage} {
        ${getHoverItemHoveredStyles()}

        ${({ hasGenericImage }) => (hasGenericImage ? `background-color: ${getGenericImageColoredBackground()};` : '')}
      }
    }

    ${LocationCardNameText} {
      color: ${token('color.text.subtlest')};
    }

    text-decoration: none;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LocationCardLoadingStateContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});
