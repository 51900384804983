import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import TagUpdatesAsideViewQuery, {
  type TagUpdatesAsideViewQuery as TagUpdatesAsideViewQueryType,
} from '../TagUpdatesView/__generated__/TagUpdatesAsideViewQuery.graphql';
import {
  SHOW_LIMIT,
  getActiveProjectsTql,
  getCompletedProjectsTql,
  getPausedProjectsTql,
  getPendingProjectsTql,
  getRelatedGoalsTql,
} from '../TagUpdatesView/getTqlQueries';

// TQL can underfetch with perms - adding a slight buffer to make sure we get the SHOW_LIMIT amount of items.
const TQL_OVERFETCH = 3;
export const tagUpdatesAsideResource = createRelayResource<TagUpdatesAsideViewQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const uuid = routerContext?.match?.params.id ?? '';
    return {
      query: TagUpdatesAsideViewQuery,
      variables: {
        tagUUID: routerContext?.match?.params.id,
        workspaceId: context.workspace.globalId || '',
        workspaceUuid: context.workspace.UUID || '',
        activeProjectsTql: getActiveProjectsTql(uuid),
        pendingProjectsTql: getPendingProjectsTql(uuid),
        pausedProjectsTql: getPausedProjectsTql(uuid),
        completedProjectsTql: getCompletedProjectsTql(uuid),
        relatedGoalsTql: getRelatedGoalsTql(uuid),
        sidebarLimit: SHOW_LIMIT + TQL_OVERFETCH,
        isNavRefreshEnabled: isNavRefreshEnabled(context),
      },
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
