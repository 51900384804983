import { RequestServiceOptions, utils } from '@atlaskit/util-service-support';

import { WORKLENS_PRODUCT_TYPE } from '@townsquare/activity-provider';
import { getConfig } from '@townsquare/config';

import {
  AvailableProductsResponse,
  getAvailableProductsResult,
  getAvailableSitesCloudIdsResult,
  getUrlFromCloudIdResult,
} from './types';

const UNTENANTED_PRODUCTS = ['TRELLO', 'BITBUCKET'];

export type GetAvailableSitesArgs = {
  cloudIdsToFilterFor?: string[];
};

export default class AvailableSitesProvider {
  private baseUrl: string;
  private promise?: Promise<AvailableProductsResponse>;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  /**
   * Get the url for the requested cloudId from the APS response
   * @param cloudId the cloudId you want the url for
   * @param requiredProducts (optional) required products to be on the site
   * @returns a string with the url for a cloud site
   */
  async getUrlFromCloudId(
    cloudId: string,
    requiredProducts?: WORKLENS_PRODUCT_TYPE[],
  ): Promise<getUrlFromCloudIdResult> {
    return this.getAvailableProducts().then(resp => {
      const siteOfRequestedCloudId = resp.data.find(site => site.cloudId === cloudId);

      if (siteOfRequestedCloudId) {
        if (
          requiredProducts &&
          !siteOfRequestedCloudId.availableProducts.find(product => requiredProducts.includes(product.productType))
        ) {
          // None of the required products are present on the site, return error response
          return {
            data: '',
            error: true,
          };
        }
        return {
          data: siteOfRequestedCloudId.url,
          error: false,
        };
      } else {
        return {
          data: '',
          error: true,
        };
      }
    });
  }

  /**
   * Get all the cloudIds of sites the current user has access to
   * @returns an array of cloudIds
   */
  async getAvailableSitesCloudIds({
    cloudIdsToFilterFor,
  }: GetAvailableSitesArgs = {}): Promise<getAvailableSitesCloudIdsResult> {
    return this.getAvailableProducts({ cloudIdsToFilterFor }).then(resp => ({
      data: resp.data.map(site => site.cloudId),
      error: resp.error,
    }));
  }

  /**
   * Get all the sites with product info the current user has access to
   * @returns an array of AvailableSite
   */
  async getAvailableProducts({ cloudIdsToFilterFor }: GetAvailableSitesArgs = {}): Promise<getAvailableProductsResult> {
    if (!this.promise) {
      const options: RequestServiceOptions = {
        requestInit: {
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      };

      this.promise = utils.requestService({ url: `${getConfig().appSwitcherBffUrl}/api/available-products` }, options);
    }

    const defaultResponse: getAvailableProductsResult = { data: [], error: false };

    try {
      const response = await this.promise;

      if (!response.sites) {
        return defaultResponse;
      }

      const sites = cloudIdsToFilterFor
        ? response.sites.filter(site => cloudIdsToFilterFor.includes(site.cloudId))
        : response.sites;
      return {
        data: sites,
        error: false,
      };
    } catch (error) {
      return {
        data: [],
        error: true,
      };
    }
  }

  async getTenantedAvailableSitesCloudIds(): Promise<string[]> {
    const cloudIds = await this.getAvailableSitesCloudIds();
    return cloudIds.data.filter(cloudId => !UNTENANTED_PRODUCTS.includes(cloudId));
  }

  async firstTenantIdFromAvailableProducts(): Promise<string | null> {
    const cloudIds = await this.getTenantedAvailableSitesCloudIds();
    // Find first tenantId from available sites that is not untenanted
    return cloudIds.length > 0 ? cloudIds[0] : null;
  }

  /**
   * If the user only has a single tenant, return it, otherwise return null
   */
  async tenantIdIfSingleTenant(): Promise<string | null> {
    const cloudIds = await this.getTenantedAvailableSitesCloudIds();
    return cloudIds.length === 1 ? cloudIds[0] : null;
  }
}
