import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

import ExploreQuery, { type ExploreQuery as ExploreQueryType } from '../ui/__generated__/ExploreQuery.graphql';
import { TAGS_TRENDING_LIMIT, TAGS_WATCHING_LIMIT } from '../ui/constants';

export const exploreResource = createRelayResource<ExploreQueryType>({
  getQuery: (_routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    return {
      query: ExploreQuery,
      variables: {
        workspaceUUID: context.workspace.UUID,
        workspaceId: context.workspace.globalId,
        tagsWatchingLimit: TAGS_WATCHING_LIMIT,
        tagsTrendingLimit: TAGS_TRENDING_LIMIT,
        first: 25,
        allTagsQuery: tqlQueryFromInput({
          input: [
            { fieldName: 'name', comparator: ComparatorOperator.LIKE, fieldValue: '' },
            { fieldName: 'usageCount', comparator: ComparatorOperator.GT, fieldValue: 0 },
          ],
        }),
        tagSort: 'USAGE_DESC',
      },
    };
  },
});
