/**
 * @generated SignedSource<<9cfa03c5b90a7ab426fa76036078bdf7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type fetchKeyboardPreferenceQuery$variables = {};
export type fetchKeyboardPreferenceQuery$data = {
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"storeKeyboardShortcut">;
  } | null;
};
export type fetchKeyboardPreferenceQuery = {
  response: fetchKeyboardPreferenceQuery$data;
  variables: fetchKeyboardPreferenceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "shortcutsType"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "fetchKeyboardPreferenceQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "storeKeyboardShortcut",
            "selections": [
              (v0/*: any*/)
            ],
            "argumentDefinitions": []
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "fetchKeyboardPreferenceQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "faeea3e943c0b544f1d69013077292b6",
    "id": null,
    "metadata": {},
    "name": "fetchKeyboardPreferenceQuery",
    "operationKind": "query",
    "text": "query fetchKeyboardPreferenceQuery {\n  currentUser {\n    ...storeKeyboardShortcut\n    id\n  }\n}\n\nfragment storeKeyboardShortcut on User {\n  shortcutsType\n}\n"
  }
};
})();

(node as any).hash = "2139ce663c434a93e72e8a9395d2e0d6";

export default node;
