import React from 'react';

import { ResponsiveLoadingState } from '@townsquare/loading-state';

import { BrowsePersonCardLoadingState } from '../Cards/Browse/Person/loadingState';
import { CardRow } from '../styles';

import { FLEX_GAP_PX } from './constants';

export const PeopleRowLoadingSkeleton = () => {
  return (
    <ResponsiveLoadingState>
      <CardRow desktopFlexGapPx={FLEX_GAP_PX}>
        {[0, 1, 2, 3, 4, 5].map(i => (
          <BrowsePersonCardLoadingState key={`loading-state-${i}`} />
        ))}
      </CardRow>
    </ResponsiveLoadingState>
  );
};
