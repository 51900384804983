import Banner from '@atlaskit/banner';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { isEmbedOrIntegration } from '@townsquare/config';
import { useFeatureGate } from '@townsquare/stat-sig/gate';

export const ScheduledMaintenanceBanner = () => {
  const shouldDisplayBanner = useFeatureGate('enable_atlas_scheduled_maintenance_banner');

  if (!shouldDisplayBanner || isEmbedOrIntegration()) {
    return null;
  }

  return (
    <Banner appearance="warning" icon={<WarningIcon label="" secondaryColor="inherit" />}>
      <FormattedMessage
        id="townsquare.banners.scheduled-maintenance.message"
        description="Banner text indicating the site is under scheduled maintenance"
        defaultMessage="We are undergoing scheduled maintenance. <link>Check our status page for more details.</link>"
        values={{
          link: (text: string) => <a href="https://atlas.status.atlassian.com/">{text}</a>,
        }}
      />
    </Banner>
  );
};
