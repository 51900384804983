import { createSearchParamsFromCurrentUrl } from '@townsquare/url-utils/search-params';
import { useWorkspaceType } from '@townsquare/workspace-store/hooks';
import { WorkspaceDetails } from '@townsquare/workspace-store/types';

import { isGateEnabled, useFeatureGate } from './Gate';

type ExpectedResourceContext = {
  workspace: Partial<WorkspaceDetails>;
};

/**
 * This needs to run early enough in the app bootstrap to prevent other URL re-writing logic from clobbering over it
 */
const GRIFFIN_NAV_OPT_OUT = 'nav4-optout';
const isOptOut = createSearchParamsFromCurrentUrl().get(GRIFFIN_NAV_OPT_OUT);

export const useIsNavRefreshEnabled = () => {
  const workspaceType = useWorkspaceType();
  const enableNavRefresh = useFeatureGate('atlassian_home_nav4');

  return shouldEnableNavRefresh(workspaceType, enableNavRefresh);
};

export const isNavRefreshEnabled = (resourceContext: ExpectedResourceContext) => {
  const enableNavRefresh = isGateEnabled('atlassian_home_nav4');

  return shouldEnableNavRefresh(resourceContext.workspace.type, enableNavRefresh);
};

const shouldEnableNavRefresh = (workspaceType: WorkspaceDetails['type'] | undefined, enableNavRefresh: boolean) => {
  try {
    if (isOptOut && true === JSON.parse(isOptOut)) {
      return false;
    }
  } catch (e) {
    /* JSON.parse could error out terribly. We don't want that */
  }

  // Only enable new nav in home
  return workspaceType !== 'PRODUCT' && enableNavRefresh;
};
