import Loadable from 'react-loadable';

import { PROJECT_PAGE, PROJECT_PATH_PATTERN } from '@townsquare/config/routes';
import { onboardingResource } from '@townsquare/onboarding/resource';
import { createSiteRoute } from '@townsquare/router/create-route';

import { jiraIntegrationLinkResource } from './resources/JiraIntegrationLink';
import {
  projectDecisionsTabResource,
  projectLearningsTabResource,
  projectRisksTabResource,
} from './resources/ProjectLearningsTab';
import { projectUpdatesResource, projectViewResource } from './resources/ProjectView';
import { projectViewAsideResource } from './resources/ProjectViewAside';

export const projectRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "project-view" */ './components/ProjectView'),
    loading: () => null,
  }),
  aside: Loadable({
    loader: () => import(/* webpackChunkName: "project-view-aside" */ './components/ProjectViewAside'),
    loading: () => null,
  }),
  exact: true,
  name: PROJECT_PAGE,
  path: PROJECT_PATH_PATTERN,
  resources: [
    projectLearningsTabResource,
    projectRisksTabResource,
    projectDecisionsTabResource,
    projectViewResource,
    projectViewAsideResource,
    projectUpdatesResource,
    jiraIntegrationLinkResource,
    onboardingResource,
  ],
  subproduct: 'townsquare-projects',
});
