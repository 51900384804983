import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { PopupSelect } from '@atlaskit/select';
import React from 'react';

import { Operators } from '../../types';
import { GROUP_OPERATOR_CLASSNAME, POPPER_MODIFIERS } from '../style';

interface OperatorPickerOption {
  label: string;
  value: Operators;
}

interface OperatorPickerPopupProps {
  isRootOperator: boolean;
  options: OperatorPickerOption[];
  selected: Operators;
  onChange: (operator: Operators) => void;
  onClose: () => void;
  buttonStyle: React.CSSProperties;
}

const getClassName = (isOpen: boolean, root?: boolean) => {
  if (isOpen) {
    return undefined;
  }

  return Object.entries({ root, [GROUP_OPERATOR_CLASSNAME]: true })
    .map(([key, value]) => {
      if (value) {
        return key;
      }
    })
    .filter(v => !!v)
    .join('-');
};

const OperatorPickerPopup = (props: OperatorPickerPopupProps) => {
  return (
    <PopupSelect<OperatorPickerOption>
      options={props.options}
      onChange={item => {
        if (item?.value) {
          props.onChange(item.value);
        }
      }}
      onClose={props.onClose}
      minMenuWidth={80}
      maxMenuWidth={80}
      value={props.options.find(option => option.value === props.selected)}
      formatOptionLabel={(option, { context }) =>
        context === 'value' ? (
          option.label
        ) : (
          <span data-testid={`operator-picker-option-${option.value}`}>{option.label}</span>
        )
      }
      styles={{
        option: (css, optionProps) => {
          return {
            ...css,
            cursor: optionProps.isDisabled ? 'not-allowed' : 'pointer',
          };
        },
      }}
      target={({ ref, isOpen }) => (
        <Button
          ref={ref}
          spacing="compact"
          appearance="subtle"
          isSelected={isOpen}
          // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
          style={props.buttonStyle}
          // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
          className={getClassName(isOpen, props.isRootOperator)}
          iconAfter={<ChevronDownIcon size="medium" label="" />}
          testId={`metafilter-${props.isRootOperator ? 'root-' : ''}operator-picker`}
        >
          {props.options.find(item => item.value === props.selected)?.label}
        </Button>
      )}
      popperProps={POPPER_MODIFIERS}
    />
  );
};

export default OperatorPickerPopup;
