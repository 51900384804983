import { goalDirectoryRoute } from '@townsquare/goals-directory-view/route';
import { projectDirectoryRoute } from '@townsquare/projects-directory-view/route';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

import { DirectoryViewEntityType } from './components/__generated__/DirectoryFavoriteViewButton.graphql';
import { ViewTab } from './hooks/useSelectedView';
import { DirectoryRoute } from './types';

// Used for url parameter tql matching as a shortcut between the url path updating and explainTql loading.
// Allows for smoother view changes.
const getArchivedTql = (archived: boolean) =>
  tqlQueryFromInput({
    input: [
      {
        fieldName: 'archived',
        fieldValue: archived,
        comparator: ComparatorOperator.EQUALS,
      },
    ],
  });

export const archivedTql = getArchivedTql(true);
export const unarchivedTql = getArchivedTql(false);

export const yourItemsTql = (userId: string, entity: DirectoryRoute) =>
  tqlQueryFromInput({
    input: [
      {
        fieldName: 'archived',
        fieldValue: false,
        comparator: ComparatorOperator.EQUALS,
      },
      {
        fieldName: entity === 'goals' ? 'owner' : 'contributor',
        fieldValue: userId,
        comparator: ComparatorOperator.EQUALS,
      },
    ],
  });

export const followingTql = (userId: string) =>
  tqlQueryFromInput({
    input: [
      {
        fieldName: 'archived',
        fieldValue: false,
        comparator: ComparatorOperator.EQUALS,
      },
      {
        fieldName: 'watcher',
        fieldValue: userId,
        comparator: ComparatorOperator.EQUALS,
      },
    ],
  });

export const entityTypeToDirectoryRoute = (entityType?: DirectoryViewEntityType | null) => {
  if (entityType === 'GOAL') {
    return goalDirectoryRoute;
  }
  return projectDirectoryRoute;
};

export const getUrlForViewTab = (entity: DirectoryRoute, viewTab: ViewTab, tql: string) => {
  const tqlEncoded = encodeURIComponent(tql);
  return `${entity}?tql=${tqlEncoded}&viewTab=${viewTab}`;
};
