import SearchIcon from '@atlaskit/icon/glyph/search';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { usePathParam } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useQueryParams } from '@townsquare/router';
import { useMetaClickHandler } from '@townsquare/ui-interactions';
import { createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';

import { staffDirectoryRoute } from '../../../route';
import { ScreenType } from '../../ScreenType';

import { PreviewRow } from './PreviewRow';
import { SearchAllText, SearchIconContainer } from './styles';
import { SelectableRowProps } from './types';

export const SEARCH_ALL_ROW_ID = 'search-all-row-key';

export const getSearchAllUrl = (
  basePath: string,
  query: {
    [key: string]: string;
  },
  tql: string,
) => {
  const queryString = createSearchParamsFromScratch({
    ...query,
    screen: ScreenType[ScreenType.SEARCH_ALL],
    tql,
  }).toString();

  return `${basePath}?${queryString}`;
};

interface SearchAllRowProps extends SelectableRowProps {
  searchText?: string;
  onRowClicked: (e?: React.MouseEvent | React.KeyboardEvent) => void;
  tql: string;
}

export const SearchAllRow = ({ searchText, isSelected, onRowClicked, tql }: SearchAllRowProps) => {
  const query = useQueryParams();
  const [search] = usePathParam('search');
  const analytics = useAnalytics();
  const intl = useIntl();

  const queryParams = createSearchParamsFromScratch({
    ...query,
    screen: ScreenType[ScreenType.SEARCH_ALL],
    tql,
  });

  const { handler } = useMetaClickHandler(
    staffDirectoryRoute,
    { params: { search }, query: Object.fromEntries(queryParams) },
    e => {
      void analytics.ui('staffDirectorySearchPreviewSearchAllRow', 'clicked');
      onRowClicked(e);
    },
  );

  return (
    <PreviewRow
      route={staffDirectoryRoute}
      params={{ search }}
      query={Object.fromEntries(queryParams)}
      onClick={handler}
      isSelected={isSelected}
    >
      <SearchIconContainer>
        <SearchIcon
          label={intl.formatMessage({
            id: 'townsquare.web.search-all-row.search-all-icon-label',
            description: 'See all results icon label',
            defaultMessage: 'See all results',
          })}
        />
      </SearchIconContainer>
      <SearchAllText>
        {searchText ? (
          <FormattedMessage
            id="townsquare.web.search-all-row.search-all-with-text"
            description="See all results for search with text"
            defaultMessage='See all results for "{searchText}"'
            values={{
              searchText,
            }}
          />
        ) : (
          <FormattedMessage
            id="townsquare.web.search-all-row.search-all"
            description="See all results for search"
            defaultMessage="See all results"
          />
        )}
      </SearchAllText>
    </PreviewRow>
  );
};
