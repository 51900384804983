import { borderRadius, layers } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { ellipsis } from '@townsquare/ellipsis';

/**
 * We use this classname to hightlight other operators within the same group
 * to signify changes will reflect on all operators.
 */
export const GROUP_OPERATOR_CLASSNAME = 'group-operator';

//#region index
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SelectedResolverWrapper = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  padding: token('space.100'),
  background: token('color.background.selected'),
  color: token('color.text.selected'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: `${borderRadius() * 2}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Title = styled.span({
  margin: `0 ${token('space.100')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SelectedResolverIconWrapper = styled.span({
  backgroundColor: token('color.background.selected.bold'),
  '&:hover': {
    backgroundColor: token('color.background.selected.bold.hovered'),
  },
  '&:active': {
    backgroundColor: token('color.background.selected.bold.pressed'),
  },
});
//#endregion

//#region FilterOptions
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterOptionsWrapper = styled.div({
  display: 'inline-flex',
  flexWrap: 'wrap',
  rowGap: token('space.100'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  button: {
    marginRight: token('space.100'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderRadius: `${borderRadius() * 2}px`,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const InlineFlexContainer = styled.span<{ shouldWrap?: boolean }>(props => ({
  display: 'inline-flex',
  alignItems: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  flexWrap: props.shouldWrap ? 'wrap' : 'nowrap',
}));
//#endregion

//#region FilterDocument
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterGroupWrapper = styled.div<{
  isLastInGroup: boolean;
  isFirstInGroup: boolean;
  isLastEntity: boolean;
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(props => ({
  background: token('elevation.surface.sunken'),
  paddingTop: token('space.100'),
  paddingBottom: token('space.100'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  paddingLeft: props.isFirstInGroup ? token('space.100') : 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  paddingRight: props.isLastInGroup ? token('space.100') : 0,
  marginRight:
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    props.isLastEntity && props.isLastInGroup ? token('space.100') : props.isLastInGroup ? token('space.050') : 0,
  display: 'inline-flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  position: 'relative',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  borderTopLeftRadius: props.isFirstInGroup ? `${borderRadius() * 2}px` : 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  borderBottomLeftRadius: props.isFirstInGroup ? `${borderRadius() * 2}px` : 0,

  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  borderTopRightRadius: props.isLastInGroup ? `${borderRadius() * 2}px` : 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  borderBottomRightRadius: props.isLastInGroup ? `${borderRadius() * 2}px` : 0,
  '&:before, &:after': {
    content: "''",
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
    position: 'absolute',
    display: 'inline-flex',
    height: 0,
    zIndex: -1,
    top: 0,
  },
  '&:before': {
    borderRightStyle: 'solid',
    borderRightWidth: '10px',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderRightColor: props.isFirstInGroup ? 'transparent' : token('elevation.surface.sunken'),
    marginLeft: token('space.negative.100'),
    left: 0,
  },
  '&:after': {
    borderLeftStyle: 'solid',
    borderLeftWidth: '10px',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderLeftColor: props.isLastInGroup ? 'transparent' : token('elevation.surface.sunken'),
    marginRight: token('space.negative.100'),
    right: 0,
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const Item = styled.span<{ isHovered?: boolean }>`
  display: inline-flex;
  min-height: 1.5rem;
  height: fit-content;
  margin: 0 ${token('space.050')} 0 0;

  > span {
    margin: 0;
    border-radius: ${borderRadius()}px;
  }

  .${GROUP_OPERATOR_CLASSNAME} {
    background-color: ${props =>
      props.isHovered ? token('color.background.neutral.hovered') + ` !important` : 'inherit'};

    span {
      color: ${props => (props.isHovered ? token('color.text.subtle') + ` !important` : 'inherit')};
    }
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const RootOperatorWrapper = styled.span<{ isHovered?: boolean }>`
  display: inline-flex;

  .root-${GROUP_OPERATOR_CLASSNAME} {
    background-color: ${props =>
      props.isHovered ? token('color.background.neutral.hovered') + ` !important` : 'inherit'};

    span {
      color: ${props => (props.isHovered ? token('color.text.subtle') + ` !important` : 'inherit')};
    }
  }
`;
//#endregion

//#region OperatorPicker
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OperatorLabel = styled.span({
  display: 'inline-flex',
  paddingBlock: token('space.025'),
  paddingInline: token('space.100'),
});
//#endregion

//#region FilterTag
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const FilterTagWrapper = styled.span<{ appearance: 'default' | 'danger' }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  backgroundColor: props.appearance === 'danger' ? token('color.background.danger') : token('color.background.neutral'),
  display: 'inline-flex',
  alignItems: 'center',
  padding: `0 0 0 ${token('space.100')}`,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterWrapperNoTitle = styled(FilterTagWrapper)({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  padding: '0 0 0 0 !important',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: token('color.background.danger'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    color: `${token('color.icon.danger')} !important`,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterTagText = styled.span<{ appearance: 'default' | 'danger' }>(
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  props => ({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    color: props.appearance === 'danger' ? token('color.text.danger') : token('color.text'),
    maxWidth: '300px',
    display: 'block',
  }),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  ellipsis,
  {
    '@media (max-width: 350px)': {
      whiteSpace: 'normal',
      maxHeight: '3rem',
    },
  },
);
//#endregion

//#region AddEntity
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const AddEntityWrapper = styled.span<{ isOpen?: boolean }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: `${borderRadius()}px`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  backgroundColor: props.isOpen ? token('color.background.selected.bold') : token('color.background.neutral'),
}));
//#endregion

//#region RenderResolver
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionWrapper = styled.div({
  display: 'inline-flex',
  width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionLabel = styled.div({
  display: 'inline-flex',
  wordBreak: 'break-word',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionIcon = styled.span({
  marginRight: token('space.100'),
  display: 'inline-flex',
});
//#endregion

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.100'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> div': {
    margin: 0,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NumberResolverContent = styled.div({
  padding: token('space.200'),
  gap: token('space.100'),
  display: 'flex',
  boxSizing: 'border-box',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  zIndex: layers.layer(),
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '300px',
  backgroundColor: token('elevation.surface.overlay'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  borderRadius: token('border.radius'),
  boxShadow: token('elevation.shadow.overlay'),
  overflow: 'visible',
  '&:focus': {
    outline: 'none',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NumberBetweenWrapper = styled.div({
  display: 'flex',
  gap: token('space.050'),
  alignItems: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> div': {
    margin: 0,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledProjectFilterRow = styled.div({
  display: 'inline-flex',
  maxWidth: '100%',
  height: '100%',
  alignItems: 'center',
  gap: token('space.100'),
});

// Popper placement is broken by the presence of the `overflow: hidden` on the parent container
export const POPPER_MODIFIERS = {
  modifiers: [
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
      },
    },
  ],
} as const;

export const FILTER_OPTIONS_BUTTON_STYLE = {
  paddingBlock: token('space.050'),
  paddingInline: token('space.100'),
  height: `auto`,
  marginRight: token('space.100'),
  marginBottom: 0,
  marginTop: 0,
};
