export const SAME_MONTH_FORMAT = 'd';
export const SAME_YEAR_FORMAT = 'd MMM';
export const DIFF_YEAR_FORMAT = 'd MMM yyyy';
export const FULL_DATE_FORMAT = 'd MMMM yyyy';
export const MONTH_ONLY_FORMAT = 'MMMM';
export const MONTH_ONLY_FORMAT_SHORT = 'MMM';
export const MONTH_ONLY_FORMAT_DIFF_YEAR = 'MMM yyyy';
export const MONTH_WITH_YEAR = 'MMMM yyyy';
export const FULL_NICE_FORMAT = 'dd-MMM-yyyy';
export const NICE_DATE_TIME = 'd MMMM yyyy h:mm a';
export const ISO_LOCAL_DATE = 'yyyy-MM-dd';
export const ISO_LOCAL_TIME = 'HH:mm:ss';
export const ISO_LOCAL_DATE_TIME = `${ISO_LOCAL_DATE}'T'${ISO_LOCAL_TIME}`;
export const ISO_LOCAL_DATE_WITH_SLASHES = 'yyyy/MM/dd';
