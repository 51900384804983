import { CustomThemeButton, ThemeProps, ThemeTokens } from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import styled from 'styled-components';

import { useModalActions } from '@townsquare/modals';
import { useNoWorkspaceExperience } from '@townsquare/workspace-store';

// Begin making the "give feedback" button smaller to give space to other nav elements
export const responsiveMobileWidthThreshold = 560;

const boldTextButtonStyle = (current: (themeProps: ThemeProps) => ThemeTokens, themeProps: ThemeProps) => {
  const theme = current(themeProps);
  return {
    ...theme,
    buttonStyles: {
      ...theme.buttonStyles,
      fontWeight: token('font.weight.medium'),
    },
  };
};

const Container = styled.div({
  '&:not(:first-child)': {
    marginLeft: token('space.100'),
  },
});

export const GiveFeedbackButton = () => {
  const { openModal } = useModalActions();
  const noWorkspaceExperience = useNoWorkspaceExperience();

  const onClick = () =>
    openModal('feedback-modal', {
      redirectOnSave: false,
      feedbackEntryPoint: noWorkspaceExperience ? 'Atlas - No workspace' : 'Atlas - Navigation',
    });

  return (
    <Container data-testid="feedback" onClick={onClick}>
      <CustomThemeButton theme={boldTextButtonStyle}>
        <FormattedMessage
          id="townsquare.give-feedback-button.give-feedback.label"
          description="Give feedback label"
          defaultMessage="Give feedback"
        />
      </CustomThemeButton>
    </Container>
  );
};
