/**
 * @generated SignedSource<<2412c15836b0a2c1b955fefa0dd554f1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdminUpgradeActionBillingAdminQuery$variables = {
  id: string;
};
export type AdminUpgradeActionBillingAdminQuery$data = {
  readonly workspace: {
    readonly userWorkspacePermissions: {
      readonly admin: boolean;
      readonly billingAdmin: boolean;
    } | null;
  } | null;
};
export type AdminUpgradeActionBillingAdminQuery = {
  response: AdminUpgradeActionBillingAdminQuery$data;
  variables: AdminUpgradeActionBillingAdminQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "concreteType": "UserWorkspacePermissions",
  "kind": "LinkedField",
  "name": "userWorkspacePermissions",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "billingAdmin"
    },
    {
      "kind": "ScalarField",
      "name": "admin"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "AdminUpgradeActionBillingAdminQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminUpgradeActionBillingAdminQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "9e2f58936aed045d047069cdeb39779c",
    "id": null,
    "metadata": {},
    "name": "AdminUpgradeActionBillingAdminQuery",
    "operationKind": "query",
    "text": "query AdminUpgradeActionBillingAdminQuery(\n  $id: ID!\n) {\n  workspace(id: $id) {\n    userWorkspacePermissions {\n      billingAdmin\n      admin\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ce000c5d4f94834a8286377df605908";

export default node;
