import { commitMutation, Environment, graphql, MutationConfig } from 'relay-runtime';

import {
  DeleteProjectFusionMutation,
  DeleteProjectFusionMutation$variables,
} from './__generated__/DeleteProjectFusionMutation.graphql';

export function deleteProjectFusionMutation(
  environment: Environment,
  variables: DeleteProjectFusionMutation$variables,
  events?: Pick<MutationConfig<DeleteProjectFusionMutation>, 'onCompleted' | 'onError'>,
) {
  return commitMutation<DeleteProjectFusionMutation>(environment, {
    mutation: graphql`
      mutation DeleteProjectFusionMutation($input: deleteProjectFusionInput!) {
        deleteProjectFusion(input: $input) {
          project {
            key
            integration {
              id
            }
            workTrackingLinks: links(type: WORK_TRACKING, first: 1) {
              ...ProjectLinks
            }
          }
        }
      }
    `,
    variables,
    onCompleted: events?.onCompleted,
    onError: events?.onError,
  });
}
