/**
 * @generated SignedSource<<ed998d12981f47ad032373bd79b81147>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type LearningTags_data$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly name: string | null;
      readonly watcherCount: number;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "LearningTags_data";
};
export type LearningTags_data$key = {
  readonly " $data"?: LearningTags_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"LearningTags_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "LearningTags_data",
  "selections": [
    {
      "concreteType": "TagEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "id"
            },
            {
              "kind": "ScalarField",
              "name": "name"
            },
            {
              "kind": "ScalarField",
              "name": "watcherCount"
            }
          ]
        }
      ]
    }
  ],
  "type": "TagConnection"
};

(node as any).hash = "57b22fafb043425d8a12cee255061145";

export default node;
