export const ENABLE_TEAM_MEMBERSHIPS_IN_USER_SEARCH = 'people-directory.team-memberships.enable';
export const ENABLE_SMART_FLEXIBLE_UI_BLOCK_CARD = 'linking-platform.block-card.flexible-ui-smart-card.enable';
export const ENABLE_PRODUCT_OVERVIEW_PROJECT_COMMS_M1_M2_COHORT = 'onboarding-enable-project-comms-onboarding-m1-m2';
export const ENABLE_EDIT_ONBOARDINGS_SETTINGS = 'onboarding.edit-onboarding-settings.enabled';
export const ENABLE_CUSTOM_EMOJIS = 'custom-emojis.enabled';
export const ENABLE_TEAM_HEALTH_MONITOR = 'team-health-monitor.enabled';

// Integration FF's
export const FORGE_ENABLED_EXTENSION_POINTS = 'forge.enabled-extension-points';

// Other FF's not added/maintained by the Atlas dev team

// Flags owned by P&T

// open flag for allowing a user to override their locale
export const ATLAS_LOCALE_OVERRIDE = 'atlas.override-locale';

// variations/types for FF used in multiple packages
export const EPIC_IMPORT_PICKER = {
  VARIATION_A: 'variation-a',
  CONTROL_NOT_ENROLLED: 'control-not-enrolled',
};
export const EXPERIMENT_COHORTS = {
  VARIATION: 'variation',
  CONTROL: 'control',
  UNENROLLED: 'unenrolled',
};

// linking platform feature flags
export const ENABLE_EDITOR_JIRA_LOL_DATASOURCE = 'platform.linking-platform.datasource-jira_issues';
