/**
 * @generated SignedSource<<75fc6e9daf16c28623fbf1eeb1871e4f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type GoalStateValue = "archived" | "at_risk" | "cancelled" | "done" | "off_track" | "on_track" | "paused" | "pending";
export type GoalMenuButtonQuery$variables = {
  aaid: string;
  workspaceId: string;
};
export type GoalMenuButtonQuery$data = {
  readonly userByAaid: {
    readonly goals: {
      readonly count: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly state: {
            readonly value: GoalStateValue | null;
          } | null;
          readonly uuid: string;
          readonly " $fragmentSpreads": FragmentRefs<"GoalLinkItem">;
        } | null;
      } | null> | null;
    } | null;
    readonly goalsWatching: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly state: {
            readonly value: GoalStateValue | null;
          } | null;
          readonly uuid: string;
          readonly " $fragmentSpreads": FragmentRefs<"GoalLinkItem">;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type GoalMenuButtonQuery = {
  response: GoalMenuButtonQuery$data;
  variables: GoalMenuButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "aaid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "aaid",
    "variableName": "aaid"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "workspaceId",
    "variableName": "workspaceId"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "count"
},
v4 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v5 = {
  "kind": "ScalarField",
  "name": "value"
},
v6 = {
  "concreteType": "GoalEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "concreteType": "Goal",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "concreteType": "GoalState",
          "kind": "LinkedField",
          "name": "state",
          "plural": false,
          "selections": [
            (v5/*: any*/)
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "GoalLinkItem"
        }
      ]
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "name": "archived"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "concreteType": "GoalEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "concreteType": "Goal",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "concreteType": "GoalState",
          "kind": "LinkedField",
          "name": "state",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "kind": "ScalarField",
              "name": "score"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "concreteType": "AggregatedInfoDto",
          "kind": "LinkedField",
          "name": "aggregatedInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "numProjects"
            }
          ]
        },
        (v7/*: any*/),
        {
          "concreteType": "GoalConnection",
          "kind": "LinkedField",
          "name": "subGoals",
          "plural": false,
          "selections": [
            {
              "concreteType": "GoalEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "Goal",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v7/*: any*/),
                    (v8/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "UserConnection",
          "kind": "LinkedField",
          "name": "watchers",
          "plural": false,
          "selections": [
            {
              "concreteType": "UserEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            }
          ]
        },
        (v8/*: any*/)
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "GoalMenuButtonQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByAaid",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "GoalConnection",
            "kind": "LinkedField",
            "name": "goals",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/)
            ]
          },
          {
            "args": (v2/*: any*/),
            "concreteType": "GoalConnection",
            "kind": "LinkedField",
            "name": "goalsWatching",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GoalMenuButtonQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByAaid",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "GoalConnection",
            "kind": "LinkedField",
            "name": "goals",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v9/*: any*/)
            ]
          },
          {
            "args": (v2/*: any*/),
            "concreteType": "GoalConnection",
            "kind": "LinkedField",
            "name": "goalsWatching",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ]
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "ecb299d8c8d38b3f15e953912dfd13f0",
    "id": null,
    "metadata": {},
    "name": "GoalMenuButtonQuery",
    "operationKind": "query",
    "text": "query GoalMenuButtonQuery(\n  $aaid: String!\n  $workspaceId: ID!\n) {\n  userByAaid(aaid: $aaid) {\n    goals(workspaceId: $workspaceId) {\n      count\n      edges {\n        node {\n          uuid\n          state {\n            value\n          }\n          ...GoalLinkItem\n          id\n        }\n      }\n    }\n    goalsWatching(workspaceId: $workspaceId) {\n      edges {\n        node {\n          uuid\n          state {\n            value\n          }\n          ...GoalLinkItem\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment GoalIcon on Goal {\n  state {\n    score\n    value\n  }\n}\n\nfragment GoalLinkItem on Goal {\n  uuid\n  name\n  ...GoalIcon\n  key\n  aggregatedInfo {\n    numProjects\n  }\n  archived\n  subGoals {\n    edges {\n      node {\n        archived\n        id\n      }\n    }\n  }\n  watchers {\n    edges {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c50960c3928b5fee8d8339010613e9d7";

export default node;
