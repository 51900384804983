import { token } from '@atlaskit/tokens';

import { ScoreStatus, ScoreStatusMap } from './types';
import { StatusLozengeStyles } from './utils';

export const scoreMap: ScoreStatusMap = {
  default: {
    light: token('color.background.neutral'),
    dark: token('color.background.neutral.hovered'),
    background: token('color.background.neutral.bold'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.gray.subtler'),
    iconForeground: token('color.icon.accent.gray'),
    lozengeStyle: StatusLozengeStyles.DEFAULT,
  },
  pending: {
    light: token('color.background.neutral'),
    dark: token('color.background.neutral.hovered'),
    background: token('color.background.neutral.bold'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.gray.subtler'),
    iconForeground: token('color.icon.accent.gray'),
    lozengeStyle: StatusLozengeStyles.DEFAULT,
  },
  off_track: {
    light: token('color.background.danger'),
    dark: token('color.background.danger.hovered'),
    background: token('color.background.danger.bold'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.red.subtler'),
    iconForeground: token('color.icon.accent.red'),
    lozengeStyle: StatusLozengeStyles.OFF_TRACK,
  },
  at_risk: {
    light: token('color.background.warning'),
    dark: token('color.background.warning.hovered'),
    background: token('color.background.accent.orange.bolder'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.yellow.subtler'),
    iconForeground: token('color.icon.accent.orange'),
    lozengeStyle: StatusLozengeStyles.AT_RISK,
  },
  on_track: {
    light: token('color.background.success'),
    dark: token('color.background.success.hovered'),
    background: token('color.background.success.bold'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.green.subtler'),
    iconForeground: token('color.icon.accent.green'),
    lozengeStyle: StatusLozengeStyles.ON_TRACK,
  },
};

export const getStatusColor = (status: ScoreStatus) => scoreMap[status];
