import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';
import { Text } from '@atlaskit/primitives';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import ChevronDownIconLegacy from '@townsquare/ds-icon/glyph/chevron-down';
import ChevronUpIconLegacy from '@townsquare/ds-icon/glyph/chevron-up';
import { useJiraProductName } from '@townsquare/workspace-store';

import { messages } from './messages';
import {
  ExpandedTextBlock,
  HeadingWrapper,
  IconWrapper,
  JiraExpandedText,
  JiraPickerSectionTitle,
  Seperator,
} from './styles';

type InfoBoxProps = {
  analyticsAttributes?: {
    cohortName: string;
    flagName: string;
  };
};

export const JiraPickerInfoBox = (props: InfoBoxProps) => {
  const analytics = useAnalytics();
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const jiraProductDisplayName = useJiraProductName();

  const onExpand = useCallback(() => {
    void analytics.ui('startWithJiraInfoBox', isExpanded ? 'closed' : 'opened', props.analyticsAttributes);
    setIsExpanded(!isExpanded);
  }, [isExpanded, analytics, props.analyticsAttributes]);

  const showLessText = intl.formatMessage({
    id: 'townsquare.web.jira-picker-info-box.show-less-projects',
    description: 'Product overview new project info box show less projects',
    defaultMessage: 'Show less projects',
  });

  const showMoreText = intl.formatMessage({
    id: 'townsquare.web.jira-picker-info-box.show-more-projects',
    description: 'Product overview new project info box show more projects',
    defaultMessage: 'Show more projects',
  });

  return (
    <>
      <HeadingWrapper onClick={onExpand}>
        <Text size="UNSAFE_small" weight="bold" color="color.text.subtlest">
          <FormattedMessage {...messages.jiraInfoBoxTitle} />
          <IconWrapper>
            {/* TODO [ICON-STYLE] - styles will need editing when removing legacy icon */}
            {isExpanded ? (
              <ChevronUpIcon
                label={showLessText}
                LEGACY_fallbackIcon={() => <ChevronUpIconLegacy size="large" label={showLessText} />}
              />
            ) : (
              <ChevronDownIcon
                label={showMoreText}
                LEGACY_fallbackIcon={() => <ChevronDownIconLegacy size="large" label={showMoreText} />}
              />
            )}
          </IconWrapper>
        </Text>
      </HeadingWrapper>

      {isExpanded && (
        <JiraExpandedText>
          <JiraPickerSectionTitle>
            <ExpandedTextBlock>
              <FormattedMessage {...messages.jiraSectionTextFirstParagraph} />
            </ExpandedTextBlock>
            <ExpandedTextBlock>
              <FormattedMessage {...messages.jiraSectionTextSecondParagraph} values={{ jiraProductDisplayName }} />
            </ExpandedTextBlock>
          </JiraPickerSectionTitle>
        </JiraExpandedText>
      )}

      <Seperator />
    </>
  );
};
