import { gql } from 'apollo-boost';

export const WORKSPACE_FEATURE_CONTEXT = gql`
  fragment WorkspaceFeatureContext on Workspace {
    featureContext {
      edition
      features {
        goalScoringMode
        privateProjects
        customFieldsLimit
      }
      entitlementId
      transactionAccountId
    }
  }
`;

export const WORKSPACE_FIELDS = gql`
  fragment WorkspaceFields on Workspace {
    id
    name
    uuid
    keyPrefix
    timezone
    type
    cloudId
    organisationId
    goalScoringMode
    type
    ...WorkspaceFeatureContext
  }

  ${WORKSPACE_FEATURE_CONTEXT}
`;

export const GET_ALL_WORKSPACES_BY_ORG = gql`
  query GetAllWorkspacesByOrg($organisationId: String) {
    allWorkspaces(organisationId: $organisationId) {
      edges {
        node {
          ...WorkspaceFields
        }
      }
    }
  }

  ${WORKSPACE_FIELDS}
`;

export const GET_USER_WORKSPACE_DATA_BY_CONTEXT = gql`
  query UserWorkspaceByContext($workspaceContext: WorkspaceContextInput) {
    userWorkspaceByContext(workspaceContext: $workspaceContext) {
      workspace {
        ...WorkspaceFields
        cloudUrl
      }
      requestedContextMatchedType
    }
    workspaceCount {
      recordCount
    }
  }

  ${WORKSPACE_FIELDS}
`;
